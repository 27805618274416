import React, { useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';

import { api } from 'utils/api';
import { HtmlRenderer } from 'utils/htmlParser';
import { Block, BlockTypes } from 'utils/blocks';
import { useGuideStore } from 'store';
import Stars from '../../../base-ui/stars';
import BaseSection from '../../../base-ui/section';
import QuoteTextBlock from '../../../renderers/quote-text';
import NotesWithBackgroundBlock from '../../../renderers/notes-with-background';
import { StContainer, StHeaderWrapper, StStarsWrapper, StBlockWrapper } from './styles';
import ImageWithText from '../../../renderers/image-with-text';
import { NewsHeader, NewsText } from '../../../renderers';

interface ContentProps {
  value?: string;
  contentBlocks: Block[];
}

const Content: React.FC<ContentProps> = observer(({ value, contentBlocks }) => {
  const store = useGuideStore();

  const changeRating = useCallback(
    async (ratingValue: number) => {
      await api.guide.rating.guideRatingCreate({ data: { guide: String(store.page?.id), ratingValue } });
      runInAction(() =>
        store.setPage({
          ...store.page!,
          userRating: String(ratingValue),
        }),
      );
    },
    [store.page],
  );

  return (
    <StContainer>
      {contentBlocks.map((block) => {
        if (block.type === BlockTypes.GuideMainContent) {
          return (
            <BaseSection
              heading={block.value.header ? <StHeaderWrapper>{block.value.header}</StHeaderWrapper> : undefined}
              compact
              borderless
              transparent
              slim
              noLastOfTypeMargin
              key={block.id}
            >
              <div>
                <HtmlRenderer html={block.value.text} />
              </div>
            </BaseSection>
          );
        }
        if (block.type === BlockTypes.QuoteText) {
          return (
            <StBlockWrapper key={block.id}>
              <QuoteTextBlock value={block.value} options={{ slim: true, compact: true }} />
            </StBlockWrapper>
          );
        }
        if (block.type === BlockTypes.NotesWithBackground) {
          return (
            <StBlockWrapper key={block.id}>
              <NotesWithBackgroundBlock value={block.value} options={{ slim: true, compact: true }} />
            </StBlockWrapper>
          );
        }

        if (block.type === BlockTypes.ImageWithText) {
          return <ImageWithText value={block.value} options={{ slim: true, compact: true }} key={block.id} />;
        }

        if (block.type === BlockTypes.GuideTextBlock) {
          return <NewsText key={block.id} data={block.value?.text} options={{ slim: true, compact: true }} />;
        }

        if (block.type === BlockTypes.GuideHeader) {
          return <NewsHeader key={block.id} data={block.value} options={{ slim: true, compact: true }} />;
        }

        return null;
      })}
      <StStarsWrapper>
        <BaseSection compact borderless transparent slim noLastOfTypeMargin>
          <Stars value={store.userRating} onChange={changeRating} />
        </BaseSection>
      </StStarsWrapper>
    </StContainer>
  );
});

export default Content;
