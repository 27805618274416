import { VideoItemType } from 'store/projects/types';
import ReactPlayer from 'react-player';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Nullable } from 'web';
import { OnProgressProps } from 'react-player/base';
import screenfull from 'screenfull';
import { observer } from 'mobx-react-lite';
import { VideoGalleryStore } from '../model';
import { Title } from '../Title';
import { Controls } from '../Controls';
import { Styled } from '../styles';

interface PlayerProps {
  video: VideoItemType;
  onClose: () => void;
  onPlayerMouseMove: () => void;
  setIsControlsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isControlsVisible: boolean;
  text?: string;
}

export const Player = observer<PlayerProps>((props) => {
  const store = VideoGalleryStore();
  const playerContainerRef = useRef<Nullable<HTMLDivElement>>(null);
  const playerRef = useRef<Nullable<ReactPlayer>>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleProgress = useCallback(
    (progress: OnProgressProps) => {
      if (!store.seeking) {
        const { played, loaded = 0, playedSeconds } = progress;
        store.setLoaded(loaded);
        if (played === 0) return;
        if (played === 1) {
          store.setProgress(props.video.id, playerRef.current?.getDuration());
          store.setPlayed(0);
          store.setPlayedSeconds(0);
          store.setPlaying(false);
          return;
        }
        store.setPlayed(played);
        store.setPlayedSeconds(playedSeconds);
      }
    },
    [store.seeking],
  );

  useEffect(() => {
    if (props.video?.id && store.playing) {
      const timer = setInterval(() => {
        store.setProgress(props.video.id);
      }, 4000);
      return () => clearInterval(timer);
    }
  }, [store.playing]);

  useEffect(() => {
    if (props.video) {
      store.setCurrentVideo(props.video);
      store.getProgress();
    }
    return store.cleanUp;
  }, [props.video]);

  useEffect(() => {
    if (playerRef.current && store.ready && playerRef.current instanceof ReactPlayer) {
      store.setDuration(playerRef.current.getDuration());
      store.setPlayed(store.playedSeconds / playerRef.current.getDuration());
      playerRef.current.seekTo(store.playedSeconds, 'seconds');
      props.setIsControlsVisible(true);
      store.setProgress(props.video.id);
      return () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        playerRef.current?.getInternalPlayer('player')?.load();
      };
    }
  }, [store.currentResolution, store.ready, playerRef]);

  const toggleFullScreen = useCallback(() => {
    if (playerContainerRef.current && playerContainerRef.current instanceof HTMLDivElement) {
      screenfull.toggle(playerContainerRef.current);
      setIsFullscreen((prev) => !prev);
    }
  }, [playerContainerRef]);

  const handlePlayerReady = useCallback(() => {
    store.setReady(true);
  }, []);

  const handlePlayerPause = useCallback(() => {
    store.setPlaying(false);
  }, []);

  const handleOnPlayerClick = useCallback(() => {
    store.setPlaying(!store.playing);
  }, []);

  return (
    <Styled.Container ref={playerContainerRef} onMouseMove={props.onPlayerMouseMove}>
      <ReactPlayer
        key="player"
        onClick={handleOnPlayerClick}
        className="player"
        width="100%"
        height="100%"
        ref={playerRef}
        playing={store.playing}
        onPause={handlePlayerPause}
        url={store.currentResolution?.url}
        onProgress={handleProgress}
        onReady={handlePlayerReady}
        onDuration={store.setDuration}
        playbackRate={store.speed}
        muted={store.muted}
        volume={store.volume}
        stopOnUnmount={true}
        progressInterval={100}
      />

      {store.ready && playerRef.current && (
        <>
          {props.isControlsVisible && <Title title={props.video.title} />}
          <Controls
            player={playerRef.current}
            visible={props.isControlsVisible}
            video={props.video}
            toggleFullScreen={toggleFullScreen}
            isFullscreen={isFullscreen}
            onClose={props.onClose}
            text={props.text}
          />
        </>
      )}
    </Styled.Container>
  );
});
