import styled from 'styled-components';
import { COLORS } from 'utils/constants';
import { Container } from '../../../base-ui/container';

export const ContentWrapper = styled(Container)`
  flex-direction: column;
  font-size: 14px;
  color: ${COLORS.blueDark};
  gap: 16px;
  border-radius: 12px;
  padding: 16px;
  width: 234px;

  img {
    width: 18px;
    height: 18px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  color: ${COLORS.azure};
`;

export const TextLink = styled.div`
  font-weight: 500;
  color: ${COLORS.azure};
  text-decoration: underline;
  cursor: pointer;
`;

export const Text = styled.div`
  word-break: break-word;
`;
