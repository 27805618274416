import styled, { css } from 'styled-components';
import { BREAKPOINTS, COLORS } from '../../../../utils/constants';
import Link from '../../link';

const link = css`
  line-height: 24px;
  text-decoration: none;
`;

export const Content = styled.div`
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    grid-template-columns: 1fr auto;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    padding: 0 160px;
  }
`;

export const Left = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 13px 24px;
  background-color: ${COLORS.white};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 13px 16px 13px 33px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-wrap: nowrap;
    gap: 25px;
  }
`;

export const BrandLink = styled(Link)`
  img {
    width: 80%;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    img {
      width: 100%;
    }
  }
`;

export const Nav = styled.nav`
  display: flex;
  column-gap: 18px;
  justify-content: flex-end;
  align-items: center;
  padding: 0 8px;

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    column-gap: 28px;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 20px 8px 15px;
  color: ${COLORS.white};
  background-color: ${COLORS.azureLight};
  border-radius: 30px;
  height: 46px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 10px 20px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 8px 20px 8px 15px;
  }
`;

export const Item = styled.div`
  display: inherit;
  position: relative;

  &:not(:last-of-type) {
    margin-right: 30px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: -15px;
      display: block;
      width: 1px;
      height: 24px;
      background-color: ${COLORS.gray5};
    }
  }
`;

export const Profile = styled.div`
  position: relative;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0 14px;
`;

export const ProfileLink = styled(Link)`
  ${link};

  color: inherit;
  font-size: 16px;
`;

export const BadgeWrapper = styled.div`
  top: -8px;
  right: -14px;
  position: absolute;
`;
