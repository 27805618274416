import React, { useEffect, useState } from 'react';
import { Container } from 'components/base-ui/container';
import { useDeviceState } from 'hooks/useDeviceProvider';
import { observer } from 'mobx-react-lite';
import { useLeaderCodeStore } from 'store/leader-code';
import { LeaderCodeTeam } from 'services';
import { FirstTeamContainer, Team, Teams, TeamsContainer, TeamsWrapper } from './styles';
import { Title } from '../styles';
import Icon from '../../../base-ui/icon';

export const TeamsBlock: React.FC = observer(() => {
  const store = useLeaderCodeStore();
  const [currentTeam, setCurrentTeam] = useState(store.team);
  const { isLessLaptop, isLessTablet } = useDeviceState();
  const [isVisible, setIsVisible] = useState(!isLessLaptop);

  useEffect(() => {
    setCurrentTeam(store.team);
  }, [store.team]);

  const teamsContainer = document.getElementById('teamsContainerId');

  useEffect(() => {
    setIsVisible(!isLessLaptop);
    if (teamsContainer) {
      teamsContainer.style.height = '100%';
    }
  }, [isLessLaptop]);

  const handleOpen = () => {
    if (teamsContainer) {
      teamsContainer.style.height = '100%';
    }
    setIsVisible(true);
  };

  const handleClose = () => {
    if (teamsContainer) {
      teamsContainer.style.height = 'fit-content';
    }
    setIsVisible(false);
  };

  const handleClick = (team: LeaderCodeTeam) => {
    setCurrentTeam(team);
    store.setTeam(team);
    if (isLessLaptop) {
      handleClose();
    }
  };

  return (
    <TeamsWrapper>
      <Title>Команды</Title>
      <Teams id="teamsContainerId" isCollapsed={!isVisible}>
        {isVisible ? (
          <>
            {isLessLaptop && (
              <Container justify="flex-end">
                <Icon name="iconCrossBaseWhite" onClick={handleClose} />
              </Container>
            )}
            <TeamsContainer>
              {store.teams.map((team) => (
                <Team
                  key={`${store.year}-${team.teamName}`}
                  onClick={() => handleClick(team)}
                  isSelectedTeam={currentTeam?.teamName === team.teamName}
                >
                  {!isLessTablet && <Icon name="iconMark" />}
                  {team.teamName}
                </Team>
              ))}
            </TeamsContainer>
          </>
        ) : (
          <FirstTeamContainer>
            <Team isCollapsed={!isVisible}>
              {!isLessTablet && <Icon name="iconMark" />}
              {store.team?.teamName ?? store.teams[0]?.teamName}
            </Team>
            <Icon name="iconArrowDown" onClick={handleOpen} />
          </FirstTeamContainer>
        )}
      </Teams>
    </TeamsWrapper>
  );
});
