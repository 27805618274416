import React from 'react';
import styled from 'styled-components';

import { BREAKPOINTS } from '../../../../utils/constants';

const BannerWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 56px;
`;

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    height: 800px;
    object-position: center top;
  }
`;

interface BannerProps {
  image?: string;
}

const Banner: React.FC<BannerProps> = (props) => {
  return props.image ? (
    <BannerWrapper>
      <BannerImage src={props.image} />
    </BannerWrapper>
  ) : null;
};

export default Banner;
