import React, { useState, useMemo, useCallback, ChangeEvent } from 'react';
import styled, { css } from 'styled-components';

import { useHistory } from 'react-router-dom';
import { useDeviceType } from 'utils/hooks';
import { COLORS, BREAKPOINTS } from 'utils/constants';

import Input from 'components/base-ui/input';
import IconButton from 'components/base-ui/icon-button';

interface SearchProps {
  onClick?: () => void;
}

const wrapper = css`
  width: inherit;
  height: inherit;
`;

const Div = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;

  height: 100%;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    grid-template-columns: 30fr 1fr;
    padding: 0 160px;
  }
`;

const InputWrapper = styled.div`
  ${wrapper};
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: block;
    width: 1px;
    height: 24px;
    background-color: ${COLORS.gray5};
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 24px;
  border: none;
  border-radius: 0;
  padding: 8px 16px;

  height: 41px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 8px 16px 8px 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 8px 16px 8px 0px;
  }
`;

const ButtonWrapper = styled.div`
  ${wrapper};
  padding: 8px 16px;

  display: flex;
  align-items: center;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 8px 24px 8px 16px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 8px 32px 8px 16px;
  }
`;

const StyledIcon = styled(IconButton)`
  width: 24px;

  &:disabled {
    > img {
      filter: invert(69%) sepia(25%) saturate(308%) hue-rotate(175deg) brightness(84%) contrast(85%);
    }
  }
`;

const SearchButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 16px;
  height: 100%;
  padding: 0;
  border: none;
  font-size: 14px;
  line-height: 24px;
  color: ${COLORS.secondary};
  text-transform: uppercase;
  background-color: transparent;
  transition: all 0.2s ease-out;

  &:disabled {
    color: ${COLORS.primary};
  }
`;

const Search: React.FC<SearchProps> = ({ onClick }) => {
  const deviceType = useDeviceType();
  const history = useHistory();

  const [value, setValue] = useState('');

  const onChangeSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.currentTarget.value);
    },
    [value],
  );

  const isMobile = useMemo(() => deviceType === 'mobile', [deviceType]);

  const deleteEscapes = React.useCallback((str: string): string => {
    return str.replace(/[^a-zа-яё0-9\s]/gi, ' ');
  }, []);

  const onSearch = useCallback(() => {
    if (value === '') return;
    history.push(`/search/${deleteEscapes(value)}`);
  }, [value]);

  const handleEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onSearch();
      }
    },
    [value],
  );

  return (
    <Div>
      <InputWrapper onKeyPress={handleEnter}>
        <StyledInput
          id="search"
          placeholder="Введите слово для поиска"
          autoFocus
          value={value}
          onChange={onChangeSearch}
        />
        {!isMobile && (
          <SearchButton disabled={!value} onClick={onSearch}>
            Найти
          </SearchButton>
        )}
      </InputWrapper>

      <ButtonWrapper>
        <StyledIcon name="iconCrossBase" onClick={onClick} />
      </ButtonWrapper>
    </Div>
  );
};

export default Search;
