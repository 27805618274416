import React from 'react';

import { Button } from 'shared/components';
import { Content, Title, BannerWrapper, ImageWrapper, Image, Description } from './styles';
import Section from '../section';

interface SmallBannerPropsType {
  header: string;
  description?: string;
  image: string;
  buttonsVisibility: boolean;
  onClick: () => void;
}

export const SmallBanner: React.FC<SmallBannerPropsType> = ({
  header,
  description,
  image,
  buttonsVisibility,
  onClick,
}) => {
  return (
    <Section noFirstOfTypeOffset noLastOfTypeMargin compact>
      <BannerWrapper>
        <Content>
          <Title>{header}</Title>
          <Description>{description}</Description>
          {buttonsVisibility && <Button onClick={onClick}>Подробнее</Button>}
        </Content>
        <ImageWrapper>
          <Image src={image} />
        </ImageWrapper>
      </BannerWrapper>
    </Section>
  );
};
