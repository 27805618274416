import styled from 'styled-components';
import { BREAKPOINTS, COLORS, HEADER_HEIGHT } from 'utils/constants';

export const StHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: ${HEADER_HEIGHT};
  max-width: inherit;
  z-index: 100000;
  background-color: ${COLORS.white};

  padding: 8px;
  border-bottom: 1px solid ${COLORS.gray6};

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0;
    border-bottom: none;
  }
`;
