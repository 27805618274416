import React, { useMemo } from 'react';

import { RelatedItem } from 'web';

import { useCarousel, useWindowDimensions } from 'utils/hooks';
import { BREAKPOINTS } from 'utils/constants';

import { useSwipeable } from 'react-swipeable';
import IconButton from '../icon-button';
import { RelatedItemCard } from './item';
import { ButtonWrapper, Div, Grid } from './styled';

interface RelatedItemsProps {
  items: RelatedItem[];
}

const MAX_DESKTOP_DIMENSION = 1903;

export const RelatedItems: React.FC<RelatedItemsProps> = ({ items }) => {
  const { list, decrement, increment } = useCarousel(items);
  const { width } = useWindowDimensions();

  const numberOfItems = useMemo((): number => {
    if (width < parseInt(BREAKPOINTS.tablet)) return 1;
    if (width < parseInt(BREAKPOINTS.desktop)) return 2;
    if (width < MAX_DESKTOP_DIMENSION) return 3;

    return 4;
  }, [width]);

  const itemsToShow = useMemo(() => list.slice(0, numberOfItems), [list, numberOfItems]);

  const showArrows = React.useMemo(() => {
    if (width < parseInt(BREAKPOINTS.tablet) && list.length > 1) return true;
    if (width < parseInt(BREAKPOINTS.desktop) && list.length > 2) return true;
    if (width < MAX_DESKTOP_DIMENSION && list.length > 3) return true;
    return list.length > 4;
  }, [width, list]);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Left') increment();
      if (eventData.dir === 'Right') decrement();
    },
  });

  return (
    <Div>
      {showArrows && (
        <ButtonWrapper direction="left">
          <IconButton name="iconArrowLeft" round shadow gradient onClick={decrement} />
        </ButtonWrapper>
      )}

      <Grid {...handlers}>
        {itemsToShow.map((el) => (
          <RelatedItemCard key={el.id} data={el} />
        ))}
      </Grid>

      {showArrows && (
        <ButtonWrapper direction="right">
          <IconButton name="iconArrowRight" round shadow gradient onClick={increment} />
        </ButtonWrapper>
      )}
    </Div>
  );
};
