import { createGlobalStyle } from 'styled-components';

import GeologicaBlack from '../assets/fonts/Geologica-Black.ttf';
import GeologicaBold from '../assets/fonts/Geologica-Bold.ttf';
import GeologicaExtraBold from '../assets/fonts/Geologica-ExtraBold.ttf';
import GeologicaExtraLight from '../assets/fonts/Geologica-ExtraLight.ttf';
import GeologicaLight from '../assets/fonts/Geologica-Light.ttf';
import GeologicaMedium from '../assets/fonts/Geologica-Medium.ttf';
import GeologicaRegular from '../assets/fonts/Geologica-Regular.ttf';
import GeologicaSemiBold from '../assets/fonts/Geologica-SemiBold.ttf';
import GeologicaThin from '../assets/fonts/Geologica-Thin.ttf';

const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: 'Geologica';
    src: url(${GeologicaBlack}) format('truetype');
    font-weight: 900;
    font-style: black;
  }

  @font-face {
    font-family: 'Geologica';
    src: url(${GeologicaBold}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Geologica';
    src: url(${GeologicaExtraBold}) format('truetype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Geologica';
    src: url(${GeologicaExtraLight}) format('truetype');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'Geologica';
    src: url(${GeologicaLight}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Geologica';
    src: url(${GeologicaMedium}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Geologica';
    src: url(${GeologicaRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Geologica';
    src: url(${GeologicaSemiBold}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Geologica';
    src: url(${GeologicaThin}) format('truetype');
    font-weight: 100;
    font-style: normal;
  }
`;

export default GlobalFonts;
