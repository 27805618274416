import React, { useCallback } from 'react';
import { HtmlRenderer } from 'utils/htmlParser';
import { LeadersForParticipantsType } from 'utils/blocks';
import { Content, Description, Header, Wrapper, ButtonWrapper } from './styles';

import { Button } from '../../../shared/components';
import { useLink } from '../../../utils/api';

type Props = LeadersForParticipantsType;

export const LeadersForParticipants: React.FC<Props> = (props) => {
  const openLink = useLink();

  const handleLinkClick = useCallback(() => {
    props.button && openLink(props.button.link);
  }, []);

  return (
    <Wrapper>
      <Content>
        <Header>
          <HtmlRenderer html={props.title} />
        </Header>
        <Description>
          <HtmlRenderer html={props.description} />
        </Description>
      </Content>

      {props.button && (
        <ButtonWrapper>
          <Button width="240px" onClick={handleLinkClick}>
            {props.button.text}
          </Button>
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};
