import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const StTitleCenter = styled.div`
  margin: 100px auto 40px auto;
  width: 100%;
  padding: 0 16px;
  color: var(--medium, ${COLORS.gray3});
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 110px auto 40px auto;
    padding: 0;
    width: 608px;
    font-size: 28px;
    line-height: 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    margin: 140px auto 60px auto;
    width: 1220px;
    font-size: 44px;
    line-height: 48px;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    margin: 140px auto 60px auto;
  }
`;
