import React, { useCallback, useEffect, useState } from 'react';
import { HtmlRenderer } from 'utils/htmlParser';
import { ReactComponent as IconDownload } from 'assets/icons/icon_download.svg';
import { BaseModal } from 'components/base-ui/base-modal';
import { Feedback } from 'components/feedback';
import {
  ButtonWrapper,
  Header,
  ModalCloseWrapper,
  ModalContainer,
  ModalContentWrapper,
} from './styles';
import { api } from 'utils/api';
import { ApplicationFormIntegrationEnum } from 'services';
import IconButton from 'components/base-ui/icon-button';
import { Button } from 'shared/components';
import { ButtonTypes } from 'shared/components/button/types';

interface ModalProps {
  onClick: () => void;
  header: string;
  content: string;
  showButton: boolean;
}

const Modal: React.FC<ModalProps> = ({ onClick, header, content, showButton }) => {
  const [showForm, setShowForm] = useState(false);
  const [formId, setFormId] = useState(0);
  const handleShowForm = useCallback(() => setShowForm(true), []);
  const handleCloseForm = useCallback(() => setShowForm(false), []);

  useEffect(() => {
    api.applicationForm.applicationFormsList().then((res) => {
      const form = res.data.results.filter((el) => el.integration === ApplicationFormIntegrationEnum.Sale);
      setFormId(form[0]?.id || 0);
    });
  }, []);

  return (
    <>
      <BaseModal isVisible={showForm} onClose={handleCloseForm}>
        <Feedback formId={formId} />
      </BaseModal>

      <ModalContainer>
        <ModalCloseWrapper>
          <IconButton name="iconCrossBaseWhite" onClick={onClick} />
        </ModalCloseWrapper>
        
        <Header>{header}</Header>
      
        <ModalContentWrapper>
          <HtmlRenderer html={content} />
        </ModalContentWrapper>
        
        <ButtonWrapper>
          {showButton && (
            <Button width='250' suffix={<IconDownload />} type={ButtonTypes.Inverse} onClick={handleShowForm}>
              Правила участия
            </Button>
          )}
        </ButtonWrapper>
      </ModalContainer>
    </>
  );
};

export default Modal;
