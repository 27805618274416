import React from 'react';

import { PropsWithSectionOptions } from '../blocks';
import { QuoteWrapper, QuoteStart, QuoteEnd } from './styles';

import Section from '../../base-ui/section';

type QuoteTextBlockProps = PropsWithSectionOptions<{
  value: string;
}>;

const QuoteTextBlock: React.FC<QuoteTextBlockProps> = ({ value, options }) => {
  return (
    <Section noFirstOfTypeOffset noLastOfTypeMargin noMinHeight {...options}>
      <QuoteWrapper>
        <QuoteStart />
        {value}
        <QuoteEnd />
      </QuoteWrapper>
    </Section>
  );
};

export default QuoteTextBlock;
