import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin-bottom: 40px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    gap: 40px;
  }

  @media screen and (min-width: 1835px) {
    flex-direction: row;
    gap: 20px;
    margin-top: 40px;
    padding-bottom: 100px;
  }
`;
