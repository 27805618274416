import React from 'react';
import { IconWrapper } from './styles';

const svg = (
  <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M31 2H47C48.1046 2 49 2.89543 49 4V20" stroke="#D12B5E" strokeWidth="3" strokeLinecap="round" />
    <rect y="28" width="22" height="22" rx="2" fill="#436F96" />
    <path
      // eslint-disable-next-line max-len
      d="M20.7491 9.51081C22.346 10.3624 22.4318 12.6474 20.9034 13.6194L3.58555 24.6332C2.02645 25.6248 8.36892e-07 24.4892 9.18425e-07 22.6239L1.80351e-06 2.37549C1.88154e-06 0.590409 1.87234 -0.555129 3.43126 0.276162L20.7491 9.51081Z"
      fill="#436F96"
    />
    <path
      // eslint-disable-next-line max-len
      d="M34.5108 29.2509C35.3624 27.654 37.6474 27.5682 38.6194 29.0966L49.6332 46.4145C50.6248 47.9736 49.4892 50 47.6239 50H27.3755C25.5904 50 24.4449 48.1277 25.2762 46.5687L34.5108 29.2509Z"
      fill="#436F96"
    />
  </svg>
);

export const Icon: React.FC = () => {
  return <IconWrapper>{svg}</IconWrapper>;
};
