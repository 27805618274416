import { COLORS } from 'utils/constants';
import { ColorsProps, ButtonTypes } from './types';

export const colors: Record<ButtonTypes, ColorsProps> = {
  primary: {
    color: COLORS.white,
    howerColor: COLORS.white,
    background: COLORS.azureGradient,
    howerBackground: COLORS.azure,
  },
  secondary: {
    color: COLORS.azure,
    howerColor: COLORS.white,
    background: COLORS.white,
    howerBackground: COLORS.azureLight,
  },
  inverse: {
    color: COLORS.white,
    howerColor: COLORS.azure,
    background: 'unset',
    howerBackground: COLORS.greenGray,
  },
  ghost: {
    color: COLORS.azure,
    howerColor: COLORS.azure,
    background: 'unset',
    howerBackground: 'unset',
  },
  ghostInverse: {
    color: COLORS.white,
    howerColor: COLORS.white,
    background: 'unset',
    howerBackground: 'unset',
  },
};
