import React from 'react';
import { observer } from 'mobx-react-lite';
import { Container } from './styles';
import { News, Applications } from './components';

type PropsType = {
  news: boolean;
  apps: boolean;
};

export const NewsWithApps: React.FC<PropsType> = observer(({ news, apps }) => {
  if (!news && !apps) return null;

  return (
    <Container>
      {apps && <Applications withNews={news} />}
      {news && <News withApps={apps} />}
    </Container>
  );
});
