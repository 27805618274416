import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 56px auto;
  padding: 0 16px;
  width: 100%;
  gap: 80px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 56px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    margin: 0 auto 79px auto;
    width: 1328px;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    width: 1504px;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 56px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    gap: 64px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: row;
    gap: 47px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    margin: 79px auto;
    width: 1328px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    margin: 79px auto;
    width: 1328px;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    width: 1504px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  margin-bottom: 56px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: 76px;
  }
`;
export const Styled = { Wrapper, Container, InfoContainer };
