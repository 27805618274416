import styled from 'styled-components';
import { BASIC_FONT_FAMILY, BREAKPOINTS } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${BASIC_FONT_FAMILY};
`;

export const Chronology = styled.div`
  width: 100%;
  margin: 100px 0 40px 23px;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-left: 0;
  }
`;

export const Carousel = styled.div`
  width: 100%;
  margin-left: 32px;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-left: 0;
  }
`;
