import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const CommentsList = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 0 52px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin: 0 150px;
  }
`;

export const ShowMoreCommentsButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
`;
