import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const StButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    justify-content: flex-start;
  }
`;
