import React, { useMemo } from 'react';

const svgIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.686279" y="13" width="16" height="16" rx="2" transform="rotate(-45 0.686279 13)" fill="#436F96" />
  </svg>
);

export const PointIcon: React.FC = () => {
  return <div style={{ width: '16px', height: '16px' }}>{svgIcon}</div>;
};
