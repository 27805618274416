import React from 'react';

import styled from 'styled-components';
import { GlobalCategory } from '../../../services';

import { Container, Grid } from '../../base-ui/cards';
import Item from './item';
import { BREAKPOINTS } from '../../../utils/constants';

interface CardsProps {
  items: GlobalCategory[];
}

const Cards: React.FC<CardsProps> = ({ items }) => {
  return (
    <StContainer>
      {items.map((el) => (
        <Item key={el.id} data={el} />
      ))}
    </StContainer>
  );
};

const StContainer = styled(Container)`
  flex-wrap: wrap;
  @media screen and (min-width: ${BREAKPOINTS.mobile}) {
    padding: 0 16px;
    gap: 16px;
  }
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0;
    gap: 40px;
  }
`;

export default Cards;
