import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import globalAxios from 'axios';

import { getCookie } from 'utils/api';

import {
  AuthStateProvider,
  BankingProductsStateProvider,
  CartStateProvider,
  CategoryStateProvider,
  CompanyStateProvider,
  FinanceCategoryStateProvider,
  FooterStateProvider,
  GlobalCategoriesStateProvider,
  GuideCategoryStateProvider,
  GuidePagesStateProvider,
  GuideStateProvider,
  InsuranceCategoryStateProvider,
  InsuranceProductsStateProvider,
  MediaCategoryStateProvider,
  MediaProductsStateProvider,
  NewsStateProvider,
  PortalDirectionsStateProvider,
  ProductAttributeStateProvider,
  ProductListStateProvider,
  ProductStateProvider,
  PromocodeStateProvider,
  SearchStateProvider,
  ShopsStateProvider,
  UnderConstructionStateProvider,
  DynamicApplicationStateProvider,
  ProjectsStateProvider,
} from 'store';

import { authService } from 'utils/authentication';
import { HTTP_STATUSES } from 'utils/constants';
import { ProfileStateProvider } from 'store/profile';
import { PageContentStateProvider } from 'store/page-content';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import routes from './router';
import { DeviceTypeProvider } from './hooks/useDeviceProvider';
import {CitiesStateProvider} from "./store/cities";

globalAxios.interceptors.request.use(({ headers, ...rest }) => {
  headers = authService.onRequest(headers);
  return {
    ...rest,
    headers: {
      ...headers,
      'X-CSRFToken': getCookie('csrftoken'),
    },
  };
});

globalAxios.interceptors.response.use(undefined, (error) => {
  const REJECT_STATUSES = [HTTP_STATUSES.THROTTLE, HTTP_STATUSES.FORBIDDEN, HTTP_STATUSES.UNAUTHORIZED];
  return new Promise(() => {
    if (error.response && error.response.status === HTTP_STATUSES.THROTTLE) {
      window.location.reload();
    }
    if (error.response && REJECT_STATUSES.includes(error.response.status)) {
      authService.onError();
    }
    throw error;
  });
});

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <AuthStateProvider>
      <PageContentStateProvider>
        <ProfileStateProvider>
          <DynamicApplicationStateProvider>
            <CartStateProvider>
              <FooterStateProvider>
                <CompanyStateProvider>
                  <CitiesStateProvider>
                  <GlobalCategoriesStateProvider>
                    <ProjectsStateProvider>
                      <CategoryStateProvider>
                        <ProductListStateProvider>
                          <ProductAttributeStateProvider>
                            <PortalDirectionsStateProvider>
                              <BankingProductsStateProvider>
                                <FinanceCategoryStateProvider>
                                  <InsuranceCategoryStateProvider>
                                    <InsuranceProductsStateProvider>
                                      <MediaCategoryStateProvider>
                                        <MediaProductsStateProvider>
                                          <PromocodeStateProvider>
                                            <ShopsStateProvider>
                                              <GuideCategoryStateProvider>
                                                <NewsStateProvider>
                                                  <ProductStateProvider>
                                                    <GuidePagesStateProvider>
                                                      <GuideStateProvider>
                                                        <SearchStateProvider>
                                                          <UnderConstructionStateProvider>
                                                            <QueryClientProvider client={queryClient}>
                                                              <DeviceTypeProvider>
                                                                <BrowserRouter>{renderRoutes(routes)}</BrowserRouter>
                                                              </DeviceTypeProvider>
                                                            </QueryClientProvider>
                                                          </UnderConstructionStateProvider>
                                                        </SearchStateProvider>
                                                      </GuideStateProvider>
                                                    </GuidePagesStateProvider>
                                                  </ProductStateProvider>
                                                </NewsStateProvider>
                                              </GuideCategoryStateProvider>
                                            </ShopsStateProvider>
                                          </PromocodeStateProvider>
                                        </MediaProductsStateProvider>
                                      </MediaCategoryStateProvider>
                                    </InsuranceProductsStateProvider>
                                  </InsuranceCategoryStateProvider>
                                </FinanceCategoryStateProvider>
                              </BankingProductsStateProvider>
                            </PortalDirectionsStateProvider>
                          </ProductAttributeStateProvider>
                        </ProductListStateProvider>
                      </CategoryStateProvider>
                    </ProjectsStateProvider>
                  </GlobalCategoriesStateProvider>
                  </CitiesStateProvider>
                </CompanyStateProvider>
              </FooterStateProvider>
            </CartStateProvider>
          </DynamicApplicationStateProvider>
        </ProfileStateProvider>
      </PageContentStateProvider>
    </AuthStateProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
