import React, { useCallback, useMemo } from 'react';
import { useNewsCommentsStore } from 'store';
import { observer } from 'mobx-react-lite';
import { Container } from 'components/base-ui/container';
import { IconSvg } from 'assets/icons';
import { StContainer, ReplyButton, StyledCounterLike } from './styles';

interface Props {
  visible: boolean;
  onReply: () => void;
  likesCount?: number;
  commentId?: number;
  parentCommentId?: number | null;
  isLikedByUser?: boolean;
  isSub?: boolean;
  onLike: () => void;
}

export const CommentFooter: React.FC<Props> = observer((props) => {
  const replyButtonText = useMemo(() => (props.visible ? 'Промолчать' : 'Ответить'), [props.visible]);

  return (
    <StContainer>
      <StyledCounterLike
        onClick={props.onLike}
        isLikedByUser={props.isLikedByUser}
        children={
          <Container gap="5px" align="center">
            <IconSvg.Thumb />
            {props.likesCount ?? 0}
          </Container>
        }
      />
      <ReplyButton onClick={props.onReply}>{replyButtonText}</ReplyButton>
    </StContainer>
  );
});
