import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from 'utils/api';
import { COLORS } from 'utils/constants';
import { Block } from 'utils/blocks';
import { parseHtml } from 'utils/htmlParser';
import Icon from 'components/base-ui/icon';
import { OurPeopleArticle as OurPeopleArticleType } from 'services/api';
import { IconEmptyLike, IconLike } from 'components/base-ui/ikon-like';
import { observer } from 'mobx-react-lite';
import { useProfileStore, useProjectsStore } from 'store';
import {
  Annotation,
  BannerContent,
  BannerImage,
  BannerImageWrapper,
  BigHeader,
  Container,
  Content,
  Count,
  Div,
  DoubleImage,
  Footer,
  IconsWrapper,
  Image,
  LikeIcon,
  PdfIcon,
  PrintIcon,
  QuoteContent,
  QuoteWrapper,
  SmallHeader,
  ViewIcon,
  WaterMark,
} from './styles';
import { ArticleProps, BigHeaderType, ImageType } from './types';

export const OurPeopleArticle: React.FC<ArticleProps> = observer(({ articleId }) => {
  const store = useProjectsStore();
  const profileStore = useProfileStore();
  const [data, setData] = useState<OurPeopleArticleType | null>(null);
  const [content, setContent] = useState<Block[]>([]);

  useEffect(() => {
    api.ourPeopleArticles.ourPeopleArticlesRead({ id: articleId }).then((res) => {
      setData(res.data);
      setContent(res.data.content as unknown as Block[]);
    });
  }, [store.articles]);

  const bigHeaderData = useMemo(() => {
    return content.filter((item) => item.type === 'banner').map((item) => item.value as unknown as BigHeaderType)[0];
  }, [content]);

  const articleContentTop = useMemo(() => {
    return content.filter((item) => item.type === 'text_block').map((item) => item.value as string)[0];
  }, [content]);

  const articleContentMid = useMemo(() => {
    return content.filter((item) => item.type === 'text_block').map((item) => item.value as string)[1];
  }, [content]);

  const articleContentBottom = useMemo(() => {
    return content.filter((item) => item.type === 'text_block').map((item) => item.value as string)[2];
  }, [content]);

  const quoteData = useMemo(() => {
    return content.filter((item) => item.type === 'big_header').map((item) => item.value)[0];
  }, [content]);

  const doubleImageData = useMemo(() => {
    const images = content
      .filter((item) => item.type === 'image' && Boolean(item.value?.image))
      .map((item) => item.value as unknown as ImageType);
    if (images.length < 2) return null;
    return { firstImage: images[0]?.image, secondImage: images[1]?.image };
  }, [content]);

  const onPrint = () => window.print();

  const onLikeClick = useCallback(() => {
    if (data?.id) {
      data?.isLikedByUser
        ? store.handleLikeDelete(data.id, profileStore.profile?.id!)
        : store.handleLikePost(data?.id, profileStore.profile);
    }
  }, [data?.id, data?.isLikedByUser]);

  return (
    <Container>
      <BannerImageWrapper>
        <BannerImage src={bigHeaderData?.image} />
      </BannerImageWrapper>
      <BannerContent>
        <BigHeader>{bigHeaderData?.header}</BigHeader>
        <SmallHeader>{bigHeaderData?.author}</SmallHeader>
      </BannerContent>
      <Annotation>{bigHeaderData?.description}</Annotation>
      <Content>{parseHtml(articleContentTop)}</Content>
      {doubleImageData && (
        <DoubleImage>
          <Image src={doubleImageData?.firstImage} />
          <Image src={doubleImageData?.secondImage} />
        </DoubleImage>
      )}
      <Content>{parseHtml(articleContentMid)}</Content>
      {quoteData && (
        <QuoteWrapper>
          <WaterMark>“</WaterMark>
          <QuoteContent>{quoteData}</QuoteContent>
        </QuoteWrapper>
      )}
      <Content>{parseHtml(articleContentBottom)}</Content>
      <Footer>
        <IconsWrapper>
          <Div>
            <ViewIcon>
              <Icon name="iconViewsGrey" />
            </ViewIcon>
            <Count>{data?.viewsCount || 0}</Count>
          </Div>
          <Div>
            <LikeIcon onClick={onLikeClick}>
              {data?.isLikedByUser ? <IconLike color={COLORS.gray4} /> : <IconEmptyLike color={COLORS.gray4} />}
              <span>Нравится</span>
            </LikeIcon>
            <Count>{data?.likesCount || 0}</Count>
          </Div>
          <PrintIcon onClick={onPrint}>
            <Icon name="iconPrintSolid" />
            <span>Распечатать</span>
          </PrintIcon>
          <Div>
            <PdfIcon>
              <Icon name="iconPdfGray" />
              <span>Скачать</span>
            </PdfIcon>
          </Div>
        </IconsWrapper>
      </Footer>
    </Container>
  );
});
