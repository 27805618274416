import React, { useCallback, useEffect, useMemo } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useNewsCommentsStore, useNewsStore } from 'store';

import { Block, BlockTypes } from 'utils/blocks';

import { BlocksRender } from 'components/renderers';

import Breadcrumbs from 'components/base-ui/breadcrumbs';
import BaseSection from 'components/base-ui/section';
import BreadcrumbsWrapper from 'components/base-ui/breadcrumbs-wrapper';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { StWrapper, StLine } from './styles';
import NewsComments from './comments';
import { LikeModal } from '../../base-ui/like-modal';
import { LikeModalContent } from '../../base-ui/like-modal-content';
import { useDeviceType } from '../../../utils/hooks';

const Div = styled.div<{ withMainImage: boolean }>`
  height: 100%;
  position: relative;
  ${(props) => props.withMainImage === false && 'padding-top: 80px'};
`;

const NewsItem: React.FC = observer(() => {
  const store = useNewsStore();
  const { trackPageView } = useMatomo();
  const [likeModalNewsId, setLikeModalNewsId] = React.useState<number | null>(null);
  const [showLikeModal, setShowLikeModal] = React.useState(false);
  const device = useDeviceType();

  const { commentsCount } = useNewsCommentsStore();

  const { newsId } = useParams<{ newsId: string }>();

  const likesLimit = React.useMemo(() => {
    if (device === 'mobile') return 70;
    return 40;
  }, [device]);

  useEffect(() => {
    document.title = store.currentNews?.title ?? 'Новости';
    if (store.currentNews?.title) {
      trackPageView({});
    }
  }, [store.currentNews?.title]);

  useEffect(() => autorun(() => store.fetchCurrentNews(+newsId)), [newsId, store]);

  // to prevent main_image from being rendered before other content
  const sortedBlocks = useMemo(() => {
    return store.currentNews?.content
      ? (store.currentNews?.content as unknown as Block[]).slice().sort((a) => {
          return a.type === BlockTypes.NewsMainImage ? -1 : 0;
        })
      : [];
  }, [store.currentNews]);

  const withMainImage = useMemo(
    () => sortedBlocks.some((block) => block.type === BlockTypes.NewsMainImage),
    [sortedBlocks],
  );

  const borderless = useMemo(
    () => sortedBlocks.some((block) => block.type === BlockTypes.ApplicationFormBlock),
    [sortedBlocks],
  );

  const onShowModal = useCallback((id: number) => {
    setLikeModalNewsId(id);
    setShowLikeModal(true);
  }, []);

  return (
    <Div withMainImage={withMainImage}>
      <BreadcrumbsWrapper absolute>
        <Breadcrumbs white={withMainImage} />
      </BreadcrumbsWrapper>

      <StWrapper>
        <BlocksRender
          blocks={sortedBlocks}
          loading={store.fetching}
          onShowLikeNewsModal={onShowModal}
          options={{ section: { slim: true, borderless: true } }}
        />
        <StLine />
      </StWrapper>
      <div id="newsCommentsDivId">
        <BaseSection compact borderless paddingless transparent>
          <NewsComments />
        </BaseSection>
      </div>
      <LikeModal visible={showLikeModal} onClose={() => setShowLikeModal(false)}>
        <LikeModalContent cardType="news" id={likeModalNewsId} limitPerPage={likesLimit} />
      </LikeModal>
    </Div>
  );
});

export default NewsItem;
