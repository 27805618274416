// TODO: move it into base-ui after news merge

import React, { useCallback } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { COLORS, BREAKPOINTS } from 'utils/constants';

import IconButton from 'components/base-ui/icon-button';
import { useGuideStore } from 'store/guide';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import { IconViews } from '../../../base-ui/icon-views';
import { IconLikeWithTooltip } from '../../../base-ui/icon-like-with-tooltip';
import { useProfileStore } from '../../../../store';
import { formatDate } from '../../../../utils/date';
import { IconFavorite } from '../../../base-ui/icon-favorite';
import Icon from '../../../base-ui/icon';

const Div = styled.div`
  padding: 16px 0;
  margin: 0 16px 16px 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.gray5};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 0 52px 16px 52px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin: 0;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${COLORS.gray3};
`;

const DateWrapper = styled.div`
  position: relative;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${COLORS.gray4};
  margin-right: 10px;
`;

const IconViewsCount = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;

const StAction = styled(IconViewsCount)`
  margin-right: 10px;
  cursor: pointer;
`;

const Count = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 20px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${COLORS.gray4};
  margin-right: 10px;
`;

interface Props {
  onShowModal?: (id: number) => void;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const Actions: React.FC<Props> = observer((props) => {
  const store = useGuideStore();
  const profileStore = useProfileStore();
  const print = () => window.print();
  const history = useHistory<{ scroll: boolean }>();

  const commentsDivElement = document.getElementById('guideCommentsDivId');

  const scrollIntoViewHandler = () => {
    commentsDivElement?.scrollIntoView({ block: 'start' });
  };

  const debouncedScroll = debounce(scrollIntoViewHandler, 300);

  React.useEffect(() => {
    if (history.location.state?.scroll) {
      debouncedScroll();
    }
  }, [history]);

  const scrollToRef = () => {
    commentsDivElement?.scrollIntoView({ block: 'start' });
  };

  const handleLikeClick = useCallback(() => {
    store.page?.isLikedByUser
      ? store.handleLikeDelete(store.page?.id!, profileStore.profile?.id!)
      : store.handleLikePost(store.page?.id!, profileStore.profile);
  }, [store.page]);

  const handleFavoriteClick = useCallback(() => {
    store.page?.isInUserFavorites
      ? store.handleFavoriteDelete(store.page?.id!)
      : store.handleFavoritePost(store.page?.id!);
  }, [store.page?.id]);

  const handleShowModal = useCallback(() => {
    if (props.onShowModal && store.page?.id) {
      props.onShowModal(store.page.id);
    }
  }, [store.page?.id, props.onShowModal]);

  const tooltipOffset = React.useMemo(() => {
    if (store.page?.likesCount && store.page?.likesCount > 6) {
      return {
        left: '-140px',
        leftMobile: '-180px',
        arrowLeft: '175px',
        arrowLeftMobile: '220px',
      };
    }

    if (store.page?.likesCount && store.page?.likesCount > 4) {
      return {
        left: '-140px',
        leftMobile: '-150px',
        arrowLeft: '175px',
        arrowLeftMobile: '180px',
      };
    }

    return {
      left: '-120px',
      leftMobile: '-120px',
      arrowLeft: '150px',
      arrowLeftMobile: '150px',
    };
  }, [store.page?.likesCount]);

  return (
    <Div>
      <Wrapper>
        <DateWrapper>{store.page?.publishedAt ? formatDate(store.page?.publishedAt) : ''}</DateWrapper>
        <IconViewsCount>
          <IconViews width="24px" height="24px" color={COLORS.gray4} />
        </IconViewsCount>
        <Count>{store.page?.viewsCount ?? 0}</Count>
      </Wrapper>

      <Wrapper>
        <IconLikeWithTooltip
          isLiked={!!store.page?.isLikedByUser}
          onClick={handleLikeClick}
          likedUsers={store.page?.likeAuthors || []}
          likesCount={store.page?.likesCount || 0}
          newsId={store.page?.id!}
          onShowModal={handleShowModal}
          left={tooltipOffset.left}
          leftMobile={tooltipOffset.leftMobile}
          arrowLeft={tooltipOffset.arrowLeft}
          arrowLeftMobile={tooltipOffset.arrowLeftMobile}
        />
        <StAction onClick={handleFavoriteClick}>
          <IconFavorite active={!!store.page?.isInUserFavorites} />
        </StAction>

        {store.page?.commentsCount! > 0 && (
          <>
            <StAction style={{ cursor: 'pointer' }} onClick={scrollToRef}>
              <Icon name="iconChatBubbleOutline" />
            </StAction>
            <Count>{store.page?.commentsCount}</Count>
          </>
        )}
        <IconButton name="iconPrint" onClick={print} />
      </Wrapper>
    </Div>
  );
});

export default Actions;
