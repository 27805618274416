import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { IconSvg } from 'assets/icons';

export const QuoteWrapper = styled.div`
  font-size: 16px;
  line-height: 30px;
  font-weight: 200;
  font-style: italic;
  color: ${COLORS.azure};
  width: 100%;

  position: relative;

  padding-left: 40px;
  margin-bottom: 48px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 20px;
    padding-left: 0;
    margin-bottom: inherit;
  }
`;

export const QuoteStart = styled(IconSvg.QuoteStart)`
  position: absolute;
  top: -10px;
  left: -5px;
  width: 38px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    left: -45px;
    width: 38px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    left: -78px;
    width: 54px;
  }
`;

export const QuoteEnd = styled(IconSvg.QuoteEnd)`
  position: absolute;
  top: -10px;
  right: -45px;
  width: 38px;
  visibility: hidden;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    visibility: visible;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    top: -10px;
    right: -78px;
    width: 54px;
  }
`;
