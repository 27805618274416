import { makeObservable, observable, action } from 'mobx';

import { api } from '../../utils/api';

import { MainPageCities } from '../../services';

type NullableError = Error | null;

export default class CitiesStore {
  fetching = false;

  error: NullableError = null;

  cities: MainPageCities[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      error: observable,
      cities: observable,
      fetchCities: action,
      setError: action,
      setFetching: action,
      setCities: action,
    });
  }

  fetchCities(): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return api.citiesMainPage
      .mainPageCitiesList()
      .then((res) => this.setCities(res.data.results))
      .catch((err: Error) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  setFetching(value: boolean): void {
    this.fetching = value;
  }

  setError(value: NullableError): void {
    this.error = value;
  }

  setCities(value: MainPageCities[]): void {
    this.cities = value;
  }
}
