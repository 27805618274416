import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BigCard, MediumCard, SmallCard } from 'components/articles/cards';
import { MagazineArticle } from 'services';
import _ from 'lodash';
import { ButtonWrapper, Column, Container, Row } from './styles';
import { LikeModal } from '../../../components/base-ui/like-modal';
import { LikeModalContent } from '../../../components/base-ui/like-modal-content';
import { Button } from '../../../shared/components';
import { ButtonTypes } from '../../../shared/components/button/types';

interface ArticleDesctopProps {
  data: MagazineArticle[];
}

const LIKES_LIMIT = 40;

export const ArticlesDesktop: React.FC<ArticleDesctopProps> = ({ data }) => {
  const [paginationData, setPaginationData] = useState<MagazineArticle[][]>([]);
  const [showModal, setShowModal] = useState(false);
  const [articleId, setArticleId] = useState<number | null>(null);
  const LIMIT = 12;

  useEffect(() => {
    setPaginationData([data.slice(0, LIMIT)]);
  }, []);

  const onClick = useCallback(() => {
    setPaginationData(_.chunk(data, LIMIT));
  }, [data]);

  const onShowModal = useCallback((id: number) => {
    setArticleId(id);
    setShowModal(true);
  }, []);

  return (
    <>
      <Container>
        {paginationData.map((item) => (
          <>
            <Row>
              <BigCard onShowModal={onShowModal} {...item[0]} />
              <Column>
                <MediumCard onShowModal={onShowModal} {...item[1]} />
                <MediumCard onShowModal={onShowModal} {...item[2]} />
              </Column>
            </Row>

            <Row>
              <SmallCard onShowModal={onShowModal} {...item[3]} />
              <SmallCard onShowModal={onShowModal} {...item[4]} />
              <SmallCard onShowModal={onShowModal} {...item[5]} />
            </Row>

            <Row>
              <Column>
                <SmallCard onShowModal={onShowModal} {...item[6]} />
                <SmallCard onShowModal={onShowModal} {...item[8]} />
              </Column>
              <BigCard onShowModal={onShowModal} {...item[7]} />
            </Row>

            <Row>
              <SmallCard onShowModal={onShowModal} {...item[9]} />
              <SmallCard onShowModal={onShowModal} {...item[10]} />
              <SmallCard onShowModal={onShowModal} {...item[11]} />
            </Row>
          </>
        ))}

        <ButtonWrapper>
          {!!data.length && (
            <Button type={ButtonTypes.Secondary} onClick={onClick}>
              {' '}
              Показать все{' '}
            </Button>
          )}
        </ButtonWrapper>
      </Container>
      <LikeModal visible={showModal} onClose={() => setShowModal(false)}>
        <LikeModalContent cardType="article" id={articleId} limitPerPage={LIKES_LIMIT} />
      </LikeModal>
    </>
  );
};
