import { BREAKPOINTS, COLORS } from 'utils/constants';
import styled from 'styled-components';
import { Container } from '../../base-ui/container';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
  padding: 70px 16px 50px 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 50px 52px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 50px 160px;
  }
`;

export const Header = styled.div`
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  color: ${COLORS.azure};
  align-self: flex-start;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 28px;
    line-height: 32px;
    font-weight: 400;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    font-size: 48px;
    line-height: 52px;

    font-weight: 600;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 25px;
  font-weight: 300;
  color: ${COLORS.azure};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
  }
`;

export const StContainer = styled(Container)`
  width: 100%;
  gap: 32px;
  flex-direction: column;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: row;
  }
`;
