import React from 'react';
import { Wrapper, ItemsWrapper, Item, Header, Text, Number } from './styled';

type Props = {
  data: {
    number: number;
    text: string;
  }[];
};

export const LeadersHowItWasBlock: React.FC<Props> = (props) => {
  const getColors = (index: number, count: number) => {
    for (let i = 0; i <= count; i += 3) {
      if (index === i) return { color: '#D12B5E' };
      if (index === i - 1) return { color: '#DFE6ED', fontColor: '#12519A' };
    }
    return { color: '#436F96' };
  };
  return (
    <Wrapper>
      <Header>Программы прошлых лет</Header>
      <ItemsWrapper>
        {props.data.map((item, index) => {
          const { color, fontColor } = getColors(index, props.data.length);
          return (
            <Item key={item.text + item.number}>
              <Number color={color} fontColor={fontColor}>
                {item.number}
              </Number>
              <Text>{item.text}</Text>
            </Item>
          );
        })}
      </ItemsWrapper>
    </Wrapper>
  );
};
