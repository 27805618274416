import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 16px;

  margin: 0 auto;
  gap: 56px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0;
    width: 608px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 1000px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 1328px;
  }
  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    width: 1504px;
  }
`;

export const Wrapper = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    .categories-section {
      padding: 0 52px 0 52px;
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0 24px;
    .categories-section {
      padding: 0 0 0 32px;
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    padding: 0 24px;
  }
`;
