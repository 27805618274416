import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 30px;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid ${COLORS.blueGray};
  }
`;

export const Years = styled.div`
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  overflow-x: auto;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 12px 16px;
    display: block;
    width: 740px;
    margin: 0 auto 30px auto;

    .react-slideshow-container {
      padding: 0 60px 0 60px;
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    padding: 12px 0;
    width: 1360px;
  }
`;

export const Year = styled.div<{ active?: boolean }>`
  text-align: center;
  min-width: 100px;
  position: relative;
  font-size: 26px;
  line-height: 48px;
  font-weight: 500;
  color: ${({ active }) => (active ? COLORS.creatorsRed : COLORS.blueGray)};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  :hover {
    color: ${COLORS.creatorsRed};
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 0;
    width: 100px;
    border-bottom: ${({ active }) => (active ? `6px solid ${COLORS.creatorsRed};` : 'unset')};
    z-index: 555;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    border-bottom: ${(props) => (props.active ? '6px' : '2px')} solid
      ${(props) => (props.active ? COLORS.creatorsRed : COLORS.blueGray)};
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    font-size: 36px;
    font-weight: 400;
    line-height: 60px;
  }
`;

export const Button = styled.button`
  width: 30px;
  background: none;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;
