import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const SpoilerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: row;
  }
`;

export const Title = styled.div`
  font-size: 28px;
  line-height: 30px;
  font-weight: 400;
  color: ${COLORS.azure};
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    min-width: 20%;
    max-width: 50%;
    overflow: hidden;
  }
`;

export const Description = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  color: ${COLORS.blueDark};
  > p {
    margin: 0;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 80%;
  }
`;
