import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AddNewComment } from 'components/comment/add-comment/add-new-comment';
import { useProductCommentsStore } from 'store/product-comments';
import { CommentsList, ShowMoreCommentsButton } from './styles';

import ProductCommentsItem from './comment';
import { Button } from '../../../shared/components';
import { ButtonTypes } from '../../../shared/components/button/types';

const COMMENTS_COUNT = 5;

type ProductCommentsProps = {
  productId: string;
};

const ProductComments: React.FC<ProductCommentsProps> = observer(({ productId }) => {
  const store = useProductCommentsStore();

  const [commentsPage, setCommentsPage] = useState(2);

  useEffect(() => {
    store.fetchComments(Number(productId), COMMENTS_COUNT);
  }, [productId]);

  const onAddNewComment = useCallback(
    (comment: string) => {
      store.addComment({ content: comment, product: productId });
    },
    [productId],
  );

  const onShowMore = useCallback(() => {
    store.fetchNextComments(+productId, commentsPage, COMMENTS_COUNT);
    setCommentsPage(commentsPage + 1);
  }, [commentsPage, productId]);

  const showMoreButton = store.comments.length < store.commentsCount;

  return (
    <div>
      <AddNewComment onAddNewComment={onAddNewComment} count={store.commentsCount} idNewComment="comment-textarea" />

      <CommentsList>{store.comments?.map((el) => <ProductCommentsItem key={el.id} data={el} />)}</CommentsList>

      {showMoreButton && (
        <ShowMoreCommentsButton>
          <Button width="300" suffix={false} type={ButtonTypes.Secondary} onClick={onShowMore}>
            ПОКАЗАТЬ БОЛЬШЕ
          </Button>
        </ShowMoreCommentsButton>
      )}
    </div>
  );
});

export default ProductComments;
