import React, { useCallback } from 'react';
import Section from 'components/base-ui/section';

import { HtmlRenderer } from 'utils/htmlParser';
import { Button } from 'shared/components';
import { useLink } from 'utils/api';
import { ContentWrapper, Content, Title, Description, Image, ImageWrapper, StWrapper, BannerWrapper } from './styles';
import { Props } from './types';

export const LeadersBanner: React.FC<Props> = (props) => {
  const openLink = useLink();

  const handleLinkClick = useCallback(() => {
    openLink(props.button.link);
  }, []);

  return (
    <BannerWrapper>
      <Section noFirstOfTypeOffset compact>
        <ContentWrapper>
          <Content>
            <StWrapper>
              <Title>
                <HtmlRenderer html={props.title} />
              </Title>
              <Description>
                <HtmlRenderer html={props.description} />
              </Description>
            </StWrapper>
            <Button width="240px" onClick={handleLinkClick}>
              {props.button.text}
            </Button>
          </Content>
          <ImageWrapper>
            <Image src={props.image} alt="banner image" />
          </ImageWrapper>
        </ContentWrapper>
      </Section>
    </BannerWrapper>
  );
};
