import { action, computed, makeObservable, observable } from 'mobx';
import { BaseProduct, ProductComment, Product, ProductRating } from 'services';
import { api } from 'utils/api';
import { FrameAdaptiveSize } from 'web';
import { AxiosError } from 'axios';
import AbstractStore from '../AbstractStore';

export default class ProductStore extends AbstractStore {
  product: Product | null = null;

  iframeSize: FrameAdaptiveSize[] = [];

  globalProduct: BaseProduct | null = null;

  productFetching = false;

  globalProductName = '';

  averageRating: number | null = null;

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      product: observable,
      iframeSize: observable,
      globalProduct: observable,
      globalProductName: observable,
      averageRating: observable,
      fetchProduct: action,
      fetchGlobalProduct: action,
      setProduct: action,
      setGlobalProduct: action,
      setGlobalProductName: action,
      changeRating: action,
      fetchAverageRating: action,
      setAverageRating: action,
      setIframeSize: action,
      updateUserRating: action,
      userRating: computed,
      relatedProducts: computed,
      relatedNews: computed,
      productFetching: observable,
      setProductFetching: action,
      addComment: action,
      closeErrorScreen: action,
    });
  }

  fetchProduct(productId: number) {
    this.setProductFetching(true);
    this.fetchData(() =>
      api.products
        .productsRead({ id: productId })
        .then((res) => {
          this.setProduct(res.data);
          this.setProductFetching(false);
        })
        .catch((err: AxiosError) => this.setError(err)),
    );
  }

  fetchAverageRating(productId: number) {
    this.fetchData(() =>
      api.products.productsRead({ id: productId }).then((res) => {
        this.setAverageRating(res.data?.averageRating || '');
      }),
    );
  }

  fetchGlobalProduct(productId: number) {
    this.setProductFetching(true);
    this.fetchData(() =>
      api.globalProducts.globalProductsRead({ id: productId }).then((res) => {
        this.setGlobalProduct(res.data);
        this.setGlobalProductName(res.data.name);
        this.setProductFetching(false);
      }),
    );
  }

  closeErrorScreen(productId: number) {
    this.setProductFetching(true);
    this.fetchData(() =>
      api.closeErrorScreen.closeErrorScreenCreate({ data: { screen: this.product?.errorScreen?.id || 0 } }).then(() => {
        this.fetchProduct(productId);
        this.setProductFetching(false);
      }),
    );
  }

  setProduct(product: Product) {
    this.product = product;
  }

  setIframeSize(size: FrameAdaptiveSize[]) {
    this.iframeSize = size;
  }

  setGlobalProduct(product: BaseProduct) {
    this.globalProduct = product;
  }

  setGlobalProductName(data: string) {
    this.globalProductName = data.replace(/(<([^>]+)>)/gi, '');
  }

  get userRating() {
    return this.product?.userRating ? +this.product.userRating : 0;
  }

  get relatedProducts() {
    return this.product?.relatedProducts || [];
  }

  get relatedNews() {
    return this.product?.relatedGuides || [];
  }

  setProductFetching(status: boolean) {
    this.productFetching = status;
  }

  setAverageRating(ratingValue: string) {
    this.averageRating = +ratingValue;
  }

  addComment(comment: string) {
    const newComment: ProductComment = {
      product: String(this.product?.id),
      content: comment,
    };
    this.fetchData(() => {
      return api.comments
        .productsCommentsCreate({
          data: newComment,
        })
        .then((res) => {
          const p = this.product;
          if (p) {
            p.comments = [...p.comments, res.data];
            this.setProduct(p);
          }
        });
    });
  }

  updateUserRating = (ratings: ProductRating) => {
    const product = this.product;
    if (product) {
      product.userRating = ratings.ratingValue;
      this.setProduct(product);
    }
  };

  changeRating = (ratingValue: number) => {
    const data = { product: String(this.product?.id), ratingValue };
    this.fetchData(() =>
      api.productsRating.productRatingsCreate({ data }).then((res) => this.updateUserRating(res.data)),
    );
  };

  cleanUp = () => {
    this.product = null;
    this.iframeSize = [];
    this.globalProduct = null;
    this.productFetching = false;
    this.globalProductName = '';
    this.averageRating = null;
  };
}
