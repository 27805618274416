import React, { useCallback, useState } from 'react';
import { VideoItemType } from 'store/projects/types';
import Icon from 'components/base-ui/icon';
import { VideoBox } from 'components/base-ui/video-box';
import { Button, StyledDiv, Container, Content, Header, Media, PlayIcon, Text, StVideoWrapper } from './styles';

interface VideoBlockType {
  header: string;
  text: string;
  video: VideoItemType;
}

interface VideoBlockProps {
  data: VideoBlockType;
}

export const VideoBlock: React.FC<VideoBlockProps> = ({ data }) => {
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);

  const handleToggleLightBox = useCallback(() => {
    setIsLightBoxOpen((prevState) => !prevState);
  }, []);

  if (data.video === null) return null;

  return (
    <Container>
      <Media bg={data.video?.preview}>
        <StyledDiv />
        <Button onClick={handleToggleLightBox}>
          <PlayIcon>
            <Icon name="iconPlay" />
          </PlayIcon>
        </Button>
      </Media>
      <Content>
        <Header>{data.header}</Header>
        <Text>{data.text}</Text>
      </Content>
      <StVideoWrapper>
        <VideoBox
          isLightBoxOpen={isLightBoxOpen}
          videos={[data.video]}
          video={data.video}
          onClose={handleToggleLightBox}
        />
      </StVideoWrapper>
    </Container>
  );
};
