import React, { useEffect, useState } from 'react';
import ScrollToTopOnMount from 'components/base-ui/scroll-to-top';
import { DefaultLayout } from 'layouts';
import Breadcrumbs from 'components/base-ui/breadcrumbs';
import { useProjectsStore } from 'store';
import { Magazine } from 'services';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Cover } from './cover';
import { BreadcrumbsContainer, Container, Covers, StTitle } from './styles';
import { useDeviceState } from '../../hooks/useDeviceProvider';

const MagazinesPage: React.FC = () => {
  const store = useProjectsStore();
  const { trackPageView } = useMatomo();
  const [data, setData] = useState<Magazine[]>([]);
  const { isLessTablet } = useDeviceState();

  useEffect(() => {
    store.fetchMagazineData().then(() => {
      setData(store?.magazineData || []);
      document.title = 'Журнал Ассоль';
      trackPageView({});
    });
  }, []);

  return (
    <>
      <ScrollToTopOnMount />
      <DefaultLayout>
        <BreadcrumbsContainer>
          <Breadcrumbs lineStyle={true} />
        </BreadcrumbsContainer>
        {isLessTablet && <StTitle>Журнал «Ассоль»</StTitle>}
        <Container>
          <Covers>
            {data?.map((cover) => (
              <Cover
                key={cover.id}
                id={cover?.id || 0}
                cover={cover?.image || ''}
                link={cover?.pdfFile || ''}
                title={cover?.title || ''}
                year={String(cover?.year) || ''}
              />
            ))}
          </Covers>
        </Container>
      </DefaultLayout>
    </>
  );
};

export default MagazinesPage;
