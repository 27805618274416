import React, { useCallback, useState } from 'react';
import { VideoBox } from 'components/base-ui/video-box';
import { StImage, CardImage, Container, StText } from './styles';
import { Video } from '../../types';

export const Card: React.FC<Video> = ({ video, fullDescription, title }) => {
  const [playerOpen, setPlayerOpen] = useState(false);

  const handlePlayerOpen = useCallback(() => {
    setPlayerOpen((prevState) => !prevState);
  }, []);

  return (
    <Container>
      <CardImage onClick={handlePlayerOpen}>
        <StImage src={video.preview} />
      </CardImage>
      <StText onClick={handlePlayerOpen}>{title}</StText>
      <div>
        <VideoBox
          isLightBoxOpen={playerOpen}
          text={fullDescription}
          videos={[video]}
          video={video}
          onClose={handlePlayerOpen}
        />
      </div>
    </Container>
  );
};
