import React from 'react';
import { AwardPageMainBanner } from 'store/projects/types';
import {
  BackDescription,
  BackSideHeader,
  BackSideHeaderText,
  BigHeader,
  ButtonsWrapper,
  Content,
  Description,
  IconArrow,
  SmallHeader,
} from './styles';
import { Button } from '../../../../../shared/components';
import { ButtonTypes } from '../../../../../shared/components/button/types';

interface BannerContentProps {
  data: AwardPageMainBanner;
  onDetailClick: () => void;
  handleToggleLightBox: () => void;
  isBackSide: boolean;
}

export const BannerContent: React.FC<BannerContentProps> = ({
  data,
  handleToggleLightBox,
  onDetailClick,
  isBackSide,
}) => {
  return (
    <div>
      {isBackSide ? (
        <Content>
          <BackSideHeader onClick={onDetailClick}>
            <IconArrow name="iconArrow" />
            <BackSideHeaderText>{data.moreBlock.header}</BackSideHeaderText>
          </BackSideHeader>
          <BackDescription>{data.moreBlock.description}</BackDescription>
        </Content>
      ) : (
        <Content>
          <SmallHeader>{data.subheader}</SmallHeader>
          <BigHeader>{data.header}</BigHeader>
          <Description>{data.description}</Description>
          <ButtonsWrapper>
            {data.moreButton && (
              <Button type={ButtonTypes.Secondary} onClick={onDetailClick}>
                Подробнее
              </Button>
            )}
            {data.videoButton && data.videoFile && <Button onClick={handleToggleLightBox}>ВИДЕО</Button>}
          </ButtonsWrapper>
        </Content>
      )}
    </div>
  );
};
