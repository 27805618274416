import React, { PropsWithRef, PropsWithChildren, RefObject } from 'react';
import styled, { css } from 'styled-components';

import { COLORS, BREAKPOINTS } from 'utils/constants';

import { H5 } from '../typo';

const shared = css`
  background-color: ${COLORS.white};
`;

interface ContainerProps {
  gradient?: boolean;
  noFirstOfTypeOffset?: boolean;
  noLastOfTypeMargin?: boolean;
}

const gradientStyles = css`
  color: ${COLORS.white};
  background: ${COLORS.linearGradient};
`;

const firstOfTypeOffsetStyles = css`
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    &:first-of-type {
      margin-top: -80px;
    }
  }
`;

const lastOfTypeMarginStyles = css`
  &:last-of-type {
    margin-bottom: 42px;

    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      margin-bottom: 102px;
    }
  }
`;

const Container = styled.section<ContainerProps>`
  z-index: 10;
  position: relative;
  margin-bottom: 1rem;

  ${(props) => props.gradient && gradientStyles};
  ${(props) => !props.noFirstOfTypeOffset && firstOfTypeOffsetStyles}
  ${(props) => !props.noLastOfTypeMargin && lastOfTypeMarginStyles}
`;

const marginX = css`
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin: 0 160px;
  }
`;

const marginXSlim = css`
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin: 0 310px;
  }
`;

const transparentBgStyles = css`
  background: transparent;
`;

interface HeadProps {
  borderless?: boolean;
  marginless?: boolean;
  transparent?: boolean;
  paddingless?: boolean;
  slim?: boolean;
}

const borderedStyles = css`
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 16px;
    right: 16px;
    height: 1px;
    width: calc(100% - 2 * 16px);
    background-color: ${COLORS.gray5};

    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      left: 88px;
      right: 88px;
      width: calc(100% - 2 * 88px);
    }

    @media screen and (min-width: ${BREAKPOINTS.laptop}) {
      left: 32px;
      right: 32px;
      height: 1px;
      width: calc(100% - 2 * 32px);
    }
  }
`;

const marginlessStyles = css`
  margin: 0 !important;
`;

const paddinglessHeadStyles = css`
  padding-left: 0 !important;
  padding-right: 0 !important;

  &:after {
    left: 0;
    width: 100%;
  }
`;

const Header = styled.div<HeadProps>`
  ${shared};

  position: relative;
  color: ${COLORS.secondary};
  border-radius: 2px 2px 0 0;

  ${(props) => (props.slim ? marginXSlim : marginX)};

  ${(props) => !props.borderless && borderedStyles};
  ${(props) => props.marginless && marginlessStyles};
  ${(props) => props.transparent && transparentBgStyles};
  ${(props) => props.paddingless && paddinglessHeadStyles};

  padding: 28px 16px 26px 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: ${(props) => (props.slim ? '28px 52px 26px 52px' : '28px 88px 26px 88px')};
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: ${(props) => (props.slim ? '28px 0px 26px 0px' : '28px 32px 26px 32px')};
  }
`;

interface HeadingProps {
  white?: boolean;
}

const Heading = styled(H5)<HeadingProps>`
  font-weight: 400;
  color: ${(props) => (props.white ? COLORS.white : COLORS.azure)};
  font-size: 22px;
`;

interface BodyProps extends Pick<HeadProps, 'transparent' | 'paddingless' | 'slim'> {
  compact?: boolean;
  medium?: boolean;
  noMinHeight?: boolean;
  paddinglessRightBody?: boolean;
}

const compactStyles = css`
  padding: 18px 16px 26px 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 32px 62px 26px 62px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 40px 32px 26px 32px;
  }
`;

const mediumStyles = css`
  padding: 28px 16px 26px 16px;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 28px 32px 26px 32px;
  }
`;

const paddinglessBodyStyles = css`
  padding: 0 !important;
`;

const paddinglessRightBodyStyles = css`
  padding-right: 0 !important;
`;

const Body = styled.div<BodyProps>`
  ${shared};

  padding: ${(props) => (props.slim ? '0 16px' : '32px 0 26px 0')};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: ${(props) => (props.slim ? '28px 52px' : '32px 0 28px 0')};
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: ${(props) => (props.slim ? '28px 0px' : '32px 0 28px 0')};
  }

  ${(props) => props.compact && !props.slim && compactStyles};
  ${(props) => props.medium && mediumStyles};
  ${(props) => props.transparent && transparentBgStyles};
  ${(props) => props.paddingless && paddinglessBodyStyles};
  ${(props) => props.paddinglessRightBody && paddinglessRightBodyStyles};

  min-height: ${(props) => (props.noMinHeight ? 'unset' : '80px')};
  ${(props) => (props.slim ? marginXSlim : marginX)};
`;

type SectionProps = ContainerProps &
  HeadProps &
  BodyProps &
  PropsWithChildren<{
    ref?: RefObject<HTMLDivElement>;
    heading?: string | React.JSX.Element | React.JSX.Element[];
    paddinglessHead?: boolean;
    className?: string;
  }>;

const Section = React.forwardRef<HTMLDivElement, PropsWithRef<SectionProps>>(
  (
    {
      heading,
      compact,
      medium,
      slim,
      gradient,
      borderless,
      marginless,
      paddingless,
      paddinglessHead,
      paddinglessRightBody,
      noMinHeight,
      noFirstOfTypeOffset,
      noLastOfTypeMargin,
      transparent,
      className,
      children,
    },
    ref,
  ) => (
    <Container
      ref={ref}
      gradient={gradient}
      className={className}
      noFirstOfTypeOffset={noFirstOfTypeOffset}
      noLastOfTypeMargin={noLastOfTypeMargin}
    >
      {heading && (
        <Header
          borderless={borderless}
          marginless={marginless}
          paddingless={paddinglessHead}
          transparent={transparent || gradient}
          slim={slim}
        >
          <Heading white={gradient}>{heading}</Heading>
        </Header>
      )}

      {children && (
        <Body
          compact={compact}
          medium={medium}
          transparent={gradient}
          paddingless={paddingless}
          paddinglessRightBody={paddinglessRightBody}
          noMinHeight={noMinHeight}
          slim={slim}
        >
          {children}
        </Body>
      )}
    </Container>
  ),
);

export default Section;
