import { BREAKPOINTS, COLORS } from 'utils/constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    gap: 60px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    gap: 80px;
  }
`;

export const StTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: ${COLORS.azure};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
  }
`;

export const VideoGallery = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    gap: 40px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
