import React, { useCallback } from 'react';
import { useProductCommentsStore } from 'store/product-comments';
import { CommentHeader } from 'components/comment/comment/comment-header/comment-header';
import { CommentFooter } from 'components/comment/comment/comment-footer/comment-footer';
import { observer } from 'mobx-react-lite';
import { StCommentBlock, StCommentHeader, StContentContainer, StEmptyContentContainer } from './styles';
import { Container } from '../../../base-ui/container';

interface Props {
  scrollToComment: () => void;
}

const LastComment: React.FC<Props> = observer(({ scrollToComment }) => {
  const store = useProductCommentsStore();
  const lastComment = store.comments[0];

  const onLike = useCallback(() => {
    if (lastComment?.id) {
      lastComment?.isLikedByUser
        ? store.toggleLikeDelete(lastComment.id)
        : store.toggleLikePost(lastComment.id);
    }
  }, [lastComment?.isLikedByUser, lastComment?.id]);

  return (
    <StCommentBlock>
      {store.commentsCount > 0 ? (
        <>
          <StCommentHeader>
            <CommentHeader createDate={lastComment?.created ?? ''} profile={lastComment?.profile} />
          </StCommentHeader>
          <StContentContainer>{lastComment?.content}</StContentContainer>
          <Container width="100%">
            <CommentFooter
              visible={false}
              commentId={lastComment?.id}
              onLike={onLike}
              onReply={() => scrollToComment()}
              isLikedByUser={lastComment?.isLikedByUser}
              likesCount={lastComment?.likesCount}
            />
          </Container>
        </>
      ) : (
        <StEmptyContentContainer>Комментариев пока нет</StEmptyContentContainer>
      )}
    </StCommentBlock>
  );
});

export default LastComment;
