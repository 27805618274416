import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { TextButton } from 'components/base-ui/button';

export const Container = styled.div`
  touch-action: manipulation;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid ${COLORS.gray6};
  padding-top: 24px;
  padding-bottom: 48px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 48px 0;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding-bottom: 96px;
  }
`;
export const Header = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: ${COLORS.gray3};
  margin-bottom: 30px;
  margin-left: 16px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 44px;
    line-height: 48px;
    width: 84%;
    margin-left: 0;
    max-width: 1328px;
  }
`;
export const Button = styled(TextButton)`
  display: none;
  width: 160px;
  height: 48px;
  font-size: 14px;
  line-height: 48px;
  text-transform: uppercase;
  border: 1px solid ${COLORS.secondary};
  color: ${COLORS.secondary};
  text-align: center;
  cursor: pointer;
  margin-top: 24px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: block;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-top: 32px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 24px;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-top: 32px;
  }
`;

export const GalleryWrapper = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  width: 100%;
  overflow-x: auto;
  gap: 40px 16px;
  margin-left: 16px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    align-items: flex-start;
    flex-wrap: wrap;
    width: 86%;
    margin-left: 0;
    max-width: 1328px;
  }
`;
