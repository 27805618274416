import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ParagraphValue } from 'web';

import Section from 'components/base-ui/section';
import Paragraph from 'components/base-ui/paragraph';
import HtmlContainer from 'components/base-ui/html-container';
import { HtmlRenderer } from 'utils/htmlParser';
import { PropsWithSectionOptions } from 'components/renderers/blocks';

interface StyledSectionProps {
  noMarginBottom?: boolean;
}

const StyledSection = styled(Section)<StyledSectionProps>`
  margin-bottom: ${(props) => (props.noMarginBottom ? 0 : '32px')};
`;

type ParagraphRendererProps = PropsWithSectionOptions<{
  value: ParagraphValue;
}>;

const ParagraphRenderer: React.FC<ParagraphRendererProps> = ({ value, options }) => {
  const location = useLocation();

  const { productId } = useParams<{ productId?: string }>();

  const isGoodsListPage = useMemo(
    () => location.pathname.includes('goods') && productId === undefined,
    [location.pathname, productId],
  );

  return (
    <StyledSection paddingless borderless {...options} noMarginBottom={isGoodsListPage} className="paragraph-section">
      <Paragraph title={value.header} bordered={isGoodsListPage} spoiler={value.spoiler}>
        <HtmlContainer gray>
          <HtmlRenderer html={value.text} />
        </HtmlContainer>
      </Paragraph>
    </StyledSection>
  );
};

export default ParagraphRenderer;
