import React, { useMemo } from 'react';
import { ProfileDataForComment } from 'services';
import { DateInfo, UserInfo, UserInfoWrapper } from './styles';

interface Props {
  profile?: ProfileDataForComment;
  createDate?: string;
}

export const CommentHeader: React.FC<Props> = (props) => {
  const username = useMemo(() => {
    return props.profile?.firstName && props.profile?.lastName
      ? `${props.profile.firstName} ${props.profile.lastName}`
      : 'Неизвестный автор';
  }, []);

  const createDate = useMemo(() => {
    return props.createDate ? new Date(props.createDate).toLocaleDateString() : '---';
  }, []);

  return (
    <UserInfoWrapper>
      <UserInfo bold>{username}</UserInfo>
      <DateInfo style={{ fontSize: '12px' }}>{createDate}</DateInfo>
    </UserInfoWrapper>
  );
};
