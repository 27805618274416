import { action, makeObservable, observable } from 'mobx';
import { api } from 'utils/api';
import {
  AwardPageContentType,
  AwardPageMainBanner,
  AwardPageSlideBlock,
  AwardType,
  BigBannerType,
  GalleryType,
  MediaBlockType,
  Nullable,
  PhotoCarouselDataType,
  SmallHeaderType,
  VideoGalleryType,
} from 'store/projects/types';
import { Block } from 'utils/blocks';
import {
  AwardBusiness,
  AwardWinners,
  Magazine,
  MagazineArticle,
  Profile,
  ProjectCards,
  ProjectsApiProjectsAwardsBusinessRequest,
  ProjectsApiProjectsAwardWinnersListRequest,
  ProjectsApiProjectsMagazinesArticlesListRequest,
} from 'services';
import { AxiosError } from 'axios';
import AbstractStore, { NullableError } from '../AbstractStore';

export default class ProjectsStore extends AbstractStore {
  fetching = false;

  projectsData: Nullable<ProjectCards[]> = null;

  magazineData: Nullable<Magazine[]> = null;

  magazineArticle: MagazineArticle | null = null;

  magazineArticleContent: Block[] = [];

  articles: MagazineArticle[] = [];

  scarletData: Nullable<Block[]> = null;

  awardPageData: AwardPageContentType[] = [];

  awards: AwardType[] = [];

  awardWinners: AwardWinners[] = [];

  awardPageBannerData: Nullable<AwardPageMainBanner> = null;

  awardPageSliderData: AwardPageSlideBlock[] = [];

  awardMediaData: Nullable<MediaBlockType> = null;

  videoWithTextData: Nullable<MediaBlockType> = null;

  awardPhotoGallery: Nullable<GalleryType> = null;

  awardVideoGallery: Nullable<VideoGalleryType> = null;

  awardBusiness: AwardBusiness[] = [];

  error: NullableError = null;

  photoCarouselData: Nullable<PhotoCarouselDataType> = null;

  bigBannerData: Nullable<BigBannerType> = null;

  smallBannerData: Nullable<SmallHeaderType> = null;

  countArticles = 0;

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      fetching: observable,
      countArticles: observable,
      photoCarouselData: observable,
      bigBannerData: observable,
      awardPageData: observable,
      awardPageBannerData: observable,
      awardPageSliderData: observable,
      awardMediaData: observable,
      videoWithTextData: observable,
      awards: observable,
      awardWinners: observable,
      awardBusiness: observable,
      awardPhotoGallery: observable,
      awardVideoGallery: observable,
      smallBannerData: observable,
      error: observable,
      projectsData: observable,
      magazineData: observable,
      magazineArticle: observable,
      magazineArticleContent: observable,
      articles: observable,
      scarletData: observable,
      fetchProjectsData: action,
      fetchAwardBusiness: action,
      fetchMagazineData: action,
      handleLikePost: action,
      handleLikeDelete: action,
      handleFavoritePost: action,
      handleFavoriteDelete: action,
      fetchArticles: action,
      fetchScarletData: action,
      setError: action,
      setFetching: action,
      setProjectsData: action,
      setScarletData: action,
      setBigBannerData: action,
      setPhootoCarouselData: action,
      setVideoWithTextData: action,
      cleanUp: action,
    });
  }

  fetchProjectsData(): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return api.projects
      .projectsProjectsCardsList()
      .then((res) => {
        this.setProjectsData(res.data.results);
      })
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

    async fetchContentMagazinesArticles({ id }: { id: number }) {
        try {
            const res = await api.projects.projectsMagazinesArticlesRead({ id });
            this.magazineArticle = res.data;
            this.magazineArticleContent = res.data.content as unknown as Block[];
            return res.data;
        } catch (error) {
            console.error('Error fetching magazine article:', error);
            throw error;
        }
    }


  fetchMagazineData(): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return api.projects
      .projectsMagazinesList()
      .then((res) => this.setMagazineData(res.data.results))
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  fetchArticles(requestParams: ProjectsApiProjectsMagazinesArticlesListRequest): Promise<void> {
    this.setError(null);
    this.setFetching(true);
    return api.projects
      .projectsMagazinesArticlesList(requestParams)
      .then(({ data: { results, count } }) => {
        this.setArticles(results);
        this.setArticlesCount(count);
      })
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  fetchAwards(): Promise<void> {
    this.setError(null);
    this.setFetching(true);
    return api.projects
      .projectsAwardsList()
      .then((res) => {
        const awardCards = [...res.data.results].sort((a, b) => (+a.year < +b.year ? 1 : -1));
        this.setAwards(awardCards as unknown as AwardType[]);
      })
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  fetchAwardsWinners(requestParams: ProjectsApiProjectsAwardWinnersListRequest): Promise<void> {
    this.setError(null);
    this.setFetching(true);
    return api.projects
      .projectsAwardWinnersList(requestParams)
      .then((res) => this.setAwardWinners(res.data.results))
      .catch((err) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  fetchScarletData(): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return api.projects
      .projectsScarletSailsList()
      .then((res) => {
        this.setScarletData(res.data.content as unknown as Block[]);
        const bigBannerData = this.scarletData?.filter((item) => item.type === 'big_banner');
        if (bigBannerData) {
          this.setBigBannerData(bigBannerData[0].value as unknown as Block[]);
        }
        const smallBannerData = this.scarletData?.filter((item) => item.type === 'small_banner');
        if (smallBannerData) {
          this.setSmallBannerData(smallBannerData[0].value as unknown as SmallHeaderType);
        }
        const photoData = this.scarletData?.filter((item) => item.type === 'photo_carousel');
        if (photoData) {
          this.setPhootoCarouselData(photoData[0].value as unknown as Block[]);
        }
      })
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  fetchAwardBusiness(requestParams: ProjectsApiProjectsAwardsBusinessRequest): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return api.projects
      .projectsAwardsBusiness(requestParams)
      .then((res) => this.setAwardBusiness(res.data as unknown as AwardBusiness[]))
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  fetchAwardPadeData(): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return api.projects.projectsAwardsPagesList().then((res) => {
      this.setAwardPageData(res.data.content as unknown as AwardPageContentType[]);
      this.setawardPageBannerData(
        this.awardPageData
          .filter((item) => item.type === 'main_banner')
          .map((item) => item.value as unknown as AwardPageMainBanner)[0],
      );
      this.setAwardPageSliderData(
        this.awardPageData
          .filter((item) => item.type === 'slide_block')
          .map((item) => item.value)[0] as unknown as AwardPageSlideBlock[],
      );
      this.setAwardMediaData(
        this.awardPageData
          .filter((item) => item.type === 'media_with_text')
          .map((item) => item.value as unknown as MediaBlockType)[0],
      );

      this.setVideoWithTextData(
        this.awardPageData
          .filter((item) => item.type === 'video_with_text')
          .map((item) => ({ ...item.value, media: 'hostingVideo' }) as unknown as MediaBlockType)[0],
      );

      this.setAwardPhotoGallery(
        this.awardPageData
          .filter((item) => item.type === 'photo_gallery')
          .map((item) => item.value as unknown as GalleryType)[0],
      );

      this.setAwardVideoGallery(
        this.awardPageData
          .filter((item) => item.type === 'video_gallery')
          .map((item) => item.value as unknown as VideoGalleryType)[0],
      );
    });
  }

  handleLikePost = (articleId: number, profile: Profile | null) => {
    api.projects.projectsMagazinesArticlesToggleLikeCreate({ id: articleId }).then(() => {
      if (this.articles && profile) {
        this.setArticles(
          this.articles.map((n) => {
            n.id === articleId && (n.isLikedByUser = true);
            n.id === articleId && (n.likesCount ? (n.likesCount += 1) : (n.likesCount = 1));
            n.id === articleId &&
              n.likeAuthors &&
              (n.likeAuthors =
                n.likeAuthors.length < 6
                  ? [
                      ...n.likeAuthors,
                      {
                        id: profile.id!,
                        image: profile.image,
                        fullName: `${profile?.lastName} ${profile?.firstName}`,
                        email: profile.user?.email!,
                      },
                    ]
                  : [...n.likeAuthors]);
            return n;
          }),
        );
      }
    });
  };

  handleLikeDelete = (articleId: number, userId: number) => {
    api.projects.projectsMagazinesArticlesToggleLikeDelete({ id: articleId }).then(() => {
      if (this.articles) {
        this.setArticles(
          this.articles.map((n) => {
            n.id === articleId && (n.isLikedByUser = false);
            n.id === articleId && (n.likesCount ? (n.likesCount -= 1) : (n.likesCount = 0));
            // @ts-ignore
            n.id === articleId && (n.likeAuthors = n.likeAuthors?.filter((item) => item.id !== userId));
            return n;
          }),
        );
      }
    });
  };

  handleFavoritePost = (articleId: number) => {
    api.projects.projectsMagazinesArticlesToggleFavoriteCreate({ id: articleId }).then(() => {
      if (this.articles) {
        this.setArticles(
          this.articles.map((n) => {
            n.id === articleId && (n.isInUserFavorites = true);
            return n;
          }),
        );
      }
    });
  };

  handleFavoriteDelete = (articleId: number) => {
    api.projects.projectsMagazinesArticlesToggleFavoriteDelete({ id: articleId }).then(() => {
      if (this.articles) {
        this.setArticles(
          this.articles.map((n) => {
            n.id === articleId && (n.isInUserFavorites = false);
            return n;
          }),
        );
      }
    });
  };

  setFetching(value: boolean): void {
    this.fetching = value;
  }

  setError(value: NullableError): void {
    this.error = value;
  }

  setProjectsData(value: ProjectCards[]): void {
    this.projectsData = value;
  }

  setMagazineData(value: Magazine[]): void {
    this.magazineData = value;
  }

  setArticles(value: MagazineArticle[]): void {
    this.articles = value;
  }

  setArticlesCount(value: number): void {
    this.countArticles = value;
  }

  setScarletData(value: Block[]): void {
    this.scarletData = value;
  }

  setPhootoCarouselData(value: Block[]): void {
    //@ts-expect-error
    const images = [...value.image];
    //@ts-expect-error
    const title = value.title;
    this.photoCarouselData = { title, photos: images.map((val, index) => ({ url: val, id: index.toString() })) };
  }

  setBigBannerData(value: Block[]): void {
    this.bigBannerData = value as unknown as BigBannerType;
  }

  setSmallBannerData(value: SmallHeaderType): void {
    this.smallBannerData = value as unknown as SmallHeaderType;
  }

  setAwardBusiness(value: AwardBusiness[]): void {
    this.awardBusiness = value;
  }

  setawardPageBannerData(value: AwardPageMainBanner): void {
    this.awardPageBannerData = value;
  }

  setAwardPageData(value: AwardPageContentType[]): void {
    this.awardPageData = value;
  }

  setAwardPageSliderData(value: AwardPageSlideBlock[]): void {
    this.awardPageSliderData = value;
  }

  setAwardMediaData(value: MediaBlockType): void {
    this.awardMediaData = value;
  }

  setVideoWithTextData(value: MediaBlockType): void {
    this.videoWithTextData = value;
  }

  setAwardPhotoGallery(value: GalleryType): void {
    this.awardPhotoGallery = value;
  }

  setAwardVideoGallery(value: VideoGalleryType): void {
    this.awardVideoGallery = value;
  }

  setAwards(value: AwardType[]): void {
    this.awards = value;
  }

  setAwardWinners(value: AwardWinners[]): void {
    this.awardWinners = value;
  }

  cleanUp = () => {
    this.projectsData = null;
    this.magazineData = null;
    this.articles = [];
    this.scarletData = null;
    this.awardPageData = [];
    this.awards = [];
    this.awardWinners = [];
    this.awardPageBannerData = null;
    this.awardPageSliderData = [];
    this.awardMediaData = null;
    this.awardPhotoGallery = null;
    this.awardVideoGallery = null;
    this.awardBusiness = [];
    this.error = null;
    this.photoCarouselData = null;
    this.bigBannerData = null;
    this.smallBannerData = null;
    this.countArticles = 0;
  };
}
