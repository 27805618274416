import React from 'react';
import { Accordion } from '@szhsin/react-accordion';
import { Header } from './header';
import { Item, StAccordionItem, Wrapper } from './styles';
import { IconPoint } from '../icon';

type Props = {
  data?: { header: string; points: string[] }[];
};

export const AccordionColumns: React.FC<Props> = ({ data }) => {
  return (
    <Wrapper>
      <Accordion>
        {data?.map((column) => (
          <StAccordionItem key={column.header} header={<Header text={column.header} />}>
            {column.points.map((point) => (
              <Item key={point}>
                <IconPoint />
                {point}
              </Item>
            ))}
          </StAccordionItem>
        ))}
      </Accordion>
    </Wrapper>
  );
};
