import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from '../../../../utils/constants';

export const Container = styled.div<{ image: string; visible: boolean }>`
  display: ${(props) => (props.visible ? 'unset' : 'none')};
  width: 100%;
  margin: 0 16px;
  height: 592px;
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 45.83%, rgba(0, 0, 0, 0.53) 59.93%, black 79.66%, black 100%),
    url(${(props) => props.image || ''});
  background-size: cover;
  background-position: center;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 720px;
    height: 720px;
    margin: unset;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 816px;
    height: 816px;
  }
`;
export const Content = styled.div`
  position: relative;
  top: 372px;
  height: 270px;
  width: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: column;

  color: ${COLORS.white};
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-left: 24px;
    padding: 0;
    top: 450px;
    width: 436px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    top: 546px;
    width: 536px;
  }
`;
export const HeaderWrapper = styled.div`
  width: 100%;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 448px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 512px;
  }
`;
export const Header = styled.h2`
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin: 0 0 16px 0;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 34px;
    font-weight: 600;
    line-height: 40px;
  }
`;
export const Description = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 0 0 16px 0;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 20px;
    line-height: 26px;
    width: 448px;
    padding-right: 20px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 512px;
  }
`;
export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const FooterIconsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
export const FooterItem = styled.div`
  width: 24px;
  height: 24px;
`;

export const Count = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: white;
  margin: 0 10px;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

export const Button = styled.div`
  display: flex;
  cursor: pointer;
  height: 24px;
`;
export const ButtonText = styled.p`
  margin-right: 10px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${COLORS.white};
  user-select: none;
`;
