import React, { useCallback } from 'react';
import { useLink } from 'utils/api';
import { useDeviceState } from 'hooks/useDeviceProvider';
import { ContentWrapper, Title, TextLink, Text } from './styles';
import Icon from '../../../base-ui/icon';
import { Container } from '../../../base-ui/container';

type Props = {
  name: string;
  text?: string | null;
  link?: string | null;
  linkText?: string | null;
};

export const TooltipContent: React.FC<Props> = (props) => {
  const goToLink = useLink();
  const { isLessLaptop } = useDeviceState();

  const onLink = useCallback(() => {
    if (!props.link) return;
    goToLink(props.link);
  }, [props.link]);

  const onClick = () => {
    const el = document.getElementById(`tooltip-${props.name}`);
    el?.classList.remove('show');
  };

  return (
    <ContentWrapper>
      <Container justify="space-between">
        <Title>{props.name}</Title>
        {isLessLaptop && <Icon name="iconCrossBase" onClick={onClick} />}
      </Container>
      <Text>{props.text}</Text>
      {props.linkText && (
        <Container align="center">
          <Icon name="iconPlace" />
          <TextLink onClick={onLink}>{props.linkText}</TextLink>
        </Container>
      )}
    </ContentWrapper>
  );
};
