import styled, { css } from 'styled-components';

import { BREAKPOINTS } from '../../../utils/constants';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

interface ColumnProps {
  width?: string;
}

const noGapStyles = css`
  row-gap: unset !important;
  column-gap: unset !important;
`;

const DEFAULT_WIDTH = '320px';

const autoColumnsStyles = css<ColumnProps>`
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    grid-template-columns: repeat(auto-fill, ${(props) => props.width ?? DEFAULT_WIDTH});
  }
`;

const stretchWhitespaceStyles = css<ColumnProps>`
  width: unset;
  display: inline-grid;
  grid-template-columns: repeat(2, ${(props) => props.width ?? DEFAULT_WIDTH});

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    grid-template-columns: repeat(3, ${(props) => props.width ?? DEFAULT_WIDTH});
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    grid-template-columns: repeat(4, ${(props) => props.width ?? DEFAULT_WIDTH});
  }
`;

export interface GridProps extends ColumnProps {
  noGap?: boolean;
  mode?: 'auto-columns' | 'stretch-whitespace';
  rowGap?: string;
  columnGap?: string;
}

export const Grid = styled.div<GridProps>`
  display: grid;
  row-gap: ${(props) => props.rowGap ?? '16px'};
  column-gap: ${(props) => props.columnGap ?? '16px'};

  ${(props) => props.noGap && noGapStyles};

  ${(props) => (props.mode === 'auto-columns' ? autoColumnsStyles : stretchWhitespaceStyles)}
`;
