import React, { useEffect } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { useGlobalCategoriesStore } from 'store';

import Cards from 'components/market/cards';

const Section = styled.section`
  width: 100%;
`;

const CategoriesRenderer: React.FC = observer(() => {
  const store = useGlobalCategoriesStore();

  useEffect(() => autorun(() => store.fetchCategories()), [store]);

  return (
    <Section className="categories-section">
      <Cards items={store.categories.filter((category) => category.forMarketMenu)} />
    </Section>
  );
});

export default CategoriesRenderer;
