import React from 'react';

const svgIcon = (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 2H30C31.1046 2 32 2.89543 32 4V20" stroke="#D12B5E" strokeWidth="3" strokeLinecap="round" />
    <rect y="12" width="22" height="22" rx="2" fill="#436F96" />
  </svg>
);

export const HeaderIcon: React.FC = () => {
  return <div style={{ width: '34px', height: '34px' }}>{svgIcon}</div>;
};
