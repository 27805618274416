import { BREAKPOINTS, COLORS } from 'utils/constants';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: row;
    gap: 24px;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 50%;
    max-height: 620px;
  }
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    object-fit: cover;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 0 24px 0;
  gap: 40px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    align-items: flex-start;
    padding: 40px 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    gap: 80px;
    width: 50%;
  }
`;

export const Title = styled.div`
  font-size: 26px;
  line-height: 30px;
  font-weight: 500;
  color: ${COLORS.azure};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 44px;
    line-height: 52px;
    font-weight: 700;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    font-size: 48px;
  }
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: ${COLORS.blueDark};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 16px;
    font-weight: 300;
  }
`;

export const StWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  p {
    margin: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    gap: 40px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding-right: 40px;
  }
`;

export const BannerWrapper = styled.div`
  margin-top: 70px;

  @media screen and (min-width: ${BREAKPOINTS.mobile}) {
    section {
      margin-bottom: 20px !important;
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    section > div {
      padding: 28px 52px;
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    section > div {
      padding: 0;
      margin: 0 160px;
    }
    section {
      margin-bottom: 102px !important;
    }
  }
`;
