import Section from 'components/base-ui/section';
import React from 'react';
import { HtmlRenderer } from 'utils/htmlParser';
import { BannerWrapper, Content, Image, ImageWrapper, Title } from './styles';

type PropsType = {
  title: string;
  image?: string;
};

export const Banner: React.FC<PropsType> = ({ title, image }) => {
  return (
    <Section noFirstOfTypeOffset noLastOfTypeMargin compact>
      <BannerWrapper>
        <Content>
          <Title>
            <HtmlRenderer html={title} />
          </Title>
        </Content>
        <ImageWrapper>
          <Image src={image} />
        </ImageWrapper>
      </BannerWrapper>
    </Section>
  );
};
