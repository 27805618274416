import React from 'react';
import { HtmlRenderer } from 'utils/htmlParser';
import { LeadersAboutProgramType } from 'utils/blocks';
import { Content, Description, Header, Wrapper, ColumnWrapper, ColumnHeader, ColumnText, Column } from './styles';

import { Icon } from './components';

type Props = LeadersAboutProgramType;

export const LeadersAboutProgram: React.FC<Props> = (props) => {
  return (
    <Wrapper>
      <Content>
        <Header>О программе</Header>
        <Description>
          <HtmlRenderer html={props.description} />
        </Description>
      </Content>

      {props.columns.length > 0 && (
        <ColumnWrapper>
          {props.columns.map((column, index) => (
            <Column key={column.header}>
              <Icon />
              <div>
                <ColumnHeader>{column.header}</ColumnHeader>
                <ColumnText>{column.text}</ColumnText>
              </div>
            </Column>
          ))}
        </ColumnWrapper>
      )}
    </Wrapper>
  );
};
