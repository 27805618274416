import { textStyles } from './../../../components/base-ui/multi-select/styles';
import { colors } from './../../../shared/components/button/constants';
import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import LiIcon from 'assets/icons/icon_roomb.svg'

export const ModalContainer = styled.div`
 position: absolute;
  top: 60px;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 99;
  isolation: isolate;
  color: ${COLORS.white};
  width: 100%;
  max-height: 80%;
  padding: 8px;
  background: ${COLORS.azureGradient};
  border-radius: 12px;

  @media screen and (min-height: ${BREAKPOINTS.mobileLandscape}) {
    left: 50%;
    width: calc(100% - 16px * 2);
    height: unset;
    transform: translateX(-50%);
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) and (min-height: 400px) {
    top: 25%;
    width: 656px;
    padding: 24px 32px 56px 32px;
    position: fixed;
  }
`;


export const ModalContentWrapper = styled.div`
  max-height: 80%;
  overflow-y: auto;
  padding: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;

  ul {
    margin: 0;
    padding-inline-start: 16px;
  }

  a {
    color: ${COLORS.white};
    text-decoration: underline;
    font-weight: 500;
  }
  
  list-style-image: url(${LiIcon});
  

  ::-webkit-scrollbar {
    width: 1px;
    background-color: ${COLORS.gray5};
  }

  ::-webkit-scrollbar-thumb {
    outline: 1px solid ${COLORS.white};
    border-radius: 2px;
    background-color: ${COLORS.white};
  }
`;

export const ModalCloseWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;


export const Header = styled.div`
width: 100%;
display: flex;
justify-content: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 26px;
    line-height: 32px;
    font-weight: 500;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
