// TODO: move it into base-ui after news merge

import React from 'react';
import styled from 'styled-components';

import { BREAKPOINTS } from '../../../../utils/constants';

const BannerWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 56px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    height: 800px;
  }
`;

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    object-fit: cover;
  }
`;

interface BannerProps {
  image: string;
}

const Banner: React.FC<BannerProps> = ({ image }) => {
  return (
    <BannerWrapper>
      <BannerImage src={image} />
    </BannerWrapper>
  );
};

export default Banner;
