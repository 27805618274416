import { useMatomo } from '@datapunt/matomo-tracker-react';
import { BlocksRender } from 'components/renderers';
import { compact } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useLeaderCodeStore } from 'store/leader-code';
import { Block } from 'utils/blocks';

export const LeaderCode: React.FC = observer(() => {
  const store = useLeaderCodeStore();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    store.fetchContent();
    document.title = 'Код лидера';
    trackPageView({});
  }, []);

  return (
    <BlocksRender
      blocks={store?.content?.content as unknown as Block[]}
      loading={store?.fetching}
      options={{ section: { compact: true } }}
    />
  );
});
