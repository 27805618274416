import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const StContainer = styled.div`
  background: ${COLORS.linearGradient};
  margin: 60px 0;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 110px 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    margin: 140px 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    margin: 140px 0;
  }
`;

export const StHeader = styled.div`
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  width: 100%;
  padding: 0 16px;
  color: ${COLORS.white};
  margin: 0 auto 60px auto;
  padding-top: 90px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 34px;
    line-height: 42px;
    width: 544px;
    padding: 100px 0 0 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    font-size: 44px;
    line-height: 48px;
    width: 1000px;
    padding-top: 140px;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    width: 1000px;
  }
`;

export const StColumns = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: ${COLORS.white};
  margin: 0 auto;
  padding-bottom: 98px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 656px;
    padding-bottom: 100px;
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 1328px;
    padding-bottom: 112px;
  }
  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    width: 1504px;
    padding-bottom: 140px;
  }
`;

export const StColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 288px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 286px;
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 280px;
  }
  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    width: 340px;
  }
`;

export const StColumnHeader = styled.div`
  font-weight: 700;
`;
