import React from 'react';
import { HtmlRenderer } from 'utils/htmlParser';
import { ColumnWrapper, Description, Title } from './styles';

type PropsType = {
  header?: string;
  text: string;
  width: number;
};

export const Column: React.FC<PropsType> = ({ header, text, width }) => {
  return (
    <ColumnWrapper w={width}>
      <Title>{header}</Title>
      <Description>
        <HtmlRenderer html={text} />
      </Description>
    </ColumnWrapper>
  );
};
