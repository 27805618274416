import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import Slider from 'react-slick';

export const StImageGallery = styled.div`
  width: 100%;
  height: auto;
  padding: 0 16px;
  margin: 40px auto 0 auto;
  overflow: hidden;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 656px;
    height: 369px;
    margin: 40px auto 110px auto;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 1220px;
    height: 755px;
    margin: 60px auto 140px auto;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    width: 1504px;
    height: 915px;
    margin: 60px auto 140px auto;
  }
`;

export const StImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StImageWrapper = styled.div`
  width: 100%;
  height: 228px;

  overflow: hidden;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: 40px;
    width: 656px;
    height: 369px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 1220px;
    height: 686px;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    width: 1504px;
    height: 846px;
  }
`;

export const StSlider = styled(Slider)`
  .slick-next {
    width: 95px;
    z-index: 5;
    padding-right: 32px;
  }

  .slick-prev {
    width: 95px;
    z-index: 5;
    padding-left: 32px;
  }

  .slick-dots {
    button {
      ::before {
        color: ${COLORS.secondary};
        opacity: 1;
        font-size: 16px;
      }
      }
    }
  }

  .slick-dots .slick-active {
    button {
      ::before {
        color: ${COLORS.primary};
        opacity: 1;
      }
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    .slick-prev {
      width: 88px;
      z-index: 5;
      padding-left: 40px;
    }
    .slick-next {
      width: 96px;
      z-index: 5;
      padding-right: 48px;
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    .slick-prev {
      width: 88px;
      padding-left: 40px;
    }
    .slick-next {
      width: 96px;
      z-index: 5;
      padding-right: 48px;
    }
  }
`;
