import styled from 'styled-components';
import { BASIC_FONT_FAMILY, BREAKPOINTS, COLORS } from 'utils/constants';
import { ColorsProps, ButtonTypes } from './types';

export const StButton = styled.button<{
  colors: ColorsProps;
  buttonType?: ButtonTypes;
  width?: string;
  disabled?: boolean;
  suffix: boolean;
}>`
  display: flex;
  justify-content: ${(props) => (props.suffix ? 'space-between' : 'center')};
  font-family: ${BASIC_FONT_FAMILY};
  align-items: center;
  gap: 36px;
  padding: 12px 24px;
  color: ${(props) => props.colors.color};
  background: ${(props) => props.colors.background};
  font-size: 16px;
  line-height: 24px;
  border: ${(props) => {
    if (props.buttonType === ButtonTypes.Secondary) {
      return `1px solid ${props.colors.color}`;
    }
    if (props.buttonType === ButtonTypes.Inverse) {
      return '1px solid white';
    }
    return '1px solid transparent';
  }};
  border-radius: 26px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  width: ${({ width }) => width ?? '220px'};
  background-clip: padding-box;

  :disabled {
    color: ${COLORS.placeholderGray};
    background: ${COLORS.greenGray};
    cursor: not-allowed;
  }
  white-space: nowrap;
  overflow: hidden;

  svg {
    fill: ${(props) => props.colors.color};
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    :hover {
      ${(props) =>
        !props.disabled &&
        `
          color: ${props.colors.howerColor};
          background: ${props.colors.howerBackground};
          transition: all cubic-bezier(0.55, 0.055, 0.675, 0.19);
          border: ${
            props.buttonType === ButtonTypes.Ghost || props.buttonType === ButtonTypes.GhostInverse
              ? `1px solid ${props.colors.color}`
              : '1px solid transparent'
          };
          svg {
            fill: ${props.colors.howerColor};
          }
        `}
    }
  }
`;
