import { BREAKPOINTS, COLORS } from 'utils/constants';
import styled from 'styled-components';
import { Container } from '../../../base-ui/container';

export const CardContainer = styled(Container)`
  display: flex;
  flex-direction: column;

  align-items: flex-start;

  width: 134px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 200px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 291px;
  }
`;

export const Name = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  color: ${COLORS.blueDark};
  word-wrap: break-word;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 18px;
  }
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  color: ${COLORS.blueGray};
  word-wrap: break-word;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const StImageWrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;

  height: auto;
  margin-bottom: 16px;

  width: 134px;
  height: 92px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 200px;
    height: 138px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 291px;
    height: 200px;
  }
`;

export const StImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
