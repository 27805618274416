import React from 'react';
import { ReactComponent as ArrowRight } from 'assets/icons/icon_arrow_right.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/icon_arrow_left.svg';
import { useDeviceType } from 'utils/hooks';
import { Slide } from 'react-slideshow-image';
import { COLORS } from 'utils/constants';
import { Years, Wrapper, Year, Button } from './styles';

type Props = {
  years: number[];
  active?: number;
  onClick: (year: number) => void;
};

export const Carousel: React.FC<Props> = ({ years, active, onClick }) => {
  const device = useDeviceType();

  const slideProperties = {
    prevArrow: (
      <Button>
        <ArrowLeft fill={COLORS.blueGray} />
      </Button>
    ),
    nextArrow: (
      <Button>
        <ArrowRight fill={COLORS.blueGray} />
      </Button>
    ),
  };

  return (
    <>
      {device === 'mobile' && (
        <Wrapper>
          <Years>
            {years.map((year) => (
              <Year active={year === active} onClick={() => onClick(year)}>
                {year}
              </Year>
            ))}
          </Years>
        </Wrapper>
      )}
      {device !== 'mobile' && (
        <Years>
          <Slide {...slideProperties} slidesToShow={device === 'tablet' ? 4 : 6} autoplay={false}>
            {years.map((year, index) => (
              <Year key={index} active={year === active} onClick={() => onClick(year)}>
                {year}
              </Year>
            ))}
          </Slide>
        </Years>
      )}
    </>
  );
};
