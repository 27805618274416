import styled from 'styled-components';

import { BREAKPOINTS, COLORS } from 'utils/constants';
import ellipseMark from 'assets/icons/ellipse_mark.svg';
import HtmlContainer from '../../../base-ui/html-container';

export const StBlockWrapper = styled.div`
  margin: 30px 0;
`;

export const StHeaderWrapper = styled.div`
  font-size: 26px;
  line-height: 30px;
  padding: 0;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 36px;
    line-height: 44px;
    font-weight: 400;
  }
`;

export const StContainer = styled(HtmlContainer)`
  font-size: 16px;
  line-height: 24px;

  > :first-of-type {
    margin-top: 0 !important;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 18px;
    line-height: 26px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin: 0;
  }

  img {
    max-width: 100%;
  }

  ul {
    padding-inline-start: 20px;

    li {
      padding-left: 15px;
      list-style-image: url(${ellipseMark});

      ::marker {
        line-height: 16px;
      }
    }
  }

  h5 {
    font-size: 26px;
    line-height: 30px;

    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      font-size: 36px;
      line-height: 44px;
    }
  }
`;

export const StStarsWrapper = styled.div`
  > :first-of-type {
    margin-top: 0 !important;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    section {
      margin-bottom: 0px !important;
    }
  }
`;
