import React from 'react';
import { MagazineArticleBannerContent } from 'web';
import {
  Annotation,
  BannerContent,
  BannerImage,
  BannerImageWrapper,
  BigHeader,
  Container,
  SmallHeader,
} from '../../articles/magazine-article/styles';

interface BannerProps {
  data: MagazineArticleBannerContent;
}

const MagazineArticleBanner: React.FC<BannerProps> = ({ data }) => {
  return (
    <Container>
      <BannerImageWrapper>
        <BannerImage src={data.image} />
      </BannerImageWrapper>
      <BannerContent>
        <BigHeader>{data.header}</BigHeader>
        <SmallHeader>{data.author}</SmallHeader>
      </BannerContent>
      <Annotation>{data.description}</Annotation>
    </Container>
  );
};

export default MagazineArticleBanner;
