import React, { useEffect } from 'react';

import { useHandleRect, useToggle } from 'utils/hooks';
import { CLOSED_PARAGRAPH_HEIGHT } from 'utils/constants';
import {
  AnimHeight,
  Body,
  ChildrenWrapper,
  Div,
  DivProps,
  Heading,
  Sidebar,
  ToggleButton,
} from 'components/base-ui/paragraph/styles';
import { isEmpty } from 'lodash';

interface ParagraphProps extends DivProps {
  title: string;
  value?: boolean;
  className?: string;
  children: React.ReactNode;
  spoiler?: boolean;
}

const Paragraph: React.FC<ParagraphProps> = ({ title, value, bordered, className, children, spoiler = true }) => {
  const [active, toggleActive] = useToggle(value);
  const [height, handleRect] = useHandleRect<HTMLDivElement>(active, CLOSED_PARAGRAPH_HEIGHT);

  useEffect(() => {
    if (!spoiler) {
      toggleActive();
    }
  }, [spoiler]);

  return (
    <Div bordered={bordered} className={className}>
      <Body>
        <Heading>{title}</Heading>

        <AnimHeight duration={500} height={height} hasTitle={title.length > 0}>
          <ChildrenWrapper ref={handleRect}>{children}</ChildrenWrapper>
        </AnimHeight>
      </Body>

      {spoiler && (
        <Sidebar>
          <ToggleButton name="iconPointer" round active={active} onClick={toggleActive} />
        </Sidebar>
      )}
    </Div>
  );
};

export default Paragraph;
