import React from 'react';
import { Column, Header, Point, PointsWrapper, Wrapper } from './styles';
import { HeaderIcon, PointIcon } from './components';

type Props = {
  data: {
    header: string;
    points: string[];
  }[];
};

export const LeadersListingColumnsBlock: React.FC<Props> = (props) => {
  return (
    <Wrapper>
      {props.data.map((column, index) => (
        <Column key={column.header}>
          <Header>
            <HeaderIcon />
            {column.header}
          </Header>
          <PointsWrapper>
            {column.points.map((point) => (
              <Point key={point}>
                <PointIcon />
                {point}
              </Point>
            ))}
          </PointsWrapper>
        </Column>
      ))}
    </Wrapper>
  );
};
