import React, { createContext, useContext } from 'react';

import CitiesStore from './store';

const store = new CitiesStore();

const CitiesStateContext = createContext<CitiesStore>(store);

export const CitiesStateProvider: React.FC = ({ children }) => (
  <CitiesStateContext.Provider value={store}>{children}</CitiesStateContext.Provider>
);

export const useCitiesStore = (): CitiesStore => useContext(CitiesStateContext);
