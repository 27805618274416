import React from 'react';

import { ServicesReadComponent } from 'components/services';
import ScrollToTopOnMount from 'components/base-ui/scroll-to-top';
import { DefaultLayout } from 'layouts';
import { ProductCommentsStateProvider } from '../../../store/product-comments';

const ServicesReadPage: React.FC = () => {
  return (
    <>
      <ScrollToTopOnMount />

      <ProductCommentsStateProvider>
        <DefaultLayout>
          <ServicesReadComponent />
        </DefaultLayout>
      </ProductCommentsStateProvider>
    </>
  );
};

export default ServicesReadPage;
