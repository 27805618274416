import React, { createContext, useContext } from 'react';

import ProductCommentStore from './store';

const store = new ProductCommentStore();

const ProductStateCommentContext = createContext<ProductCommentStore>(store);

export const ProductCommentsStateProvider: React.FC = ({ children }) => (
  <ProductStateCommentContext.Provider value={store}>{children}</ProductStateCommentContext.Provider>
);

export const useProductCommentsStore = (): ProductCommentStore => useContext(ProductStateCommentContext);
