import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { Textarea } from 'components/base-ui/textarea';
import { Container } from 'components/base-ui/container';

export const ReplyTextArea = styled(Textarea)<{ isSub?: boolean }>`
  height: ${(props) => (props.isSub ? '48px' : '64px')};

  border-radius: 12px;
  border: 1px solid ${COLORS.blueGray};
`;

export const CommentWrapper = styled(Container)`
  flex-direction: column;
  gap: 20px;
  justify-content: center;

  background-color: #f5f9fb;

  padding: 30px 16px;

  .comment-avatar {
    ::before {
      content: none;
    }
    overflow: visible !important;
    margin: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 8px;
    gap: 16px;
    padding: 30px 44px;
    border-radius: 16px;

    img {
      width: 64px;
      height: 64px;
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin: 0px;
    padding: 30px 150px;
  }
`;

export const CommentBlockTitle = styled(Container)`
  color: ${COLORS.azure};
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  gap: 10px;

  align-items: center;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 26px;
    line-height: 32px;
    font-weight: 500;
  }
`;

export const StyledCounter = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 1.25;
  color: ${COLORS.white};
  min-width: 17px;
  max-width: fit-content;

  height: 17px;
  border: 2px solid ${COLORS.azureLight};
  border-radius: 10px;
  background-color: ${COLORS.azureLight};
  padding: 4px;
`;

export const StContainer = styled(Container)`
  gap: 20px;
  position: relative;

  textarea {
    padding: 7px 58px 7px 12px;
    align-self: center;
    font-weight: 300;
  }
`;

export const ButtonWrapper = styled.div<{ isSub?: boolean }>`
  position: absolute;
  bottom: ${({ isSub }) => (isSub ? '5px' : '12px')};

  right: 14px;

  button {
    gap: 0px;
    width: 36px;
    height: 36px;
    padding: 12px 6px;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    bottom: ${({ isSub }) => (isSub ? '14px' : '12px')};
  }
`;
