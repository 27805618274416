import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { Container } from '../../base-ui/container';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  padding: 0px 16px 40px 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0px 52px 40px 52px;
    row-gap: 50px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 32px 160px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 32px;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: row;
  }
`;

export const Header = styled.div`
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  color: ${COLORS.azure};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 28px;
    line-height: 32px;
    font-weight: 400;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 33.33%;
    font-size: 48px;
    line-height: 52px;
    font-weight: 600;
  }
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  color: ${COLORS.blueDark};

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 66.66%;
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 25px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    flex-direction: row;
    column-gap: 40px;
    justify-content: center;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: row;
    column-gap: 90px;
  }
`;

export const Column = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    gap: 20px;
    width: 25%;
  }
`;

export const ColumnHeader = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  color: ${COLORS.blueGray};

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    font-size: 18px;
    line-height: 24px;
    color: ${COLORS.blueDark};
  }
`;

export const ColumnText = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  color: ${COLORS.blueDark};

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
  }
`;
