import React, { useState } from 'react';
import { ReactComponent as Arrow } from 'assets/icons/icon_arrow_white.svg';
import { useLink } from 'utils/api';
import { MainApplicationItem, MainApplicationItemButtonActionEnum } from 'services';
import { BaseModal } from 'components/base-ui/base-modal';
import { Feedback } from 'components/feedback';
import { Content, ItemWrapper, Title, IconWrapper, Icon } from './styles';

type ItemProps = {
  data: MainApplicationItem;
};

export const ApplicationItem: React.FC<ItemProps> = ({ data }) => {
  const [showForm, setShowForm] = useState(false);
  const handleLink = useLink();

  const { ApplicationForm, Link } = MainApplicationItemButtonActionEnum;

  const onClick = () => {
    if (data.buttonAction === ApplicationForm && data.applicationForm) {
      setShowForm(true);
    }
    if (data.buttonAction === Link && data.link) {
      handleLink(data.link);
    }
  };

  return (
    <ItemWrapper>
      <Content onClick={onClick}>
        <IconWrapper>{Boolean(data?.icon) && <Icon src={data.icon!} />}</IconWrapper>
        <Title>{data.text}</Title>
      </Content>
      <IconWrapper>
        <Arrow onClick={onClick} />
      </IconWrapper>
      <BaseModal isVisible={showForm} onClose={() => setShowForm(false)}>
        <Feedback formId={data.applicationForm ?? 0} />
      </BaseModal>
    </ItemWrapper>
  );
};
