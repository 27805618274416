import styled from 'styled-components';
import { BASIC_FONT_FAMILY, BREAKPOINTS, COLORS } from 'utils/constants';
import { TextButton } from 'components/base-ui/button';
import { ReactComponent as GradientSvg } from 'assets/icons/gradient_white.svg';

export const Container = styled.div<{ back?: string }>`
  position: relative;
  font-family: ${BASIC_FONT_FAMILY};
  width: 100%;
  height: 568px;
  background-image: url(${(props) => props.back});
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
  z-index: 1;

  @media screen and (min-width: ${BREAKPOINTS.mobile}) {
    height: 744px;
  }
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 100%;
    height: 840px;
  }
`;
export const Div = styled.div``;
export const Content = styled.div`
  position: absolute;
  left: 16px;
  bottom: 24px;

  background-color: transparent;
  padding: 0;

  @media screen and (min-width: ${BREAKPOINTS.mobile}) {
    width: 94%;
    height: fit-content;
    padding: 20px 15px;
    margin-bottom: 20px;

    position: absolute;
    left: 3%;
    top: 168px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 4;

    background-color: rgba(255, 255, 255, 0.9);
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 698px;
    max-height: 480px;
    padding: 30px 20px;

    position: absolute;
    left: calc((100% - 698px) / 2);
    top: 248px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 4;

    background-color: rgba(255, 255, 255, 0.9);
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin: 204px 0 0 136px;
    padding: 0;
    top: 10px;
    left: 5px;
    background-color: transparent;
  }
`;

export const Header = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: ${COLORS.secondary};
  margin-bottom: 24px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 44px;
    line-height: 48px;
  }
`;

export const Button = styled(TextButton)`
  width: 160px;
  height: 48px;
  color: ${COLORS.white};
  text-transform: uppercase;
  background: ${COLORS.linearGradient};
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: 76px;
  }
`;

export const StGradient = styled(GradientSvg)`
  position: absolute;
  z-index: 2;
  @media screen and (min-width: ${BREAKPOINTS.mobile}) {
    left: -150px;
  }
`;
