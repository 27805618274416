import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { FreeContentBannerListItem, CarouselButtonWrapperProps } from 'web';

import IconButton from 'components/base-ui/icon-button';
import { BREAKPOINTS } from 'utils/constants';
import { useCarousel, useWindowDimensions } from 'utils/hooks';
import { useSwipeable } from 'react-swipeable';
import Card from './card';

const Div = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 20px 16px 62px 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 20px 0 62px 0;
  }
`;

const Grid = styled.div`
  display: grid;
  column-gap: 32px;
  overflow-x: hidden;
  grid-auto-flow: column;
  grid-template-columns: repeat(1, 256px);

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    grid-template-columns: repeat(2, 256px);
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    grid-template-columns: repeat(3, 256px);
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    grid-template-columns: repeat(4, 256px);
  }
`;

const leftStyles = css`
  left: -8px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    left: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    left: 16px;
  }
`;

const rightStyles = css`
  right: -8px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    right: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    right: 16px;
  }
`;

const ButtonWrapper = styled.div<CarouselButtonWrapperProps>`
  top: 168px;
  z-index: 15;
  width: 48px;
  height: 48px;
  position: absolute;

  ${props => (props.direction === 'left' ? leftStyles : rightStyles)}
`;

interface OfferProps {
  items: FreeContentBannerListItem[];
}

const Offer: React.FC<OfferProps> = ({ items }) => {
  const { list, decrement, increment } = useCarousel(items);

  const { width } = useWindowDimensions();

  const numberOfItems = useMemo((): number => {
    if (width < parseInt(BREAKPOINTS.tablet)) return 1;
    if (width < parseInt(BREAKPOINTS.laptop)) return 2;
    if (width < parseInt(BREAKPOINTS.desktop)) return 3;
    return 4;
  }, [width]);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Left') increment();
      if (eventData.dir === 'Right') decrement();
    },
  });

  const itemsToShow = useMemo(() => list.slice(0, numberOfItems), [list, numberOfItems]);

  return (
    <Div>
      <ButtonWrapper direction="left">
        <IconButton name="iconArrowLeft" round shadow gradient onClick={decrement} />
      </ButtonWrapper>

      <Grid {...handlers}>
        {itemsToShow.map(el => (
          <Card key={el.image} data={el} />
        ))}
      </Grid>

      <ButtonWrapper direction="right">
        <IconButton name="iconArrowRight" round shadow gradient onClick={increment} />
      </ButtonWrapper>
    </Div>
  );
};

export default Offer;
