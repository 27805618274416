import React from 'react';
import { Container } from 'components/base-ui/container';

import { Styled } from './styles';
import { TagItem } from './TagItem';
import { useDeviceType } from '../../../utils/hooks';

interface Direction {
  active?: boolean;
  id: number;
  name: string;
}

export function Tags(props: { data: Direction[]; toggleDirections: (id: number, active: boolean) => void }) {
  const deviceType = useDeviceType();

  return (
    <Styled.Container>
      <Container gap={deviceType === 'mobile' ? '12px' : '24px'} wrap="wrap" width="100%">
        {props.data.map((direction) => (
          <TagItem
            key={direction.id}
            text={direction.name}
            active={direction.active}
            onClick={() => props.toggleDirections(direction.id, Boolean(direction.active))}
          />
        ))}
      </Container>
    </Styled.Container>
  );
}
