import React, { useCallback, useMemo } from 'react';
import { Button } from 'shared/components';
import { ActionButtonType } from 'utils/blocks';
import BaseSection from '../../base-ui/section';
import { StButtonWrapper } from './styled';
import { BaseModal } from '../../base-ui/base-modal';
import { Feedback } from '../../feedback';
import { PropsWithSectionOptions } from '../blocks';
import { useLink } from '../../../utils/api';

type Props = PropsWithSectionOptions<{
  data: ActionButtonType;
}>;

export const ButtonBlock: React.FC<Props> = ({ data, options }) => {
  const [showForm, setShowForm] = React.useState(false);

  const goToLink = useLink();

  const buttonText = useMemo(() => {
    return data.text?.length && data.text?.length > 0 ? data.text : 'Заказать';
  }, [data.text]);

  const onLink = useCallback(() => {
    if (!data.link) return;
    goToLink(data.link);
  }, [data.link]);

  return (
    <>
      <BaseSection compact borderless transparent {...options}>
        {data.buttonAction === 'application_form' ? (
          <StButtonWrapper>
            <Button onClick={() => setShowForm(true)} suffix={false}>
              {buttonText}
            </Button>
          </StButtonWrapper>
        ) : (
          <Button onClick={onLink}>{buttonText}</Button>
        )}
      </BaseSection>
      <BaseModal isVisible={showForm} onClose={() => setShowForm(false)}>
        <Feedback formId={data.dynamicApplicationForm || null} />
      </BaseModal>
    </>
  );
};
