import React, { useCallback, useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { NewsComment } from 'services';

import { useNewsCommentsStore } from 'store/news-comments';
import { NewsCommentWithChildrens } from 'store/news-comments/store';
import { head } from 'lodash';
import { Container } from 'components/base-ui/container';
import DEFAULT_SRC from 'assets/icons/icon_default_avatar.svg';
import { useDeviceState } from 'hooks/useDeviceProvider';
import { CommentHeader } from 'components/comment/comment/comment-header/comment-header';
import { CommentBody } from 'components/comment/comment/comment-body/comment-body';
import { CommentFooter } from 'components/comment/comment/comment-footer/comment-footer';
import { AddNewComment } from 'components/comment/add-comment/add-new-comment';
import { StContainer, SubCommentsShowMoreButton, AvatarWrapper } from './styles';
import Avatar from '../../../../base-ui/avatar';

interface ItemProps {
  data: NewsCommentWithChildrens;
  isSub?: boolean;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const NewsCommentsItem: React.FC<ItemProps> = observer(({ data, isSub }) => {
  const newsCommentsStore = useNewsCommentsStore();
  const { isLessTablet } = useDeviceState();

  const [visibleReply, setVisibleReply] = useState(false);
  const [subCommentsCount, setSubCommentsCount] = useState(10);

  useEffect(() => {
    if (data.childrenComments) {
      newsCommentsStore.addSubComments(data.childrenComments);
    }
  }, []);
  const addSubComment = (comment: string) => {
    const newComment: NewsComment = {
      content: comment,
      parentComment: isSub ? data.parentComment : data.id,
      news: data.news,
    };
    newsCommentsStore.addComment(newComment);
    setVisibleReply(false);
  };

  const fetchNextSubComments = useCallback(() => {
    if (data.id) {
      setSubCommentsCount(subCommentsCount + 1);
      newsCommentsStore.fetchSubComments({
        newsId: +data.news,
        parentId: data.id,
        count: subCommentsCount,
      });
    }
    setSubCommentsCount(subCommentsCount + 5);
  }, [subCommentsCount]);

  const isShowMoreReplyButton =
    Number(data?.childrenCommentsCount) > 5 &&
    data.id &&
    Number(head(newsCommentsStore.comments.filter((comment) => comment.id === data.id))?.childrens?.length) <
      Number(data?.childrenCommentsCount);

  const handleReplyClick = useCallback(() => {
    setVisibleReply(!visibleReply);
  }, [visibleReply]);

  const renderChildrens = (childrens: NewsComment[]) => {
    return childrens.map((c) => <NewsCommentsItem data={c} isSub={true} />);
  };

  const onLike = useCallback(() => {
    if (data.id) {
      data.isLikedByUser
        ? newsCommentsStore.toggleLikeDelete(data.id, data.parentComment ? data.parentComment : undefined)
        : newsCommentsStore.toggleLikePost(data.id, data.parentComment ? data.parentComment : undefined);
    }
  }, [data.isLikedByUser, data.id, data.parentComment]);

  return (
    <>
      <StContainer isSub={isSub} hasChildren={Boolean(data.childrenCommentsCount)}>
        {!isLessTablet && (
          <AvatarWrapper>
            <Avatar
              src={data.profile?.image ?? DEFAULT_SRC}
              className="comment-avatar"
              size={isLessTablet ? 'smallest' : 'small'}
            />
          </AvatarWrapper>
        )}
        <Container direction="column">
          {isLessTablet ? (
            <Container gap="10px">
              <AvatarWrapper>
                <Avatar
                  src={data.profile?.image ?? DEFAULT_SRC}
                  className="comment-avatar"
                  size={isLessTablet ? 'smallest' : 'small'}
                />
              </AvatarWrapper>
              <CommentHeader profile={data.profile} createDate={data.created} />
            </Container>
          ) : (
            <CommentHeader profile={data.profile} createDate={data.created} />
          )}

          <CommentBody content={data.content} />

          <CommentFooter
            visible={visibleReply}
            onReply={handleReplyClick}
            likesCount={data.likesCount}
            commentId={data.id}
            parentCommentId={data.parentComment}
            isLikedByUser={data.isLikedByUser}
            isSub={isSub}
            onLike={onLike}
          />
        </Container>
      </StContainer>

      {data.id &&
        renderChildrens(head(newsCommentsStore.comments.filter((comment) => comment.id === data.id))?.childrens || [])}

      {isShowMoreReplyButton && (
        <SubCommentsShowMoreButton onClick={fetchNextSubComments}>ПОКАЗАТЬ БОЛЬШЕ ОТВЕТОВ</SubCommentsShowMoreButton>
      )}

      {visibleReply && (
        <AddNewComment
          onAddNewComment={addSubComment}
          isSub={true}
          toName={data.profile?.firstName ?? ''}
          idNewComment={`comment-textarea-${data.id}`}
        />
      )}
    </>
  );
});
export default NewsCommentsItem;
