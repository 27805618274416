import React, { useCallback, useEffect, useMemo } from 'react';
import { useNewsStore } from 'store';
import { observer } from 'mobx-react-lite';
import { Button } from 'shared/components';
import { useHistory } from 'react-router-dom';
import { Container, Title, NewsWrapper, ButtonWrapper } from './styles';
import { NewsCard } from '../news-card';

type NewsProps = {
  withApps: boolean;
};

const CARD_LIMIT = 4;

export const News: React.FC<NewsProps> = observer(({ withApps }) => {
  const store = useNewsStore();
  const history = useHistory();

  useEffect(() => {
    store.fetchNews({ isShowOnMainPage: true, limit: CARD_LIMIT });
    return store.cleanUp;
  }, []);

  const onClick = useCallback(() => {
    history.push('/news');
  }, []);

  return (
    <Container withApps={withApps}>
      <Title>Новости</Title>
      <NewsWrapper>
        {store.newsForPreview.map((n) => (
          <NewsCard key={n.id} data={n} />
        ))}
      </NewsWrapper>
      <ButtonWrapper>
        <Button onClick={onClick}>Все новости</Button>
      </ButtonWrapper>
    </Container>
  );
});
