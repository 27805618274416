import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HtmlRenderer } from 'utils/htmlParser';
import { useProjectsStore } from 'store';
import { useHistory } from 'react-router-dom';
import { useDeviceType } from 'utils/hooks';
import { ProjectCards } from 'services';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  Div,
  Item,
  ItemBlur,
  Main,
  MailMobile,
  Description,
  Step,
  StepVrTxt,
  Title,
  Number,
  Container,
} from './styles';

const ProjectsAccordion: React.FC = () => {
  const store = useProjectsStore();
  const deviceType = useDeviceType();
  const history = useHistory();
  const { trackPageView } = useMatomo();
  const [cards, setCards] = useState<ProjectCards[]>([]);
  const [isInitial, setIsInitial] = useState(true);
  const isMobile = useMemo(() => deviceType === 'mobile', [deviceType]);

  useEffect(() => {
    store.fetchProjectsData().then(() => {
      if (store.projectsData) {
        setCards(store.projectsData.reverse());
      }
      document.title = 'Проекты';
      trackPageView({});
    });

    const timer = setTimeout(() => {
      setIsInitial(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleOnProjectClick = useCallback(
    (link: string) => {
      history.push(link);
    },
    [history],
  );

  const accordionItems = useMemo(() => {
    return cards.map((item, index) => {
      return (
        <Item
          key={item.header}
          src={item.image || ''}
          cardsCount={cards.length}
          className={isInitial ? 'initial' : ''}
          onClick={() => handleOnProjectClick(item.link)}
        >
          <ItemBlur color={item.color} />
          <Main>
            <Number>0{cards.length - index}</Number>
            <Title>{item.header}</Title>
            <Description>
              <HtmlRenderer html={item.description || ''} />
            </Description>
          </Main>
          <Step>
            <StepVrTxt>{item.header}</StepVrTxt>0{cards.length - index}
          </Step>
        </Item>
      );
    });
  }, [cards, isInitial]);

  const mobileAccordionItems = useMemo(() => {
    return cards.map((item, index) => {
      return (
        <Div>
          <Item key={item.header} src={item.image || ''} onClick={() => handleOnProjectClick(item.link)}>
            <Step>
              <StepVrTxt>{item.header}</StepVrTxt>0{cards.length - index}
            </Step>
          </Item>
          <MailMobile color={item.color}>
            <Number>0{cards.length - index}</Number>
            <Title>{item.header}</Title>
            <Description>
              <HtmlRenderer html={item.description || ''} />
            </Description>
          </MailMobile>
        </Div>
      );
    });
  }, [cards]);

  return <Container>{isMobile ? mobileAccordionItems : accordionItems}</Container>;
};

export default ProjectsAccordion;
