import React from 'react';

import { ImageWithText } from 'web';
import { PropsWithSectionOptions } from '../blocks';

import Section from '../../base-ui/section';
import { HtmlRenderer } from '../../../utils/htmlParser';
import { StContainer, StImage, StTextWrapper } from './styles';

type ImageWithTextBlockProps = PropsWithSectionOptions<{
  value: ImageWithText;
}>;

const ImageWithTextBlock: React.FC<ImageWithTextBlockProps> = ({ value, options }) => {
  return (
    <Section noFirstOfTypeOffset noLastOfTypeMargin noMinHeight {...options}>
      <StContainer>
        <StImage src={value.image} />
        <StTextWrapper>
          <HtmlRenderer html={value.text} />
        </StTextWrapper>
      </StContainer>
    </Section>
  );
};

export default ImageWithTextBlock;
