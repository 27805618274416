import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const StActions = styled.div<{showActions: boolean}>`
  display: ${props => (props.showActions ? 'flex' : 'none')};
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    justify-content: flex-start;
  }
`;
