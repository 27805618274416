import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

const Container = styled.div<{ center?: boolean }>`
  display: none;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: flex;
    justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
    width: 100%;
    height: 616px;
    margin: 40px 0;
  }
`;

const Image = styled.img<{ center?: boolean }>`
  height: 616px;
  object-fit: cover;
  width: ${(props) => (props.center ? '698px' : '100%')};
`;

export const ArticleBigImage: React.FC<{ image: string; fill?: string }> = ({ image, fill }) => {
  return (
    <Container center={fill === 'center'}>
      <Image src={image} alt="центральное изображение" center={fill === 'center'} />
    </Container>
  );
};
