import styled from 'styled-components';
import { Container } from '../../../base-ui/container';
import { BREAKPOINTS } from '../../../../utils/constants';

export const CardsContainer = styled(Container)`
  gap: 32px;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    justify-content: flex-start;
  }
`;

export const ParticipantsWrapper = styled(Container)`
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    gap: 24px;
  }
`;
