import styled from 'styled-components';

export const StContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StImage = styled.img`
  width: 100%;
  border-radius: 16px;
`;

export const StTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  line-height: 26px;
  font-weight: 200;
  font-style: italic;
  padding: 8px 0 16px 0;
`;
