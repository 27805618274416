import React, { useCallback, useEffect, useState } from 'react';
import { head, last } from 'lodash';
import { HtmlRenderer } from 'utils/htmlParser';
import { useDeviceType } from 'utils/hooks';
import { useLeaderCodeStore } from 'store/leader-code';
import { observer } from 'mobx-react-lite';
import { useLink } from 'utils/api';
import Icon from 'components/base-ui/icon';
import { Wrapper, Footer, Description, Columns, ColumnItem, Column, ColumnHeader, Body, StButton } from './styles';
import { Carousel, AccordionColumns, IconPoint } from './components';

type Data = {
  year: number;
  button: {
    link: string;
    text: string;
  };
  description: string;
  listingColumns: { header: string; points: string[] }[];
};

type Props = {
  data: Data[];
};

export const LeadersYearsBlock: React.FC<Props> = observer(({ data }) => {
  const store = useLeaderCodeStore();
  const goToLink = useLink();
  const device = useDeviceType();
  const [currentData, setCurrentData] = useState<Data | undefined>(last(data));

  useEffect(() => {
    store.setYear(currentData?.year);
  }, []);

  const onClick = useCallback(
    (newYear: number) => {
      store.setYear(newYear);
      setCurrentData(head(data.filter((y) => y.year === newYear)));
    },
    [data],
  );

  const onLink = useCallback(() => {
    if (!currentData?.button.link) return;
    goToLink(currentData.button.link);
  }, [currentData?.button.link]);

  return (
    <Wrapper>
      <Carousel years={data.map((item) => item.year)} active={currentData?.year} onClick={onClick} />

      <Body>
        <Description>
          <HtmlRenderer html={currentData?.description ?? ''} />
        </Description>
        {device === 'mobile' && <AccordionColumns data={currentData?.listingColumns} />}
        {device !== 'mobile' && (
          <Columns>
            {currentData?.listingColumns?.map((column) => (
              <Column key={column.header}>
                <ColumnHeader>{column.header}</ColumnHeader>
                {column.points.map((point) => (
                  <ColumnItem key={point}>
                    <IconPoint />
                    {point}
                  </ColumnItem>
                ))}
              </Column>
            ))}
          </Columns>
        )}
      </Body>

      <Footer>
        {currentData?.button.link && currentData.button.text && (
          <StButton onClick={onLink}>
            {currentData.button.text}
            <div style={{ width: '24px', height: '24px' }}>
              <Icon name="iconArrowWhite" />
            </div>
          </StButton>
        )}
      </Footer>
    </Wrapper>
  );
});
