import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 50px;
  padding: 70px 16px 50px 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    align-items: flex-start;
    padding: 50px 52px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 150px;
    padding: 50px 160px;
  }
`;

export const Header = styled.div`
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  color: ${COLORS.azure};
  align-self: flex-start;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 28px;
    line-height: 32px;
    font-weight: 400;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    font-size: 48px;
    line-height: 52px;
    width: 380px;
    font-weight: 600;
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 60px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 150px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: unset;
    justify-content: flex-start;
    column-gap: 150px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

export const Number = styled.div<{ color: string; fontColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 99px;
  height: 99px;
  border-radius: 50px;
  background-color: ${(props) => props.color};
  font-size: 36px;
  line-height: 44px;
  font-weight: 400;
  color: ${({ fontColor }) => fontColor ?? COLORS.white}; //${COLORS.white};
`;

export const Text = styled.div`
  font-size: 24px;
  line-height: 30px;
  font-weight: 300;
  color: ${COLORS.blueDark};
`;
