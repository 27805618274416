import React from 'react';

import { HtmlRenderer } from '../../../utils/htmlParser';
import { Container, Content } from '../../articles/magazine-article/styles';

type TextRendererProps = {
  value: string;
};

const MagazineArticleTextRenderer: React.FC<TextRendererProps> = ({ value }) => {
  return (
    <Container>
      <Content>
        <HtmlRenderer html={value} />
      </Content>
    </Container>
  );
};

export default MagazineArticleTextRenderer;
