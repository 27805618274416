import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { parseHtml } from 'utils/htmlParser';

const Container = styled.div`
  width: 288px;
  font-size: 18px;
  line-height: 28px;
  color: ${COLORS.gray2};
  h3 {
    font-size: 20px;
    line-height: 26px;
    color: ${COLORS.secondary};
  }

  img {
    width: 100%;
  }
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 698px;
  }
`;

export const ArticleText: React.FC<{ text: string }> = ({ text }) => {
  return <Container>{parseHtml(text)}</Container>;
};
