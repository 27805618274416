import styled from 'styled-components';
import { H1 as StyledH1 } from 'components/base-ui/typo';
import { COLORS, BASIC_FONT_FAMILY, BREAKPOINTS } from 'utils/constants';

export const State = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.mobile}) {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 17px;
  }
`;

export const StateSection = styled.div``;
export const StateTitle = styled(StyledH1)`
  color: ${COLORS.gray2};
  &:after {
    content: '';
    display: block;
    margin-top: 8px;
    width: 101px;
    height: 8px;
    background-color: ${COLORS.red};
  }
`;
export const StateInfo = styled.div`
  color: ${COLORS.gray2};
  max-width: 210px;
  @media screen and (min-width: ${BREAKPOINTS.mobile}) {
    margin-top: 8px;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-top: 19px;
  }
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.gray3};
  margin-top: 40px;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-top: 64px;
  }
`;
