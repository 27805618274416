import React, { useCallback, useState } from 'react';
import { Button } from 'shared/components';
import { ButtonTypes } from 'shared/components/button/types';
import { COLORS } from 'utils/constants';
import { useProfileStore } from 'store';
import { api } from 'utils/api';
import { useDeviceType } from 'utils/hooks';
import { Container, Description, Title, Text, Inputs, StInput } from './styled';

type ModalProps = {
  onClose: () => void;
  afterClose?: () => void;
};

export const ModalContent: React.FC<ModalProps> = ({ onClose, afterClose }) => {
  const store = useProfileStore();
  const device = useDeviceType();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const onSubmit = useCallback(async () => {
    if (!store.profile?.id) return;

    await api.profile.profilePartialUpdate({ id: store.profile.id, data: { firstName, lastName } });
    store.updateUser();
    onClose();
    if (afterClose) {
      afterClose();
    }
  }, [store.profile?.id, firstName, lastName, onClose]);

  return (
    <Container>
      <Description>
        <Title>Чтобы отправить комментарий введите ваше имя и фамилию</Title>
        <Text>Информация сохранится в личном кабинете</Text>
      </Description>

      <Inputs>
        <StInput
          value={firstName}
          onChange={(v) => setFirstName(v.currentTarget.value)}
          color={COLORS.white}
          id="comment-firstname"
          label="Имя"
          placeholder="Введите имя"
        />
        <StInput
          value={lastName}
          onChange={(v) => setLastName(v.currentTarget.value)}
          color={COLORS.white}
          id="comment-lastname"
          label="Фамилия"
          placeholder="Введите фамилию"
        />
      </Inputs>

      <Button
        suffix={device !== 'mobile'}
        width={device === 'mobile' ? '260px' : '310px'}
        type={ButtonTypes.Inverse}
        onClick={onSubmit}
      >
        Отправить комментарий
      </Button>
    </Container>
  );
};
