import React, { useCallback, useState } from 'react';
import Avatar from 'components/base-ui/avatar';
import DEFAULT_SRC from 'assets/icons/icon_default_avatar.svg';
import { useProfileStore } from 'store/profile';
import { BaseModal } from 'components/base-ui/base-modal';
import { ModalContent } from 'components/comment/modal-content';

import { useDeviceState } from 'hooks/useDeviceProvider';
import { Button } from 'shared/components';
import { ButtonTypes } from 'shared/components/button/types';
import { ReplyTextArea, CommentWrapper, CommentBlockTitle, StyledCounter, StContainer, ButtonWrapper } from './styles';

interface Props {
  isSub?: boolean;
  toName?: string;
  onAddNewComment: (comment: string) => void;
  count?: number;
  idNewComment: string;
}

export const AddNewComment: React.FC<Props> = (props) => {
  const { profile } = useProfileStore();

  const [showModal, setShowModal] = useState(false);
  const [newComment, setNewComment] = useState('');

  const textarea = document.getElementById(props.idNewComment);

  if (textarea) {
    textarea.addEventListener('input', () => {
      textarea.style.height = '';
      textarea.style.height = `${textarea.scrollHeight + 2}px`;
    });
  }

  React.useEffect(() => {
    if (props.toName) {
      setNewComment(`${props.toName}, `);
    }
  }, []);

  const { isLessTablet } = useDeviceState();

  const addComment = useCallback(() => {
    props.onAddNewComment(newComment);
    setNewComment('');
  }, [newComment]);

  const onClick = useCallback(() => {
    if (profile?.firstName && profile?.lastName) {
      addComment();
    } else {
      setShowModal(true);
    }
  }, [addComment, profile?.firstName, profile?.lastName]);

  const onChangeComment = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewComment(e.currentTarget.value);
  }, []);

  return (
    <>
      <BaseModal isVisible={showModal} onClose={() => setShowModal(false)}>
        <ModalContent onClose={() => setShowModal(false)} afterClose={addComment} />
      </BaseModal>

      <CommentWrapper>
        {!props.isSub && (
          <CommentBlockTitle>
            {isLessTablet && <Avatar src={profile?.image || DEFAULT_SRC} size="smallest" className="comment-avatar" />}
            Комментарии
            <StyledCounter children={props.count ?? 0} />
          </CommentBlockTitle>
        )}

        <StContainer>
          {!isLessTablet && <Avatar src={profile?.image || DEFAULT_SRC} size="small" className="comment-avatar" />}

          <ReplyTextArea
            isSub={props.isSub}
            placeholder={props.isSub ? 'Напишите ответ...' : 'Введите комментарий'}
            value={newComment}
            onChange={onChangeComment}
            id={props.idNewComment}
          />
          {newComment.length > 0 && (
            <ButtonWrapper isSub={props.isSub}>
              <Button type={ButtonTypes.Primary} onClick={onClick} />
            </ButtonWrapper>
          )}
        </StContainer>
      </CommentWrapper>
    </>
  );
};
