import { ReactComponent as ArrowRight } from 'assets/icons/icon_arrow_grey.svg';
import { ReactComponent as Up } from 'assets/icons/icon_up.svg';
import { ReactComponent as Tag } from 'assets/icons/icon_tag.svg';
import { ReactComponent as Down } from 'assets/icons/icon_down.svg';
import { ReactComponent as Doc } from 'assets/icons/icon_doc.svg';
import { ReactComponent as Place } from 'assets/icons/icon_place.svg';
import { ReactComponent as CrossBase } from 'assets/icons/icon_cross_base.svg';
import { ReactComponent as CrossBaseSmall } from 'assets/icons/icon_cross_small.svg';
import { ReactComponent as CrossDownSmall } from 'assets/icons/icon_down_small.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/icon_arrow_left.svg';
import { ReactComponent as CheckSmall } from 'assets/icons/icon_check_small.svg';
import { ReactComponent as PointerLeftSmall } from 'assets/icons/icon_pointer_left_small.svg';
import { ReactComponent as Play } from './play.svg';
import { ReactComponent as Pause } from './pause.svg';
import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as Upsize } from './upsize.svg';
import { ReactComponent as Downsize } from './downsize.svg';
import { ReactComponent as Mute } from './mute.svg';
import { ReactComponent as Volume } from './volume.svg';
import { ReactComponent as Close } from './icon_cross_base_white.svg';
import { ReactComponent as EllipseMark } from './ellipse_mark.svg';
import { ReactComponent as QuoteStart } from './quote_start.svg';
import { ReactComponent as QuoteEnd } from './quote_end.svg';
import { ReactComponent as Thumb } from './icon_thumb_2.svg';

export const IconSvg = {
  ArrowRight,
  Up,
  Down,
  Tag,
  Doc,
  Place,
  CrossBase,
  CrossBaseSmall,
  CrossDownSmall,
  ArrowLeft,
  CheckSmall,
  PointerLeftSmall,
  Play,
  Pause,
  Settings,
  Upsize,
  Downsize,
  Mute,
  Volume,
  Close,
  EllipseMark,
  QuoteStart,
  QuoteEnd,
  Thumb,
};
