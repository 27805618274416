import React, { useCallback, useEffect } from 'react';
import { Block } from 'utils/blocks';
import Icon from 'components/base-ui/icon';
import { observer } from 'mobx-react-lite';
import { useProfileStore, useProjectsStore } from 'store';
import { useHistory } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { isNumber } from 'lodash';

import {
  Container,
  Count,
  Div,
  FavoriteIcon,
  Footer,
  IconsWrapper,
  LikeWrapper,
  PdfIcon,
  PrintIcon,
  ViewIcon,
} from './styles';
import { ArticleProps } from './types';
import { IconLikeWithTooltip } from '../../base-ui/icon-like-with-tooltip';
import { useDeviceType } from '../../../utils/hooks';
import { LikeModalContent } from '../../base-ui/like-modal-content';
import { LikeModal } from '../../base-ui/like-modal';
import { IconFavorite } from '../../base-ui/icon-favorite';
import { BlocksRender } from '../../renderers';
import { Button } from '../../../shared/components';
import { ButtonTypes } from '../../../shared/components/button/types';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const MagazineArticle: React.FC<ArticleProps> = observer((props) => {
  const store = useProjectsStore();
  const profileStore = useProfileStore();
  const history = useHistory();
  const [showLikeModal, setShowLikeModal] = React.useState(false);
  const device = useDeviceType();

  const { trackPageView } = useMatomo();

  const likesLimit = React.useMemo(() => {
    if (device === 'mobile') return 70;
    return 40;
  }, [device]);

  useEffect(() => {
    store.fetchContentMagazinesArticles({ id: props.articleId });
    document.title = 'Статья журнала';
    trackPageView({});
  }, [store, props.articleId]);

  const onPrint = () => window.print();

  const onLikeClick = useCallback(() => {
    if (store.magazineArticle?.id) {
      if (store.magazineArticle.isLikedByUser) {
        store.handleLikeDelete(store.magazineArticle.id, profileStore.profile?.id!);
      } else {
        store.handleLikePost(store.magazineArticle.id, profileStore.profile);
      }
    }
  }, [store.magazineArticle?.id, store.magazineArticle?.isLikedByUser]);

  const onFavoriteClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (store.magazineArticle?.id) {
        if (store.magazineArticle.isInUserFavorites) {
          store.handleFavoriteDelete(store.magazineArticle.id);
        } else {
          store.handleFavoritePost(store.magazineArticle.id);
        }
      }
    },
    [store.magazineArticle?.id, store.magazineArticle?.isInUserFavorites],
  );

  const onBackClick = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.stopPropagation();
      history.push(`/projects/magazines/${store.magazineArticle?.magazine.id || 0}`);
    },
    [store.magazineArticle?.id],
  );

  const onDownloadPdf = useCallback(() => {
    if (!store.magazineArticle?.pdf) return;

    window.open(store.magazineArticle.pdf);
  }, [store.magazineArticle?.magazine.pdfFile]);

  const tooltipOffset = React.useMemo(() => {
    if (store.magazineArticle?.likesCount && store.magazineArticle.likesCount > 6) {
      return {
        left: '-140px',
        leftMobile: '0',
        arrowLeft: '235px',
        arrowLeftMobile: '130px',
      };
    }

    if (store.magazineArticle?.likesCount && store.magazineArticle.likesCount > 4) {
      return {
        left: '-110px',
        leftMobile: '0',
        arrowLeft: '200px',
        arrowLeftMobile: '120px',
      };
    }

    return {
      left: '-20px',
      leftMobile: '0',
      arrowLeft: '110px',
      arrowLeftMobile: '110px',
    };
  }, [store.magazineArticle?.likesCount]);

  return (
    <>
      <BlocksRender
        blocks={store?.magazineArticle?.content as unknown as Block[]}
        loading={store?.fetching}
        options={{ section: { compact: true } }}
      />
      <Container>
        <Footer>
          <Button type={ButtonTypes.Secondary} onClick={(e) => onBackClick(e as React.MouseEvent<HTMLButtonElement>)}>
            Все статьи
          </Button>
          <IconsWrapper>
            <Div>
              <ViewIcon>
                <Icon name="iconViewsGrey" />
              </ViewIcon>
              <Count>{store.magazineArticle?.viewsCount || 0}</Count>
            </Div>
            <LikeWrapper>
              {isNumber(store.magazineArticle?.likesCount) && (
                <IconLikeWithTooltip
                  isLiked={!!store.magazineArticle?.isLikedByUser}
                  onClick={onLikeClick}
                  likedUsers={store.magazineArticle?.likeAuthors || []}
                  likesCount={store.magazineArticle?.likesCount || 0}
                  newsId={store.magazineArticle?.id!}
                  onShowModal={() => setShowLikeModal(true)}
                  left={tooltipOffset.left}
                  leftMobile={tooltipOffset.leftMobile}
                  arrowLeft={tooltipOffset.arrowLeft}
                  arrowLeftMobile={tooltipOffset.arrowLeftMobile}
                />
              )}
            </LikeWrapper>

            <FavoriteIcon onClick={onFavoriteClick}>
              <IconFavorite active={!!store.magazineArticle?.isInUserFavorites} />
              <span>{store.magazineArticle?.isInUserFavorites ? 'Удалить из избранного' : 'Добавить в избранное'}</span>
            </FavoriteIcon>

            <PrintIcon isNoLasElement={Boolean(store.magazineArticle?.pdf)} onClick={onPrint}>
              <Icon name="iconPrintSolid" />
              {device !== 'mobile' && device !== 'tablet' && <span>Распечатать</span>}
            </PrintIcon>
            {store.magazineArticle?.pdf && (
              <Div>
                <PdfIcon onClick={onDownloadPdf}>
                  <Icon name="iconPdfGray" />
                  {device !== 'mobile' && device !== 'tablet' && <span>Скачать</span>}
                </PdfIcon>
              </Div>
            )}
          </IconsWrapper>
        </Footer>

        <LikeModal visible={showLikeModal} onClose={() => setShowLikeModal(false)}>
          <LikeModalContent cardType="article" id={props.articleId} limitPerPage={likesLimit} />
        </LikeModal>
      </Container>
    </>
  );
});
