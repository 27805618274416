import React, { useRef } from 'react';
import { Slide, SlideshowRef } from 'react-slideshow-image';
import Section from 'components/base-ui/section';
import { useDeviceType } from 'utils/hooks';
import { SliderProps } from './types';
import { Item } from './item';
import 'react-slideshow-image/dist/styles.css';
import './styles.css';

export const BigSlider: React.FC<SliderProps> = ({ items, interval }) => {
  const slideRef = useRef<SlideshowRef>(null);
  const device = useDeviceType();

  const indicators = (index = 0) => <div className="indicator" />;

  const goNext = () => {
    if (device === 'mobile' || device === 'tablet') return;
    slideRef.current?.goNext();
  };

  const goPrev = () => {
    if (device === 'mobile' || device === 'tablet') return;
    slideRef.current?.goBack();
  };

  return (
    <Section noFirstOfTypeOffset compact>
      <Slide
        arrows={false}
        canSwipe={device === 'mobile' || device === 'tablet'}
        ref={slideRef}
        duration={interval ? interval * 1000 : 5000}
        indicators={indicators}
        easing="cubic-out"
        transitionDuration={500}
      >
        {items.map((item, index) => (
          <div className="each-slide-effect">
            <Item key={index} {...item} goNext={goNext} goPrev={goPrev} />
          </div>
        ))}
      </Slide>
    </Section>
  );
};
