import styled from 'styled-components';
import { Small } from 'components/base-ui/typo';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const StSmall = styled(Small)`
  word-break: break-word !important;
  padding: 10px 16px 0 16px;
  color: ${COLORS.gray2};

  font-size: 16px;
  line-height: 24px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 10px 0 0 0;
    font-size: 18px;
    line-height: 26px;
  }
`;

export const ShowMore = styled.span`
  color: ${COLORS.primary};
  cursor: pointer;
`;
