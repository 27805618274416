import React from 'react';
import { Container, QuoteContent, QuoteWrapper, WaterMark } from '../../articles/magazine-article/styles';

type TextRendererProps = {
  value: string;
};

const MagazineArticleBigHeaderRender: React.FC<TextRendererProps> = ({ value }) => {
  return (
    <Container>
      <QuoteWrapper>
        <WaterMark>“</WaterMark>
        <QuoteContent>{value}</QuoteContent>
      </QuoteWrapper>
    </Container>
  );
};

export default MagazineArticleBigHeaderRender;
