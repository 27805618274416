import React from 'react';
import { Container } from 'components/base-ui/container';
import { CardContainer, Name, Description, StImage, StImageWrapper } from './styles';

type Props = {
  name: string;
  company?: string;
  position?: string | null;
  image?: string;
};

export const Card: React.FC<Props> = (props) => {
  return (
    <CardContainer>
      <StImageWrapper>
        <StImage src={props.image} />
      </StImageWrapper>
      <Container gap="2px" direction="column">
        <Name>{props.name}</Name>
        <Description>{props.position}</Description>
        <Description>{props.company}</Description>
      </Container>
    </CardContainer>
  );
};
