import React from 'react';
import { Redirect } from 'react-router';

import { AppRouterConfig } from 'web';
import App from 'App';
import SearchPage from 'pages/search';
import { BasePage } from 'pages/base-page';
import { TravelLineForm } from 'pages/travel-line-form';
import {
  AboutPage,
  ArticlesPage,
  AssolAwardPage,
  AssolWinnersPage,
  BlogReadPage,
  CartPage,
  GoodsListPage,
  GoodsReadPage,
  GuidePage,
  LoginPage,
  MagazineArticlePage,
  MagazinesPage,
  MainPage,
  MarketPage,
  NewsItemPage,
  NewsListPage,
  NotFoundPage,
  ProfileFavoritePage,
  ProfileHomePage,
  ProfileNotificationsPage,
  ProfileOrdersPage,
  ProfilePage,
  ProjectsPage,
  ScarletPage,
  ServicesPage,
  ServicesReadPage,
  AboutUsPage,
  CreatorsPage,
  LeaderCodePage,
} from 'pages';
import { MedicinePage } from 'pages/medicine';
import { MedicineCenterPage } from 'pages/medicine/medicine-center';
import { ProgramPage } from 'pages/medicine/program';
import OurPeopleArticlePage from '../pages/articles/our-people-article';

const NotFound = '/404';

const routes: AppRouterConfig[] = [
  {
    component: App,
    routes: [
      {
        path: '/',
        exact: true,
        component: MainPage,
        pageContentType: ['mainpage'],
        getLink: (_) => '/',
      },
      {
        path: '/market',
        exact: true,
        component: MarketPage,
        pageContentType: ['globalcategorypage'],
        getLink: (_) => '/market',
      },
      {
        path: '/market/medicine',
        exact: true,
        component: MedicinePage,
        pageContentType: ['globalcategorypage'],
      },
      {
        path: '/market/medicine/:medCenterId',
        exact: true,
        component: MedicineCenterPage,
      },
      {
        path: '/market/medicine/:medCenterId/:medProgramId',
        exact: true,
        component: ProgramPage,
      },
      {
        path: '/projects',
        exact: true,
        component: ProjectsPage,
      },
      {
        path: '/projects/magazines',
        exact: true,
        component: MagazinesPage,
      },
      {
        path: '/projects/magazines/:magazineId',
        exact: true,
        component: ArticlesPage,
      },
      {
        path: '/projects/magazines/:magazineId/:articleId',
        exact: true,
        component: MagazineArticlePage,
      },
      {
        path: '/magazine-article/:articleId',
        exact: true,
        component: MagazineArticlePage,
      },
      {
        path: '/our-people-article/:articleId',
        exact: true,
        component: OurPeopleArticlePage,
      },
      {
        path: '/projects/creators',
        exact: true,
        component: CreatorsPage,
      },
      {
        path: '/projects/scarlet',
        exact: true,
        component: ScarletPage,
      },
      {
        path: '/projects/award',
        exact: true,
        component: AssolAwardPage,
      },
      {
        path: '/projects/award/:awardId',
        exact: true,
        component: AssolWinnersPage,
      },
      {
        path: '/projects/award/:awardId/:nominationId',
        exact: true,
        component: AssolWinnersPage,
      },
      {
        path: '/goods/:categoryType/:categoryId',
        exact: true,
        component: GoodsListPage,
      },
      {
        path: '/goods/:categoryType/:categoryId/:productId',
        exact: true,
        component: GoodsReadPage,
      },
      {
        path: '/services/:categoryType/:categoryId',
        exact: true,
        component: ServicesPage,
        pageContentType: ['categorypage', 'mainguidepage', 'mediapage'],
        getLink: (_, additionalInfo) => {
          if (additionalInfo) {
            return `/services/${additionalInfo.app_label}/${additionalInfo.instance_pk}`;
          }
          return NotFound;
        },
      },
      {
        path: '/services/:categoryType/:categoryId/:productId',
        component: ServicesReadPage,
        pageContentType: ['productpage'],
        getLink: (_, additionalInfo) => {
          if (additionalInfo) {
            return `/services/${additionalInfo.app_label}/${additionalInfo.category_pk}/${additionalInfo.instance_pk}`;
          }
          return NotFound;
        },
      },
      {
        path: '/login',
        component: LoginPage,
      },
      {
        path: '/ldap-auth',
        component: LoginPage,
      },
      {
        path: '/cart',
        component: CartPage,
      },
      {
        path: '/profile',
        component: ProfilePage,
        routes: [
          {
            path: '/profile',
            exact: true,
            component: ProfileHomePage,
          },
          {
            path: '/profile/orders',
            exact: true,
            component: ProfileOrdersPage,
          },
          {
            path: '/profile/favorite',
            exact: true,
            component: ProfileFavoritePage,
          },
          {
            path: '/profile/notifications',
            exact: true,
            component: ProfileNotificationsPage,
          },
          {
            path: '/profile/*',
            component: () => <Redirect to="/profile" />,
          },
        ],
      },
      {
        path: '/news',
        exact: true,
        component: NewsListPage,
        pageContentType: ['mainnewspage'],
        getLink: (_, __) => {
          return '/news';
        },
      },
      {
        path: '/live-booking',
        exact: true,
        component: TravelLineForm,
      },
      {
        path: '/leader-code',
        exact: true,
        component: LeaderCodePage,
        pageContentType: ['aboutuspage'],
        getLink: (_, __) => {
          return '/leader-code';
        },
      },
      {
        path: '/booking',
        exact: true,
        component: () => <TravelLineForm isMedicine />,
      },
      {
        path: '/news/:newsId',
        exact: true,
        component: NewsItemPage,
        pageContentType: ['newspage'],
        getLink: (pageId, _) => {
          return `/news/${pageId}`;
        },
      },
      {
        path: '/guide',
        component: GuidePage,
        exact: true,
        routes: [],
      },
      {
        path: '/guide/:guidePageId',
        exact: true,
        component: BlogReadPage,
        pageContentType: ['guidepage'],
        getLink: (pageId, _) => {
          return `/guide/${pageId}`;
        },
      },
      {
        path: '/about',
        exact: true,
        component: AboutPage,
        pageContentType: ['aboutprojectpage'],
        getLink: (_, __) => {
          return '/about';
        },
      },
      {
        path: '/about-us',
        exact: true,
        component: AboutUsPage,
        pageContentType: ['aboutuspage'],
        getLink: (_, __) => {
          return '/about-us';
        },
      },
      {
        path: '/page/:basePageId',
        exact: true,
        component: BasePage,
        pageContentType: ['basepage'],
        getLink: (pageId, _) => {
          return `/page/${pageId}`;
        },
      },
      {
        path: '/search/:searchValue',
        exact: true,
        component: SearchPage,
      },
      {
        path: '*',
        component: NotFoundPage,
      },
    ],
  },
];

export default routes;
