import React, { useState, useCallback, useEffect } from 'react';
import { useDeviceType } from 'utils/hooks';
import Menu from './menu';
import Search from './search';
import Navigation from './navigation';
import { MenuIcon } from './menu-icon';
import { StHeader } from './styled';

const Header: React.FC = () => {
  const device = useDeviceType();
  const [isMenuActive, setMenuActive] = useState(false);
  const [isSearchActive, setSearchActive] = useState(false);

  useEffect(() => {
    if (isMenuActive) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'unset';
    }
  }, [isMenuActive]);

  const toggleMenuActive = useCallback(() => {
    setMenuActive((prev) => !prev);
  }, [setMenuActive]);

  const toggleSearchActive = useCallback(() => {
    setSearchActive((prev) => !prev);
  }, [setSearchActive]);

  return (
    <StHeader>
      {(device === 'mobile' || device === 'tablet' || device === 'laptop') && (
        <MenuIcon isOpen={isMenuActive} onClick={toggleMenuActive} />
      )}

      <Menu visible={isMenuActive} onCloseMenu={() => setMenuActive(false)} />

      {isSearchActive ? <Search onClick={toggleSearchActive} /> : <Navigation onClick={toggleSearchActive} />}
    </StHeader>
  );
};

export default Header;
