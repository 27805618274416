import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const StContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0;
    margin-top: 15px;
  }
`;

export const ReplyButton = styled.div`
  color: ${COLORS.blueDark};
  font-size: 16px;
  font-weight: 300;
  &:hover {
    color: ${COLORS.primary};
    cursor: pointer;
  }
`;

export const StyledCounterLike = styled.span<{ isLikedByUser?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: ${({ isLikedByUser }) => (isLikedByUser ? `${COLORS.white}` : `${COLORS.gray3}`)};
  min-width: 17px;
  max-width: fit-content;

  height: 28px;
  border: 2px solid ${({ isLikedByUser }) => (isLikedByUser ? `${COLORS.azureLight}` : `${COLORS.greenGray}`)};
  border-radius: 12px;
  background-color: ${({ isLikedByUser }) => (isLikedByUser ? `${COLORS.azureLight}` : `${COLORS.greenGray}`)};
  padding: 2px 12px;

  cursor: pointer;

  svg {
    fill: ${({ isLikedByUser }) => (isLikedByUser ? `${COLORS.white}` : `${COLORS.gray4}`)} !important;
    path {
      fill: ${({ isLikedByUser }) => (isLikedByUser ? `${COLORS.white}` : `${COLORS.gray4}`)} !important;
    }
  }
`;
