import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

const Title = styled.span`
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;

  color: ${COLORS.azure};

  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  width: 288px;
  flex-direction: column;
  gap: 24px;

  :hover ${Title} {
    color: ${COLORS.primary};
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 320px;
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 368px;
  }
`;

const ImgWrapper = styled.div`
  width: 288px;
  height: 288px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.35);

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 320px;
    height: 320px;
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 368px;
    height: 368px;
  }
`;

const Subtitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;

  color: ${COLORS.gray3};

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  & p {
    margin: 0 0 0.2em 0;
  }
`;

const Button = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;

    & * {
      fill: ${COLORS.secondary};
    }
  }
`;

export const Styled = { Button, Title, Wrapper, ImgWrapper, Subtitle, IconWrapper };
