import { ReactElement } from 'react';

export enum ButtonTypes {
  Primary = 'primary',
  Secondary = 'secondary',
  Inverse = 'inverse',
  Ghost = 'ghost',
  GhostInverse = 'ghostInverse',
}

export type ButtonProps = {
  type?: ButtonTypes;
  name?: string | null;
  suffix?: ReactElement | boolean;
  onClick?: (e?: unknown) => void;
  width?: string;
  disabled?: boolean;
};

export type ColorsProps = {
  color: string;
  howerColor: string;
  background: string;
  howerBackground: string;
};
