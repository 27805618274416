import React, { forwardRef } from 'react';
import styled, { CSSObject } from 'styled-components';
import RSelect, { Styles, GroupTypeBase, OptionsType, InnerRef } from 'react-select';

import { SelectOption } from 'web';

import { BASIC_FONT_FAMILY, COLORS } from 'utils/constants';

const textStyles: CSSObject = {
  margin: '0',
  fontSize: '16px',
  lineHeight: '24px',
  padding: '7px 12px',
  fontFamily: BASIC_FONT_FAMILY,
  fontWeight: 400,
  whiteSpace: 'nowrap',
};

const customStyles: Partial<
  Styles<
    {
      label: string;
      value: string;
    },
    false,
    GroupTypeBase<{
      label: string;
      value: string;
    }>
  >
> = {
  control: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    backgroundColor: COLORS.white,
    borderRadius: state.menuIsOpen ? '2px 2px 0 0' : '2px',
    borderColor: `${state.menuIsOpen ? COLORS.white : COLORS.gray5} !important`,
    boxShadow: state.menuIsOpen ? '0 6px 20px rgba(0, 0, 0, 0.08)' : 'none',
  }),

  input: (provided) => ({
    ...provided,
    ...textStyles,
  }),

  menu: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
    zIndex: 9999999,
    borderRadius: '0 0 2px 2px',
    boxShadow: '0 6px 20px rgba(0, 0, 0, 0.08)',
    fontFamily: BASIC_FONT_FAMILY,
    fontWeight: 400,
  }),

  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999999,
  }),

  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    padding: '4px 12px 8px 12px',
    color: state.isSelected ? COLORS.secondary : COLORS.gray3,
    backgroundColor: state.isSelected || state.isFocused ? COLORS.gray7 : COLORS.white,
  }),

  valueContainer: (provided) => ({
    ...provided,
    padding: '0',
  }),

  singleValue: (provided, state) => ({
    ...provided,
    ...textStyles,
    color: state.isDisabled ? COLORS.gray4 : COLORS.gray3,
  }),

  placeholder: (provided) => ({
    ...provided,
    ...textStyles,
    color: COLORS.gray4,
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),
};

const InputLabel = styled.label`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  color: ${COLORS.gray};
`;

const InputDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export type SelectProps = Partial<{
  ref: InnerRef;
  value: SelectOption | null;
  options: OptionsType<SelectOption>;
  placeholder: string;
  label: string;
  className: string;
  onChange: (value: SelectOption | null) => void;
  disabled?: boolean;
  isSearchable?: boolean;
  maxMenuHeight?: number;
  menuPosition?: 'fixed' | 'absolute';
}>;

const Select: React.FC<SelectProps> = forwardRef(
  (
    {
      value,
      options,
      isSearchable,
      placeholder = '',
      label,
      menuPosition,
      className,
      onChange,
      disabled = false,
      maxMenuHeight,
    },
    ref: InnerRef,
  ) => {
    return (
      <InputDiv>
        {label && <InputLabel>{label}</InputLabel>}

        <RSelect
          ref={ref}
          value={value}
          options={options}
          placeholder={placeholder}
          className={className}
          classNamePrefix={className}
          styles={customStyles}
          onChange={onChange}
          menuPortalTarget={document.body}
          isDisabled={disabled}
          noOptionsMessage={() => 'Нет опций'}
          isSearchable={isSearchable}
          maxMenuHeight={maxMenuHeight}
          menuPosition={menuPosition}
          menuShouldBlockScroll={false}
        />
      </InputDiv>
    );
  },
);

export default Select;
