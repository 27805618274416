import React, { useCallback, useMemo, useState } from 'react';
import { HeaderStats } from 'components/renderers/header-stats';
import { Lightbox } from 'components/base-ui/lightbox';
import { useDeviceType } from 'utils/hooks';
import { BigBannerType } from 'store/projects/types';
import { Button, Container, Content, Div, Header, StGradient } from './styles';

interface ScarletBannerProps {
  bannerData: BigBannerType;
}

export const ScarletBanner: React.FC<ScarletBannerProps> = ({ bannerData }) => {
  const deviceType = useDeviceType();
  const isMobileOrTablet = useMemo(() => deviceType === 'mobile' || deviceType === 'tablet', [deviceType]);
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);

  const handleToggleLightBox = useCallback(() => setIsLightBoxOpen((prev) => !prev), []);

  return (
    <>
      <Container back={bannerData.image}>
        {isMobileOrTablet && <StGradient />}
        <Content>
          <Header>{bannerData.header}</Header>
          <Div>{bannerData.videoButton && <Button onClick={handleToggleLightBox}>видео</Button>}</Div>
          <Div>
            <HeaderStats stats={bannerData.numbers} description={bannerData.description} />
          </Div>
        </Content>
      </Container>
      <Lightbox
        showLightBox={isLightBoxOpen}
        toggler={isLightBoxOpen}
        sources={[bannerData.video]}
        handleClose={handleToggleLightBox}
      />
    </>
  );
};
