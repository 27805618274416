import React from 'react';
import styled from 'styled-components';

import { BREAKPOINTS, COLORS } from 'utils/constants';

import HtmlContainer from 'components/base-ui/html-container';
import { HtmlRenderer } from 'utils/htmlParser';

const Div = styled(HtmlContainer)<{ borderless?: boolean }>`
  color: ${COLORS.gray2};
  margin: 0 16px;
  padding-bottom: ${(props) => (props.borderless ? '4px' : '24px')};
  border-bottom: ${(props) => (props.borderless ? 'undefined' : `1px solid ${COLORS.gray5}`)};
  font-size: 18px;
  line-height: 32px;

  img {
    max-width: 100%;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 0 88px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin: 0 32px;
    padding-bottom: ${(props) => (props.borderless ? '22px' : '24px')};
  }
`;

interface ContentProps {
  text: string;
  borderless?: boolean;
}

const Content: React.FC<ContentProps> = (props) => {
  return (
    <Div borderless={props.borderless}>
      <HtmlRenderer html={props.text} />
    </Div>
  );
};

export default Content;
