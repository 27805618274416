import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { Container } from '../../../base-ui/container';

export const StCommentBlock = styled.div`
    width: 100%;
    height: 218px;
    padding: 16px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    background:  ${COLORS.gray6};
    border-radius: 4px;

    color: ${COLORS.gray3};

    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
        height: 190px;
    }

    transition: all 0.3s ease-out;
`;

export const StContentContainer = styled(Container)`
    width: 100%;
    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      padding-left: 70px;
    }
    transition: all 0.3s ease-out;
`;

export const StEmptyContentContainer = styled(Container)`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
`;

export const StCommentHeader = styled(Container)`
    width: 100%;
    @media screen and (min-width: ${BREAKPOINTS.mobile}) {
      span {
          margin-bottom: 0;
      }
    }
    transition: all 0.3s ease-out;
`;
