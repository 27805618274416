import { BREAKPOINTS, COLORS } from 'utils/constants';
import styled from 'styled-components';
import bgMapDesk from 'assets/img/bg-map_desk.svg';
import bgMapTab from 'assets/img/bg-map_tab.svg';
import bgMapMob from 'assets/img/bg-map_mob.svg';
import { Container } from '../../base-ui/container';

export const Wrapper = styled(Container)`
  width: 100%;
  min-height: 350px;
  padding: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  background: no-repeat url(${bgMapMob}) center;
  background-size: cover;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    min-height: 408px;
    background: no-repeat url(${bgMapTab}) center;
    background-size: cover;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    min-height: 567px;
    background: no-repeat url(${bgMapDesk}) center;
    background-size: cover;
  }
  position: relative;

  .city-tooltip {
    padding: 0;
    border-radius: 12px;
    background: ${COLORS.white} !important;
    opacity: 1 !important;
    box-shadow: 0px 4px 6px 0px rgba(0, 116, 147, 0.6);
  }
`;

export const Cities = styled(Container)`
  width: 100%;
  max-height: 400px;
  justify-content: center;
  align-items: center;
  color: ${COLORS.white};
  gap: 15px;
  flex-wrap: wrap;
  row-gap: 15px;
  position: relative;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    row-gap: 26px;
    gap: 24px;
    width: 700px;
  }
`;

export const City = styled.div<{ size: 'small' | 'big' | 'medium' }>`
  cursor: pointer;

  font-size: ${({ size }) => {
    if (size === 'small') return '14px';
    if (size === 'big') return '24px';
    return '16px';
  }};
  font-weight: ${({ size }) => {
    if (size === 'small') return '300';
    if (size === 'big') return '600';
    return '500';
  }};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: ${({ size }) => {
      if (size === 'small') return '20px';
      if (size === 'big') return '44px';
      return '32px';
    }};
    font-weight: ${({ size }) => {
      if (size === 'small') return '300';
      if (size === 'big') return '600';
      return '400';
    }};
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    font-size: ${({ size }) => {
      if (size === 'small') return '24px';
      if (size === 'big') return '48px';
      return '36px';
    }};
    font-weight: ${({ size }) => {
      if (size === 'small') return '300';
      if (size === 'big') return '600';
      return '400';
    }};
  }
`;
