import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const Container = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'unset' : 'none')};
  width: 100%;
  margin: 0 32px;
  height: 284px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 340px;
    height: 340px;
    margin: 0 40px 0 0;
    :last-child {
      margin-right: 0;
    }
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 368px;
    height: 368px;
    margin-right: 80px;
    :last-child {
      margin-right: 0;
    }
  }
`;
export const ImageWrapper = styled.div<{ image: string }>`
  width: 100%;
  height: 136px;
  background: url(${(props) => props.image});
  border-radius: 8px;
  background-size: cover;
  background-position: center;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 340px;
    height: 184px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 368px;
    height: 232px;
  }
`;
export const Content = styled.div`
  width: 100%;
  padding: 0 16px;
  height: 132px;
  color: ${COLORS.gray3};
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0;
    width: 340px;
    height: 132px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 368px;
    height: 120px;
  }
`;
export const Header = styled.h2`
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin: 16px 0 4px 0;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin: 16px 0 8px 0;
    -webkit-line-clamp: 1;
  }
`;
export const Description = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 4px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const FooterIconsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
export const FooterItem = styled.div`
  width: 24px;
  height: 24px;
`;

export const Count = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${COLORS.gray4};
  margin: 0 10px;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

export const LikesWrapper = styled(FooterItem)`
  cursor: pointer;
`;
export const Button = styled.div`
  height: 24px;
  display: flex;
  cursor: pointer;
`;
export const ButtonText = styled.p`
  margin-right: 10px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${COLORS.gray3};
  user-select: none;
`;
