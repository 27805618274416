import React, { RefObject, useState } from 'react';
import { IconName } from 'web';
import { Container } from 'components/base-ui/container';
import { useProductCommentsStore } from 'store/product-comments';
import { Button } from 'shared/components';
import { ButtonTypes } from 'shared/components/button/types';
import { useDeviceState } from 'hooks/useDeviceProvider';
import { useToggle } from 'utils/hooks';
import {
  ButtonWrapper,
  StCloseBlock,
  StCommentContainer,
  StContainer,
  StFooterContainer,
  StIcon,
} from './styles';
import IconButton from '../../base-ui/icon-button';
import LastComment from './lastComment';

interface Props {
  commentsBlockRef: RefObject<HTMLDivElement>,
  hasBanner: boolean;
}

const GoToCommentButton: React.FC<Props> = ({ commentsBlockRef, hasBanner }) => {
  const { isLessTablet } = useDeviceState();
  const [iconName, setIconName] = useState<IconName>('iconComments');
  const store = useProductCommentsStore();

  const [isVisible, setIsVisible] = useToggle(false);

  const scrollToComment = () => {
    if (commentsBlockRef.current) {
      commentsBlockRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (isLessTablet) {
      handleClose();
    }
  };

  const commentInput = document.getElementById('commentTextAreaId');

  const handleAddComment = () => {
    scrollToComment();
    setTimeout(() => commentInput?.focus(), 500);
  };

  const animationCommentContainer = document.getElementById('animationCommentContainer');
  const commentButtonWrapper = document.getElementById('commentButtonWrapper');
  const goToCommentsButton = document.getElementById('goToCommentsButton');

  const handleOpen = () => {
    if (animationCommentContainer) {
      animationCommentContainer.style.width = isLessTablet ? '320px' : '417px';

      animationCommentContainer.style.opacity = '1';
      animationCommentContainer.style.left = '0';
    }
    if (commentButtonWrapper) {
      commentButtonWrapper.style.left = isLessTablet ? 'calc(50% - 160px)' : 'calc(100% - 488px)';
    }
    if (goToCommentsButton) {
      goToCommentsButton.style.display = isLessTablet ? 'none' : 'block';
    }
    setIsVisible();
  };

  const handleClose = () => {
    if (animationCommentContainer) {
      animationCommentContainer.style.width = '0';
      animationCommentContainer.style.opacity = '0';
      animationCommentContainer.style.left = '500px';
    }
    if (commentButtonWrapper) {
      commentButtonWrapper.style.left = 'calc(100% - 56px)';
    }
    if (goToCommentsButton) {
      goToCommentsButton.style.display = 'block';
    }
    setIsVisible();
  };

  const handleClick = () => {
    if (isVisible) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  return (
    <StContainer hasBanner={hasBanner}>
      <ButtonWrapper id="commentButtonWrapper" hasBanner={hasBanner} isVisible={isVisible}>
        <Container gap="8px" style={{ height: '80px' }}>
          <div id="goToCommentsButton">
            <IconButton
              name={iconName}
              round
              shadow
              gradient
              onMouseOver={() => setIconName('iconComments2')}
              onMouseOut={() => setIconName('iconComments')}
              className="go-to-comments-button"
              onClick={() => handleClick()}
            />
          </div>

          <StCommentContainer id="animationCommentContainer" hasBanner={hasBanner}>
            <Container direction="column" gap="16px">
              <StCloseBlock>
                <span>{`Комментарии (${store.commentsCount})`}</span>
                <StIcon name="iconCrossBaseWhite" onClick={() => handleClose()} />
              </StCloseBlock>

              <LastComment scrollToComment={scrollToComment} />

              <StFooterContainer>
                <Button
                  onClick={() => handleAddComment()}
                  suffix={false}
                  type={ButtonTypes.Secondary}
                >
                  Оставить комменатрий
                </Button>

                <Button onClick={() => scrollToComment()} suffix={false}>Посмотреть все</Button>
              </StFooterContainer>
            </Container>
          </StCommentContainer>

        </Container>
      </ButtonWrapper>

    </StContainer>
  );
};

export default GoToCommentButton;
