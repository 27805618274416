import React from 'react';

import { ServicesBannerValue } from 'web';

import { PropsWithSectionOptions } from '../blocks';

import { Banner } from './components';

type ServicesBannerProps = PropsWithSectionOptions<{
  value: ServicesBannerValue;
}>;

const ServicesBanner: React.FC<ServicesBannerProps> = ({ value, options }) => {
  return <Banner value={value} options={options} />;
};

export default ServicesBanner;
