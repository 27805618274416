import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMedicineProgramStore } from 'store/medicine-program';
import { useParams } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { observer } from 'mobx-react-lite';
import HtmlContainer from 'components/base-ui/html-container';
import { HtmlRenderer } from 'utils/htmlParser';
import { Container } from 'components/base-ui/container';
import { BaseModal } from 'components/base-ui/base-modal';
import { Feedback } from 'components/feedback';
import { MedicineCenterTypeEnum } from 'services';
import { Styled } from './styles';
import {
  SimpleBanner,
  MedCenterInfo,
  PriceInfo,
  ContactsInfo,
  UniqueSellingPoint,
  DownloadButton,
  Grid,
  Banner,
} from '../ui';
import { TravelLineWidget } from '../ui/travel-line-widget';

export const ProgramContent = observer(() => {
  const [feedbackFormId, setFeedbackFormId] = useState<number | null>(null);

  const { medProgramId, medCenterId } = useParams<{ medCenterId: string; medProgramId: string }>();

  const store = useMedicineProgramStore();
  const program = store.program;
  const medicineCenter = store.medicineCenter;

  useEffect(() => {
    if (!isUndefined(medProgramId) && !isUndefined(medCenterId)) {
      store.fetchProgramData(Number(medProgramId), Number(medCenterId));
    }
    return store.cleanUp();
  }, [medProgramId]);

  const showPriceInfo = useMemo(() => {
    return (
      program?.hasPrice &&
      !(medicineCenter?.type === MedicineCenterTypeEnum.Sanatorium && medicineCenter?.needTravelLine)
    );
  }, [medicineCenter]);

  const feedbackModalClose = useCallback(() => setFeedbackFormId(null), []);
  const onPriceButtonClick = () => {
    setFeedbackFormId(program?.formPrice || null);
  };

  const onContactButtonClick = useCallback(() => {
    setFeedbackFormId(program?.applicationForm || null);
  }, [setFeedbackFormId, program?.applicationForm]);

  if (program === null || medicineCenter === null) return null;

  return (
    <Styled.Wrapper>
      <Banner title={program.title} image={program.image} />
      {store.showTravelLineInProgram && program.hasResidence && <TravelLineWidget />}
      <Styled.Container>
        <Styled.InfoContainer>
          <Container direction="column" gap="32px" width="100%">
            <HtmlContainer>
              <HtmlRenderer html={program.description} />
            </HtmlContainer>
            {program.usps && (
              <Container wrap="wrap" gap="16px 32px">
                {program.usps?.map((usps) => <UniqueSellingPoint key={usps.id} title={usps.name} />)}
              </Container>
            )}
            {program.presentation && <DownloadButton href={program.presentation} />}
          </Container>
          <Container direction="column" gap="32px">
            <MedCenterInfo title={program.center.title} address={medicineCenter.address} />
            {showPriceInfo && (
              <PriceInfo
                type={medicineCenter.type}
                discountPrice={program.salePrice}
                price={program.mainPrice}
                buttonText={program.btnPrice}
                unit={program.periodPrice}
                onButtonClick={onPriceButtonClick}
              />
            )}
            {program.hasContact && (
              <ContactsInfo
                phone={program.phoneContact}
                title={program.headerContact}
                description={program.textContact}
                buttonText={program.btnContact}
                onButtonClick={onContactButtonClick}
              />
            )}
          </Container>
        </Styled.InfoContainer>
        {program.specials && program.specials.length > 0 && (
          <Grid data={program.specials} title="Вас также может заинтересовать:" />
        )}
      </Styled.Container>
      <BaseModal isVisible={Boolean(feedbackFormId)} onClose={feedbackModalClose}>
        <Feedback formId={Number(feedbackFormId)} />
      </BaseModal>
    </Styled.Wrapper>
  );
});
