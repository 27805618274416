import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GalleryType, VideoItemType } from 'store/projects/types';
import { Lightbox } from 'components/base-ui/lightbox';
import { useDeviceType } from 'utils/hooks';
import { Nullable } from 'web';
import { VideoBox } from 'components/base-ui/video-box';
import { GalleryItem } from './item';
import { GalleryCarousel } from './carousel';
import { ButtonWrapper, Container, GalleryWrapper, Header } from './styles';
import { VideoGalleryList } from './video-list';
import { VideoGalleryItem } from './video-list/VideoItem';
import { Button } from '../../../../shared/components';
import { ButtonTypes } from '../../../../shared/components/button/types';

interface PhotoGalleryProps {
  data: GalleryType;
  type: 'photo' | 'video';
}

interface VideoGalleryProps {
  data: VideoItemType[];
  header?: string;
}

export const Gallery: React.FC<PhotoGalleryProps> = ({ data, type }) => {
  const limit = 8;
  const device = useDeviceType();

  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [currentData, setCurrentData] = useState<string[]>([]);
  const [lightBoxData, setLightBoxData] = useState<string[]>([]);
  const [showAllButton, setShowAllButton] = useState(true);

  useEffect(() => setCurrentData(data.image.slice(0, limit)), []);
  useEffect(() => setShowAllButton(data.image.length > limit), []);

  const isMobile = useMemo(() => device === 'mobile', [device]);

  const openLightBox = useCallback(
    (file: string) => {
      const indexCurrentElement = data.image.indexOf(file);
      setLightBoxData(data.image);
      if (indexCurrentElement && indexCurrentElement !== 0) {
        const elementsBeforeCurrent = data.image.slice(0, indexCurrentElement);
        const elementsAfterCurrent = data.image.slice(indexCurrentElement + 1, data.image.length);
        setLightBoxData([file, ...elementsAfterCurrent, ...elementsBeforeCurrent]);
      }
      setIsLightBoxOpen(true);
    },
    [data.image],
  );

  const closeLightBox = useCallback(() => {
    setIsLightBoxOpen(false);
  }, []);

  const onMoreButtonClick = useCallback(() => {
    setShowAllButton(false);
    setCurrentData(data.image);
  }, []);

  return (
    <Container>
      <Header>{data.header}</Header>
      <GalleryWrapper>
        {!isMobile && currentData.map((item) => <GalleryItem data={item} type={type} onClick={openLightBox} />)}
        {isMobile && <GalleryCarousel data={data.image} type={type} onClick={openLightBox} />}
      </GalleryWrapper>
      {showAllButton && (
        <ButtonWrapper>
          <Button type={ButtonTypes.Secondary} onClick={onMoreButtonClick}>
            Показать все
          </Button>
        </ButtonWrapper>
      )}
      <Lightbox
        showLightBox={isLightBoxOpen}
        toggler={isLightBoxOpen}
        sources={lightBoxData}
        handleClose={closeLightBox}
      />
    </Container>
  );
};

export const VideoGallery: React.FC<VideoGalleryProps> = ({ data, header }) => {
  const limit = 8;
  const device = useDeviceType();

  const [currentData, setCurrentData] = useState<VideoItemType[]>([]);
  const [videoData, setVideoData] = useState<Nullable<VideoItemType>>(null);
  const [showAllButton, setShowAllButton] = useState(true);

  useEffect(() => setCurrentData(data.slice(0, limit)), []);
  useEffect(() => setShowAllButton(data.length > limit), []);

  const isMobile = useMemo(() => device === 'mobile', [device]);

  const openVideoBox = useCallback(
    (file: VideoItemType) => {
      setVideoData({ ...file, resolutions: file.resolutions.filter((resolution) => resolution.url.length > 0) });
    },
    [data],
  );

  const closeVideoBox = useCallback(() => {
    setVideoData(null);
  }, []);

  const onMoreButtonClick = useCallback(() => {
    setShowAllButton(false);
    setCurrentData(data);
  }, []);

  const dataVideo = useMemo(() => currentData.filter((item) => item !== null), [currentData]);

  return (
    <Container>
      <Header>{header}</Header>
      <GalleryWrapper>
        {isMobile ? (
          <VideoGalleryList data={dataVideo} onClick={openVideoBox} />
        ) : (
          dataVideo.map((item) => <VideoGalleryItem key={item?.id} video={item} onClick={openVideoBox} />)
        )}
      </GalleryWrapper>
      {showAllButton && (
        <ButtonWrapper>
          <Button type={ButtonTypes.Secondary} onClick={onMoreButtonClick}>
            Показать все
          </Button>
        </ButtonWrapper>
      )}
      <VideoBox isLightBoxOpen={!!videoData} videos={data} video={videoData ?? undefined} onClose={closeVideoBox} />
    </Container>
  );
};
