import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    gap: 40px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    :hover {
      background: #ffffff4d;
      border-radius: 16px;
    }
    padding: 10px 0;
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    gap: 20px;
  }
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 200;
  color: ${COLORS.white};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
  }
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  flex-shrink: 0;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
`;

export const Icon = styled.img`
  width: 100%;
  height: 100%;
`;
