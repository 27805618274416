import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const NotesWrapper = styled.div`
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
  font-style: italic;
  color: ${COLORS.blueDark};
  width: 100%;

  padding: 30px;
  border-radius: 16px;
  background-color: ${COLORS.greenGray};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 20px;
  }
`;
