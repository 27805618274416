import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const IconWrapper = styled.div`
  svg {
    width: 35px;
    height: 35px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    svg {
      width: 50px;
      height: 50px;
    }
  }
`;
