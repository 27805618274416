import React from 'react';
import styled, { css } from 'styled-components';

import { BREAKPOINTS, COLORS } from '../../../utils/constants';

const borderedStyles = css`
  border: 2px solid ${COLORS.gray7};
`;

const marginlessStyles = css`
  margin: 0 !important;
`;

const smallestStyles = css`
  width: 48px;
  height: 48px;
`;

const smallStyles = css`
  width: 64px;
  height: 64px;
`;

const mediumStyles = css`
  width: 128px;
  height: 128px;
`;

const largeStyles = css`
  width: 264px;
  height: 264px;
`;

type AvatarSize = 'smallest' | 'small' | 'medium' | 'large';

interface AvatarWrapperProps {
  size?: AvatarSize;
  bordered?: boolean;
  marginless?: boolean;
}

const AvatarWrapper = styled.div<AvatarWrapperProps>`
  overflow: hidden;
  position: relative;

  margin: 0 0 8px 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    ${(props) => props.bordered && borderedStyles}
  }

  ${(props) => props.marginless && marginlessStyles}
  ${(props) => {
    if (props.size === 'smallest') {
      return smallestStyles;
    }
    if (props.size === 'small') {
      return smallStyles;
    }
    if (props.size === 'medium') {
      return mediumStyles;
    }
    return largeStyles;
  }}

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 0 8px 0 0;
  }
`;

const AvatarImage = styled.img<AvatarWrapperProps>`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

interface AvatarProps extends AvatarWrapperProps {
  src: string;
  className?: string;
}

const Avatar: React.FC<AvatarProps> = ({ src, size = 'small', bordered, marginless, className }) => {
  return (
    <AvatarWrapper size={size} bordered={bordered} marginless={marginless} className={className}>
      <AvatarImage src={src} />
    </AvatarWrapper>
  );
};

export default Avatar;
