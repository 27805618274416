import React, { useContext, createContext } from 'react';
import LeaderCodeStore from './store';

const store = new LeaderCodeStore();

const LeaderCodeStateContext = createContext<LeaderCodeStore>(store);

export const LeaderCodeStateProvider: React.FC = ({ children }) => (
  <LeaderCodeStateContext.Provider value={store}>{children}</LeaderCodeStateContext.Provider>
);

export const useLeaderCodeStore = (): LeaderCodeStore => useContext(LeaderCodeStateContext);
