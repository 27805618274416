import React from 'react';
import Section from 'components/base-ui/section';
import { HtmlRenderer } from 'utils/htmlParser';
import { Description, SpoilerWrapper, Title } from './styles';

type PropsType = {
  title: string;
  description: string;
};

export const Spoiler: React.FC<PropsType> = ({ title, description }) => {
  return (
    <Section noFirstOfTypeOffset compact>
      <SpoilerWrapper>
        <Title>{title}</Title>
        <Description>
          <HtmlRenderer html={description} />
        </Description>
      </SpoilerWrapper>
    </Section>
  );
};
