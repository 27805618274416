import React from 'react';
import { LeaderCodeParticipant } from 'services';
import { Title } from '../styles';
import { Card } from '../card';
import { CardsContainer, ParticipantsWrapper } from './styles';

type Props = {
  membersList?: LeaderCodeParticipant[];
};

export const Participants: React.FC<Props> = (props) => {
  return (
    <ParticipantsWrapper>
      <Title>Участники</Title>
      <CardsContainer>
        {props.membersList?.map((member) => (
          <Card position={member.position} company={member.company} name={member.fullName} image={member.image} />
        ))}
      </CardsContainer>
    </ParticipantsWrapper>
  );
};
