import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import ellipseMark from 'assets/icons/ellipse_mark.svg';

export const StWrapper = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding-bottom: 80px;
  }

  > :last-of-type {
    margin-bottom: 0px !important;
    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      margin-bottom: 42px !important;
    }
  }
  p,
  li {
    font-size: 16px;
    line-height: 24px;

    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  h5 {
    font-size: 26px;
    line-height: 30px;

    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      font-size: 36px;
      line-height: 44px;
    }
  }

  ul {
    padding-inline-start: 20px;

    li {
      padding-left: 15px;
      list-style-image: url(${ellipseMark});

      ::marker {
        line-height: 16px;
      }
    }
  }
`;

export const StLine = styled.div`
  margin: 0 16px;
  border-top: 1px solid ${COLORS.gray5};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 0 52px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin: 0 310px;
  }
`;
