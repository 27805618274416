import React from 'react';
import BaseSection from 'components/base-ui/section';
import { HtmlRenderer } from 'utils/htmlParser';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';
import { PropsWithSectionOptions } from '../blocks';

type NewsTextBlockProps = PropsWithSectionOptions<{
  data: string;
}>;

const Wrapper = styled.div`
  padding: 18px 16px 26px 16px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 52px 0 52px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0 0px 26px 0px;
  }

  img {
    max-width: 100%;
  }
`;

const NewsText: React.FC<NewsTextBlockProps> = ({ data, options }) => {
  return (
    <BaseSection compact borderless paddingless transparent {...options}>
      <Wrapper>
        <HtmlRenderer html={data} />
      </Wrapper>
    </BaseSection>
  );
};

export default NewsText;
