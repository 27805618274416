import styled from 'styled-components';
import { COLORS } from 'utils/constants';

export const ColumnWrapper = styled.div<{ w: number }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => `${props.w}%`};
`;

export const Title = styled.div`
  font-size: 30px;
  line-height: 34px;
  font-weight: 400;
  color: ${COLORS.azure};
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  color: ${COLORS.gray2};
  > p {
    margin: 0;
  }
`;
