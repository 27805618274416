import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  background: ${COLORS.blueGradient};
  padding: 50px 16px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 50px 52px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 40px 160px 50px 160px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-repeat: no-repeat;
    background-position: right 15px bottom 0;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Header = styled.div`
  display: flex;
  gap: 12px;
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  color: #436f96;
  border-bottom: 2px solid #436f96;
  padding-bottom: 24px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 26px;
    line-height: 32px;
    font-weight: 500;
  }
`;

export const PointsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const Point = styled.div`
  display: flex;
  gap: 18px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  color: #436f96;
`;
