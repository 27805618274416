import { AxiosError } from 'axios';
import { action, makeObservable, observable } from 'mobx';
import { MainPage, LeaderCodeTeam } from 'services';
import AbstractStore from 'store/AbstractStore';
import { api } from 'utils/api';

export default class LeaderCodeStore extends AbstractStore {
  fetching = false;

  content: MainPage | null = null;

  year: number | null = null;

  teams: LeaderCodeTeam[] = [];

  team: LeaderCodeTeam | null = null;

  title: string | null = null;

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      fetching: observable,
      content: observable,
      year: observable,
      fetchContent: action,
      setYear: action,
      teams: observable,
      team: observable,
      title: observable,
      setTeam: action,
      setTitle: action,
      cleanUpTeams: action,
    });
  }

  fetchContent(): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return api.leaderCodePage
      .leaderCodePageList()
      .then((res) => {
        this.setContent(res.data);
      })
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  private setContent(data: MainPage) {
    this.content = data;
  }

  setYear(newYear?: number) {
    this.year = newYear ?? null;
  }

  private setTeams(data: LeaderCodeTeam[]) {
    this.teams = data;
  }

  fetchTeams(): Promise<void> {
    this.setError(null);

    return api.leaderCodeYears
      .leaderCodeYearsList({ year: this.year ?? undefined })
      .then((res) => {
        this.setTitle(res.data.results[0].participantsTitle);
        this.setTeams(res.data.results[0].leaderTeams);
        this.setTeam(res.data.results[0].leaderTeams[0]);
      })
      .catch((err: AxiosError) => this.setError(err));
  }

  setTeam(currentTeam?: LeaderCodeTeam) {
    this.team = currentTeam ?? null;
  }

  setTitle(title?: string | null) {
    this.title = title ?? null;
  }

  cleanUpTeams = (): void => {
    this.teams = [];
    this.team = null;
    this.title = null;
  };
}
