import React from 'react';
import BaseSection from 'components/base-ui/section';
import { HtmlRenderer } from 'utils/htmlParser';
import { PropsWithSectionOptions } from '../blocks';

type NewsHeaderBlockProps = PropsWithSectionOptions<{
  data: string;
}>;

const NewsHeader: React.FC<NewsHeaderBlockProps> = ({ data, options }) => {
  return <BaseSection heading={data} compact borderless paddingless transparent {...options} />;
};

export default NewsHeader;
