import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { GuideComment } from 'services';
import { AddNewComment } from 'components/comment/add-comment/add-new-comment';
import { useGuideCommentsStore } from 'store/guide-comments';
import { CommentsList, ShowMoreCommentsButton } from './styles';

import GuideCommentsItem from './comment';
import { Button } from '../../../shared/components';
import { ButtonTypes } from '../../../shared/components/button/types';

const COMMENTS_COUNT = 5;

type GuideCommentsProps = {
  guideId: string;
};

const GuideComments: React.FC<GuideCommentsProps> = observer(({ guideId }) => {
  const store = useGuideCommentsStore();

  const [commentsPage, setCommentsPage] = useState(2);

  useEffect(() => {
    store.fetchComments(+guideId, COMMENTS_COUNT);
  }, [guideId]);

  const onAddNewComment = useCallback(
    (comment: string) => {
      const newComment: GuideComment = {
        content: comment,
        guide: String(guideId),
      };
      store.addComment(newComment);
    },
    [guideId],
  );

  const onShowMore = useCallback(() => {
    store.fetchNextComments(+guideId, commentsPage, COMMENTS_COUNT);
    setCommentsPage(commentsPage + 1);
  }, [commentsPage, guideId]);

  const showMoreButton = store.comments.length < store.commentsCount;

  return (
    <div>
      <AddNewComment onAddNewComment={onAddNewComment} count={store.commentsCount} idNewComment="comment-textarea" />

      <CommentsList>{store.comments?.map((el) => <GuideCommentsItem key={el.id} data={el} />)}</CommentsList>

      {showMoreButton && (
        <ShowMoreCommentsButton>
          <Button width="300" suffix={false} type={ButtonTypes.Secondary} onClick={onShowMore}>
            ПОКАЗАТЬ БОЛЬШЕ
          </Button>
        </ShowMoreCommentsButton>
      )}
    </div>
  );
});

export default GuideComments;
