import React, { useEffect, useMemo } from 'react';
import { ScarletBanner, SmallBanner } from 'components/renderers/scarlet-sails';
import {
  LeftImageBlock,
  ArticleText,
  ArticleQuote,
  ArticleBigImage,
  ChronologyCarousel,
  PhotoCarousel,
} from 'components/renderers';
import { useProjectsStore } from 'store';
import { observer } from 'mobx-react-lite';
import { ArticleLeftBlock } from 'components/renderers/article-left-block';
import { ChronologyCarouselBlock } from 'web';
import { ChronologyDataType } from 'components/carousels/chronology-carousel/types';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import BaseSection from '../base-ui/section';
import { Container, Chronology, Carousel } from './styles';

export const ScarletSails = observer(() => {
  const store = useProjectsStore();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    store.fetchScarletData();
    document.title = 'Алые паруса';
    trackPageView({});
  }, []);

  const leftImageBlockData = useMemo(
    () =>
      store?.scarletData
        ?.filter((item) => item.type === 'i2')
        .map((item) => item.value)[0] as unknown as ArticleLeftBlock,
    [store.scarletData],
  );

  const textTop = useMemo(
    () => store?.scarletData?.filter((item) => item.type === 'text_block').map((item) => item.value)[0] as string,
    [store.scarletData],
  );
  const textMid = useMemo(
    () => store?.scarletData?.filter((item) => item.type === 'text_block').map((item) => item.value)[1] as string,
    [store.scarletData],
  );
  const textBottom = useMemo(
    () => store?.scarletData?.filter((item) => item.type === 'text_block').map((item) => item.value)[2] as string,
    [store.scarletData],
  );

  const quote = useMemo(
    () => store?.scarletData?.filter((item) => item.type === 'big_header').map((item) => item.value)[0] as string,
    [store.scarletData],
  );

  const image = useMemo(
    () =>
      store?.scarletData?.filter((item) => item.type === 'image').map((item) => item.value)[0] as {
        image: string;
        position: string;
      },
    [store.scarletData],
  );

  const chronologyData = useMemo((): ChronologyCarouselBlock => {
    return store?.scarletData
      ?.filter((item) => item.type === 'chronology')
      .map((item) => item.value)[0] as ChronologyCarouselBlock;
  }, [store.scarletData]);

  return (
    <Container>
      {store?.bigBannerData && <ScarletBanner bannerData={store.bigBannerData} />}
      {store?.smallBannerData && <SmallBanner smallBannerData={store.smallBannerData} />}
      {store?.scarletData && <LeftImageBlock content={leftImageBlockData} />}
      <ArticleText text={textTop} />
      <ArticleQuote text={quote} />
      <ArticleText text={textMid} />
      {image && <ArticleBigImage image={image.image} />}
      <ArticleText text={textBottom} />
      <Chronology>
        <BaseSection compact heading={chronologyData?.header || 'Хронология'} borderless={true}>
          {chronologyData && <ChronologyCarousel data={chronologyData.chronology as ChronologyDataType[]} />}
        </BaseSection>
      </Chronology>
      <Carousel>
        <BaseSection compact heading={store?.photoCarouselData?.title || ''} borderless noMinHeight>
          {store?.photoCarouselData && <PhotoCarousel data={store.photoCarouselData.photos} />}
        </BaseSection>
      </Carousel>
    </Container>
  );
});
