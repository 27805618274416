import styled from 'styled-components';
import { BASIC_FONT_FAMILY, BREAKPOINTS, COLORS } from 'utils/constants';
import { TextButton } from 'components/base-ui/button';
import BreadcrumbsWrapper from 'components/base-ui/breadcrumbs-wrapper';

export const Container = styled.div`
  font-family: ${BASIC_FONT_FAMILY};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  margin: 44px 0 150px 0;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    justify-content: flex-start;
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    padding: 0 160px 0 0;
  }
`;
export const BreadcrumbsContainer = styled(BreadcrumbsWrapper)``;
export const Covers = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.mobile}) {
    padding: 0 16px;
    gap: 8px;

    width: unset;
    display: inline-grid;
    grid-template-columns: repeat(2, 140px);
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    width: 100%;
    justify-content: flex-start;

    padding: 0 16px 0 88px;
    gap: 16px;
    row-gap: 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    padding: 0 16px 0 160px;
    gap: 48px;
  }
`;

export const StTitle = styled.div`
  padding: 0 16px;
  margin-bottom: 8px;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: ${COLORS.azure};
`;
