import styled from 'styled-components';

import { BREAKPOINTS, COLORS } from 'utils/constants';

export const StDiv = styled.div`
  height: 100%;
  position: relative;
`;

export const StBreadcrumbsWrapper = styled.div`
  top: 56px;
  left: 0;
  width: 100%;
  z-index: 100;
  position: absolute;
  margin-top: 12px;
  padding: 0 16px 0 0;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 32px 0 32px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0 32px 0 136px;
  }
`;

export const StSection = styled.section`
  width: 100%;
  overflow-x: hidden;
`;

export const StCommentsBlockWrapper = styled.div`
  margin-top: 28px;
  > :first-of-type {
    margin-top: 0 !important;
  }
`;

export const StLine = styled.div`
  margin: 0 16px;
  border-top: 1px solid ${COLORS.gray5};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 0 52px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin: 0 310px;
  }
`;
