import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useApplicationStore } from 'store';
import { ApplicationsWrapper, Container, Title, ApplicationsScroll } from './styles';
import { ApplicationItem } from '../application-item';

type ApplicationsProps = {
  withNews: boolean;
};

export const Applications: React.FC<ApplicationsProps> = observer(({ withNews }) => {
  const store = useApplicationStore();

  useEffect(() => {
    store.fetchMainPageApplications();
  }, []);

  return (
    <Container withNews={withNews}>
      <Title>Активные заявки и конкурсы</Title>
      <ApplicationsWrapper>
        <ApplicationsScroll>
          {store.mainPageApplications.map((app, ind) => (
            <ApplicationItem key={ind} data={app} />
          ))}
        </ApplicationsScroll>
      </ApplicationsWrapper>
    </Container>
  );
});
