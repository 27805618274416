import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { Container } from '../../base-ui/container';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  padding: 0px 16px 40px 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0px 52px 40px 52px;
    row-gap: 50px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 32px 160px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 32px;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: row;
  }
`;

export const Header = styled.div`
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  color: ${COLORS.azure};

  p {
    margin: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 28px;
    line-height: 32px;
    font-weight: 400;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 33.33%;
    font-size: 48px;
    line-height: 52px;
    font-weight: 600;
  }
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  color: ${COLORS.blueDark};

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 66.66%;
  }
`;

export const ButtonWrapper = styled(Container)`
  justify-content: center;
`;
