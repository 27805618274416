import React, { useCallback, useState } from 'react';
import Icon from 'components/base-ui/icon';
import { useDeviceType } from 'utils/hooks';
import { StImage, StImageGallery, StImageWrapper, StSlider } from './styled';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Lightbox } from '../../../base-ui/lightbox';

interface Props {
  images: string[];
}

export const AboutImageGallery: React.FC<Props> = (props) => {
  const device = useDeviceType();
  const [lighbox, setLightbox] = useState(false);
  const [image, setImage] = useState('');

  const openLightbox = useCallback((image: string) => {
    setImage(image);
    setLightbox(true);
  }, []);

  return (
    <StImageGallery>
      <StSlider
        dots={device !== 'mobile' && device !== 'tablet'}
        slidesToShow={1}
        slidesToScroll={1}
        arrows={true}
        nextArrow={<Icon name="iconNavRight" />}
        prevArrow={<Icon name="iconNavLeft" />}
        adaptiveHeight={true}
      >
        {props.images.map((image, index) => (
          <StImageWrapper key={index} onClick={() => openLightbox(image)}>
            <StImage src={image} alt={`Изображение галереи ${index}`} />
          </StImageWrapper>
        ))}
      </StSlider>
      {device === 'mobile' && (
        <Lightbox
          handleClose={() => setLightbox(false)}
          showLightBox={lighbox}
          toggler={lighbox}
          source={image}
          sources={[image]}
        />
      )}
    </StImageGallery>
  );
};
