import React from 'react';

import { PropsWithSectionOptions } from '../blocks';
import { NotesWrapper } from './styles';

import Section from '../../base-ui/section';

type NotesWithBackgroundBlockProps = PropsWithSectionOptions<{
  value: string;
}>;

const NotesWithBackgroundBlock: React.FC<NotesWithBackgroundBlockProps> = ({ value, options }) => {
  return (
    <Section noFirstOfTypeOffset noLastOfTypeMargin noMinHeight {...options}>
      <NotesWrapper>{value}</NotesWrapper>
    </Section>
  );
};

export default NotesWithBackgroundBlock;
