import React from 'react';
import AnimateHeight from 'react-animate-height';
import styled, { css } from 'styled-components';

import { useToggle } from '../../../utils/hooks';
import { COLORS, BREAKPOINTS } from '../../../utils/constants';

import { H5 } from '../typo';
import IconButton from '../icon-button';

interface DivProps {
  bordered?: boolean;
}

const border = css`
  &:after {
    content: '';
    bottom: 0%;
    left: 16px;
    right: 16px;
    height: 1px;
    position: absolute;
    background: ${COLORS.gray5};

    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      left: 88px;
      right: 88px;
    }

    @media screen and (min-width: ${BREAKPOINTS.laptop}) {
      left: 32px;
      right: 32px;
    }
  }
`;

const borderedStyles = css`
  ${border};
`;

const borderlessStyles = css`
  ${border};
`;

const Div = styled.div<DivProps>`
  position: relative;

  ${(props) => (props.bordered ? borderedStyles : borderlessStyles)};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: grid;
    column-gap: 16px;
    grid-template-columns: 1fr auto;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    column-gap: 64px;
  }
`;

const Body = styled.div`
  padding: 28px 16px 36px 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 28px 0 36px 88px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 28px 0 36px 32px;
  }
`;

const Sidebar = styled.aside`
  @media screen and (max-width: calc(${BREAKPOINTS.tablet} - 1px)) {
    top: 28px;
    right: 16px;
    position: absolute;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 88px 0 32px;
    margin: 24px 0 48px 0;
    border-left: 1px solid ${COLORS.gray5};
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0 32px;
  }
`;

const Heading = styled(H5)`
  font-weight: 400;
  color: ${COLORS.secondary};
  transition: margin 0.5s ease-out;

  @media screen and (max-width: calc(${BREAKPOINTS.tablet} - 1px)) {
    max-width: calc(100% - 42px - 16px);
  }
`;

interface ChildrenWrapperProps {
  active?: boolean;
}

const ChildrenWrapper = styled.div<ChildrenWrapperProps>`
  color: ${COLORS.gray2};
  transition: margin-top 0.5s ease-out;
  margin-top: ${(props) => (props.active ? '20px' : '0')};
`;

interface ToggleButtonProps {
  active?: boolean;
}

const activeStyles = css`
  transform: rotate(-90deg);
`;

const ToggleButton = styled(IconButton)<ToggleButtonProps>`
  transform: rotate(90deg);
  transition: transform 0.5s ease-out;

  ${(props) => props.active && activeStyles}
`;

interface CollapseProps extends DivProps {
  title?: string;
  value?: boolean;
  className?: string;
  children: React.ReactNode;
}

const Collapse: React.FC<CollapseProps> = ({ title, value, bordered, className, children }) => {
  const [active, toggleActive] = useToggle(value);

  return (
    <Div bordered={bordered} className={className}>
      <Body>
        {title && <Heading>{title}</Heading>}

        <AnimateHeight duration={500} height={active ? 'auto' : 0}>
          <ChildrenWrapper active={active}>{children}</ChildrenWrapper>
        </AnimateHeight>
      </Body>

      <Sidebar>
        <ToggleButton name="iconPointer" round active={active} onClick={toggleActive} />
      </Sidebar>
    </Div>
  );
};

export default Collapse;
