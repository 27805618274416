import React, { useEffect, useMemo, useState } from 'react';
import { Container } from 'components/base-ui/container';
import { useBreakpointsDimensions } from 'utils/hooks';
import { MedicineCenterShort, MedicineProgram } from 'services';
import { Card } from './Card';
import { Styled } from './styles';
import { getLimitCount } from './lib';
import { Button } from '../../../../shared/components';
import { ButtonTypes } from '../../../../shared/components/button/types';

type GridItemType = Pick<MedicineProgram, 'title' | 'center' | 'image'> & {
  title: string;
  image?: string;
  id: number;
  center?: MedicineCenterShort;
};

export const Grid: React.VFC<{ data: GridItemType[]; title?: string; isMain?: boolean; rows?: number }> = (props) => {
  const windowDimension = useBreakpointsDimensions();
  const [count, setCount] = useState<number>(0);
  const [expanded, setExpanded] = useState(false);

  const showButton = useMemo(() => {
    return props.data.length > count && !expanded;
  }, [props.data, count, expanded]);

  useEffect(() => {
    setCount(getLimitCount(windowDimension.width, props.rows));
  }, [windowDimension.width]);

  const data = useMemo(() => {
    return expanded ? props.data : props.data.slice(0, count);
  }, [props.data, expanded, count]);

  return (
    <Container width="100%" direction="column" gap="40px">
      <Styled.Title>{props.title}</Styled.Title>
      <Styled.Container>
        {data.map((item) => (
          <Card
            key={item.id}
            id={item.id}
            title={item.title}
            withSubtitle={props.isMain}
            center={item.center}
            src={item.image}
          />
        ))}
      </Styled.Container>
      {showButton && (
        <Container width="100%" justify="center">
          <Button type={ButtonTypes.Secondary} onClick={() => setExpanded(true)}>
            Показать все
          </Button>
        </Container>
      )}
    </Container>
  );
};
