import ScrollToTopOnMount from 'components/base-ui/scroll-to-top';
import { DefaultLayout } from 'layouts';
import React from 'react';
import Breadcrumbs from 'components/base-ui/breadcrumbs';
import { LeaderCode } from 'components/leader-code';
import { Container, BreadCrumbsWrapper } from './styles';

const LeaderCodePage: React.FC = () => {
  return (
    <>
      <ScrollToTopOnMount />
      <DefaultLayout>
        <Container>
          <BreadCrumbsWrapper>
            <Breadcrumbs white={false} lineStyle={false} />
          </BreadCrumbsWrapper>
          <LeaderCode />
        </Container>
      </DefaultLayout>
    </>
  );
};

export default LeaderCodePage;
