import { BREAKPOINTS, COLORS } from 'utils/constants';
import styled from 'styled-components';
import bgTeam from 'assets/img/teams_bg.svg';
import { Container } from '../../../base-ui/container';

export const TeamsWrapper = styled(Container)`
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    gap: 24px;
  }
`;

export const Teams = styled(Container)<{ isCollapsed?: boolean }>`
  flex-direction: column;
  background-color: #436f96;
  color: ${COLORS.white};
  padding: ${({ isCollapsed }) => (isCollapsed ? '8px 16px' : '16px')};
  gap: 25px;
  position: relative;
  width: 100%;

  border-radius: 16px;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 292px;
    height: 100%;
    padding: 32px 8px;
    background: no-repeat url(${bgTeam});
    background-size: cover;
    border-radius: 16px;
  }

  transition: height 0.4s ease-out;
`;

export const TeamsContainer = styled(Container)`
  flex-direction: column;
  gap: 25px;
  max-height: 474px;
  overflow-y: auto;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    flex-direction: row;
    gap: 70px;
    flex-wrap: wrap;
    row-gap: 8px;
    max-height: 100%;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: column;
    gap: 25px;
  }
`;

export const Team = styled(Container)<{ isSelectedTeam?: boolean; isCollapsed?: boolean }>`
  padding: 8px 16px;
  gap: 10px;
  z-index: 2;

  cursor: pointer;

  font-size: 16px;
  line-height: 24px;
  font-weight: 300;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: ${({ isCollapsed }) => (isCollapsed ? '90%' : '40%')};
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    width: 100%;
  }

  border-radius: ${({ isSelectedTeam }) => (isSelectedTeam ? '24px' : '0')};
  background-color: ${({ isSelectedTeam }) => (isSelectedTeam ? 'rgba(255, 255, 255, 0.2)' : 'transparent')};

  img {
    width: 24px;
    height: 24px;
  }
`;

export const FirstTeamContainer = styled(Container)`
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  img {
    width: 24px;
    height: 24px;
  }
`;
