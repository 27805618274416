import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { Container } from 'components/base-ui/container';

export const StContainer = styled(Container)<{ isSub?: boolean; hasChildren: boolean }>`
  padding: ${(props) => (props.isSub ? '16px' : '16px 0')};
  color: ${COLORS.gray3};
  margin-bottom: ${(props) => (props.isSub || props.hasChildren ? '0px' : '8px')};
  position: relative;

  gap: 20px;

  ::before {
    content: '';
    display: ${(props) => (props.isSub ? 'block' : 'none')};
    border-left: 1px solid ${COLORS.greenGray};
    height: 115%;
    position: absolute;
    top: -60px;
    left: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-left: ${(props) => (props.isSub ? '30px' : '0')};
    padding: ${(props) => (props.isSub ? '24px 0 16px 48px' : '16px 0')};

    ::before {
      content: '';
      display: ${(props) => (props.isSub ? 'block' : 'none')};
      border-left: 1px solid ${COLORS.greenGray};
      height: 116%;
      position: absolute;
      top: -42px;
      left: 0;
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-left: ${(props) => (props.isSub ? '30px' : '0')};
  }
`;

export const AvatarWrapper = styled.div`
  img {
    flex-shrink: 0;
    margin: 0;
  }

  .comment-avatar {
    margin: 0;
  }
`;

export const SubCommentsShowMoreButton = styled.div`
  color: ${COLORS.primary};
  cursor: pointer;
  margin-bottom: 30px;
  margin-left: 20px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-left: 50px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-left: 70px;
  }
`;
