import React, { useCallback } from 'react';
import { NewsItemProps } from 'web';
import { formatDate } from 'utils/date';
import { useHistory } from 'react-router-dom';
import { CardWrapper, Content, Date, Image, ImageWrapper, Text } from './styles';

type NewsCardProps = {
  data: NewsItemProps;
};

export const NewsCard: React.FC<NewsCardProps> = ({ data }) => {
  const history = useHistory();

  const onClick = useCallback(() => {
    history.push(`/news/${data.id}`);
  }, [data.id]);

  return (
    <CardWrapper>
      <ImageWrapper onClick={onClick}>
        <Image src={data.mainPageImage} />
      </ImageWrapper>
      <Content onClick={onClick}>
        <Text>{data.paragraph?.header}</Text>
        <Date>{formatDate(data.date)}</Date>
      </Content>
    </CardWrapper>
  );
};
