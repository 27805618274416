import React, { useCallback, useRef, useState } from 'react';

import { VideoItemType } from 'store/projects/types';
import { Nullable } from 'web';
import { observer } from 'mobx-react-lite';
import { useCarousel } from 'utils/hooks';
import { IconSvg } from 'assets/icons';
import { ModalWrapper } from './ModalWrapper';
import { VideoGalleryStateProvider, VideoGalleryStore } from './model';
import { SwipeButton } from './SwipeButton';
import { Counter } from './Counter';
import { Player } from './Player';
import { Styled } from './styles';

interface VideoBoxContentProps {
  isLightBoxOpen: boolean;
  videos: VideoItemType[];
  video?: VideoItemType;
  text?: string;
  onClose: () => void;
}

const VideoBoxContent = observer<VideoBoxContentProps>((props) => {
  const store = VideoGalleryStore();
  // eslint-disable-next-line no-undef
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const { current, increment, decrement } = useCarousel(
    props.videos,
    props.videos.findIndex((el) => el?.id === props.video?.id),
  );

  const handleOnPrevClick = decrement;
  const handleOnNextClick = increment;

  const [isControlsVisible, setIsControlsVisible] = useState(false);

  const handleOnPlayerMouseMove = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current ?? undefined);
    }
    if (store.ready) {
      setIsControlsVisible(true);
      timerRef.current = setTimeout(() => {
        setIsControlsVisible(false);
      }, 2000);
    }
  }, [timerRef, store.ready, store.playing]);

  const handleOnPlayerMouseOut = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current ?? undefined);
    }
    if (store.playing) setIsControlsVisible(false);
  }, [timerRef]);

  return (
    <ModalWrapper
      isVisible={props.isLightBoxOpen}
      handleOnPlayerMouseOut={handleOnPlayerMouseOut}
      onClose={props.onClose}
    >
      <Player
        onClose={props.onClose}
        video={props.videos[current]}
        onPlayerMouseMove={handleOnPlayerMouseMove}
        setIsControlsVisible={setIsControlsVisible}
        isControlsVisible={isControlsVisible}
        text={props.text}
      />
      {props.videos.length > 1 && (
        <>
          {isControlsVisible && <SwipeButton vector="right" onClick={handleOnNextClick} />}
          {isControlsVisible && <SwipeButton vector="left" onClick={handleOnPrevClick} />}
          <Counter
            total={props.videos.length}
            current={props.videos.findIndex((el) => el?.id === store.currentVideo?.id) + 1}
            visible
          />
        </>
      )}
      <Styled.IphoneFix />
      <Styled.CloseButtonContainer onClick={props.onClose}>
        <Styled.IconButton>
          <IconSvg.Close />
        </Styled.IconButton>
      </Styled.CloseButtonContainer>
    </ModalWrapper>
  );
});

export const VideoBox: React.VFC<{
  video?: VideoItemType;
  videos: VideoItemType[];
  onClose: () => void;
  isLightBoxOpen: boolean;
  text?: string;
}> = (props) => {
  return (
    <VideoGalleryStateProvider>
      <VideoBoxContent {...props} />
    </VideoGalleryStateProvider>
  );
};
