import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container } from 'components/base-ui/container';
import { Button } from 'shared/components';
import { observer } from 'mobx-react-lite';
import { useDeviceState } from 'hooks/useDeviceProvider';
import { useLeaderCodeStore } from 'store/leader-code';
import { Header, StContainer, Wrapper } from './styles';
import { ButtonTypes } from '../../../shared/components/button/types';
import { TeamsBlock } from './teams';
import { Participants } from './participants';

const MOBILE_COUNT_MEMBERS = 6;
const OTHER_DEVICE_COUNT_MEMBERS = 9;

export const LeadersList: React.FC = observer(() => {
  const store = useLeaderCodeStore();

  useEffect(() => {
    if (store.year) {
      store.fetchTeams();
    }
    return store.cleanUpTeams();
  }, [store.year]);

  const { isLessTablet } = useDeviceState();

  const countShortListMembers = useMemo(() => {
    return isLessTablet ? MOBILE_COUNT_MEMBERS : OTHER_DEVICE_COUNT_MEMBERS;
  }, [isLessTablet]);

  const [membersList, setMembersList] = useState(store.team?.members.slice(0, countShortListMembers));
  const [visibleButton, setVisibleButton] = useState(
    store.team?.members.length ? store.team?.members.length > countShortListMembers : false,
  );

  useEffect(() => {
    setMembersList(store.team?.members.slice(0, countShortListMembers));
    setVisibleButton(store.team?.members.length ? store.team?.members.length > countShortListMembers : false);
  }, [store.team, countShortListMembers]);

  const handleShowAll = useCallback(() => {
    setMembersList(store.team?.members);
    setVisibleButton(false);
  }, [store.team]);

  return (
    <Wrapper>
      <Header>{store.title}</Header>
      <StContainer>
        <TeamsBlock />
        <Participants membersList={membersList} />
      </StContainer>
      {visibleButton && (
        <Container>
          <Button onClick={() => handleShowAll()} type={ButtonTypes.Secondary}>
            Все участники
          </Button>
        </Container>
      )}
    </Wrapper>
  );
});
