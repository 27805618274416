import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    gap: 20px;
  }
`;

export const CardImage = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 16px;
  overflow: hidden;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 216px;
    height: 216px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 290px;
    height: 290px;
  }
`;

export const StImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const StText = styled.div`
  width: 140px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${COLORS.azure};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 216px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 290px;
  }
`;
