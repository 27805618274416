import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Tooltip from 'react-tooltip';
import { autorun } from 'mobx';
import { COLORS } from 'utils/constants';
import { useDeviceState } from 'hooks/useDeviceProvider';
import { useCitiesStore } from 'store/cities';
import { useLink } from 'utils/api';
import { Wrapper, Cities, City } from './styles';
import { TooltipContent } from './tooltip-content';

export const CitiesBlock: React.FC = observer(() => {
  const store = useCitiesStore();
  const goToLink = useLink();
  const { isLessTablet, isLessLaptop } = useDeviceState();

  useEffect(() => autorun(() => store.fetchCities()), [store]);

  const onLink = useCallback((link?: string | null) => {
    if (!link || isLessLaptop) return;
    goToLink(link);
  }, []);

  return (
    <Wrapper>
      <Cities>
        {store.cities.map((city) => (
          <>
            <City
              size={city.size}
              key={city.name}
              data-tooltip-id={`tooltip-${city.name}`}
              data-tip
              data-for={`tooltip-${city.name}`}
              onClick={() => onLink(city.link)}
            >
              {city.name}
            </City>
            <Tooltip
              id={`tooltip-${city.name}`}
              delayHide={20}
              effect="solid"
              backgroundColor={COLORS.white}
              className="city-tooltip"
              clickable
              place={isLessTablet ? undefined : 'right'}
              arrowColor={isLessLaptop ? 'transparent' : COLORS.white}
              event={isLessLaptop ? 'click' : undefined}
              eventOff={isLessLaptop ? 'mouseout' : undefined}
              overridePosition={(position, currentEvent) => {
                if (!isLessLaptop) return position;
                const ev = currentEvent as unknown as React.MouseEvent;

                let newLeft = position.left;
                let newTop = position.top;

                if (ev.clientX + 234 > window.innerWidth) {
                  newLeft = window.innerWidth - 234;
                }

                if (ev.clientX < 234) {
                  newLeft = 6;
                }

                if (ev.clientY + 200 > window.innerHeight) {
                  newTop = ev.clientY - 120;
                }

                if (ev.clientY < 200) {
                  newTop = ev.clientY + 10;
                }

                return { top: newTop, left: newLeft };
              }}
            >
              <TooltipContent name={city.name} link={city.link} text={city.text} linkText={city.linkText} />
            </Tooltip>
          </>
        ))}
      </Cities>
    </Wrapper>
  );
});
