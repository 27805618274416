import styled from 'styled-components';
import bgTab from 'assets/img/background_leader_years_tab.svg';
import bgDesk from 'assets/img/background_leader_years_desk.svg';
import { BASIC_FONT_FAMILY, BREAKPOINTS, COLORS } from 'utils/constants';

export const Wrapper = styled.div`
  background-color: ${COLORS.gray10};
  padding: 28px 0;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    background: no-repeat url(${bgTab});
    background-size: cover;
    background-position: center;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 160px 70px 160px;
    background: no-repeat url(${bgDesk});
    background-size: cover;
    background-position: center;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px; //temp

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  color: ${COLORS.blueDark};
  padding: 0 16px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 52px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0;
  }
`;

export const Columns = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  padding: 0 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 52px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0;
  }
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 382px;
  }
`;

export const ColumnHeader = styled.div`
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  color: ${COLORS.blueDark2};
`;

export const ColumnItem = styled.div`
  display: flex;
  gap: 15px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  color: ${COLORS.blueDark};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 16px;
  gap: 60px;
  margin-top: 40px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 100%;
    padding: 0;
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const StButton = styled.button`
  width: 194px;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 16px 32px;
  border-radius: 26px;
  border: none;
  font-family: ${BASIC_FONT_FAMILY};
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  background: ${COLORS.creatorsRed};
  color: ${COLORS.white};
  cursor: pointer;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 185px;
  }
`;
