import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';
import { Span } from 'components/base-ui/typo';

export const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    flex-direction: row;
    align-items: flex-end;
    gap: 12px;
  }
`;

export const UserInfo = styled(Span)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: unset;
    font-size: 18px;
    font-weight: 500;
  }
`;

export const DateInfo = styled(Span)`
  font-size: 14px !important;
  line-height: 18px;
  font-weight: 300;
`;
