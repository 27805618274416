import React, { useCallback } from 'react';
import { useDeviceState } from 'hooks/useDeviceProvider';
import { ReactComponent as IconDownload } from 'assets/icons/icon_download2.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'shared/components';
import { Container, IconBox, Img, StTitle, Year } from './styles';
import { ButtonTypes } from '../../../shared/components/button/types';

interface CoverPropsType {
  id: number;
  cover: string;
  link: string;
  title: string;
  year: string;
}

export const Cover: React.FC<CoverPropsType> = ({ id, cover, link, title, year }) => {
  const { isLessTablet } = useDeviceState();

  const history = useHistory();
  const location = useLocation();
  const handleViewClick = useCallback(() => {
    history.push(`${location.pathname}/${id}`);
  }, [id]);

  if (isLessTablet) {
    return (
      <Container isMobile onClick={handleViewClick}>
        <Img src={cover} alt="обложка журнала" isMobile />
        <IconBox>
          <a href={link} download target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
            <IconDownload />
          </a>
        </IconBox>
        <Year>{year}</Year>
      </Container>
    );
  }

  return (
    <Container isMobile onClick={handleViewClick}>
      <Img src={cover} alt="обложка журнала" isMobile />
      <StTitle>{title}</StTitle>
      <a href={link} download target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
        <Button type={ButtonTypes.Secondary}>Скачать</Button>
      </a>
    </Container>
  );
};
