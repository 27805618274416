import React, { useCallback, useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { useNewsCommentsStore } from 'store/news-comments';
import { NewsComment } from 'services';

import { useParams } from 'react-router-dom';
import { AddNewComment } from 'components/comment/add-comment/add-new-comment';
import NewsCommentsItem from './comment';
import { CommentsList, ShowMoreCommentsButton } from './styles';

import { Button } from '../../../../shared/components';
import { ButtonTypes } from '../../../../shared/components/button/types';

const COMMENTS_COUNT = 5;

const NewsComments: React.FC = observer(() => {
  const store = useNewsCommentsStore();
  const { newsId } = useParams<{ newsId: string }>();

  const [commentsPage, setCommentsPage] = useState(2);

  useEffect(() => {
    store.fetchComments(+newsId, COMMENTS_COUNT);
  }, [newsId]);

  const onAddNewComment = useCallback(
    (comment: string) => {
      const newComment: NewsComment = {
        content: comment,
        news: String(newsId),
      };
      store.addComment(newComment);
    },
    [newsId],
  );

  const onShowMore = useCallback(() => {
    store.fetchNextComments(+newsId, commentsPage, COMMENTS_COUNT);
    setCommentsPage(commentsPage + 1);
  }, [commentsPage]);

  const showMoreButton = store.comments.length < store.commentsCount;

  return (
    <div>
      <AddNewComment onAddNewComment={onAddNewComment} count={store.commentsCount} idNewComment="comment-textarea" />

      <CommentsList>{store.comments?.map((el) => <NewsCommentsItem key={el.id} data={el} />)}</CommentsList>

      {showMoreButton && (
        <ShowMoreCommentsButton>
          <Button width="300" suffix={false} type={ButtonTypes.Secondary} onClick={onShowMore}>
            ПОКАЗАТЬ БОЛЬШЕ
          </Button>
        </ShowMoreCommentsButton>
      )}
    </div>
  );
});

export default NewsComments;
