import React, { useCallback, useState } from 'react';

import { HtmlRenderer } from 'utils/htmlParser';
import { Button } from 'shared/components';
import { useLink } from 'utils/api';
import { BaseModal } from 'components/base-ui/base-modal';
import { Feedback } from 'components/feedback';
import { debounce, isFunction, isObject } from 'lodash';
import { useMainPageStore } from 'store';
import { ItemWrapper, Content, Title, Description, Image, ImageWrapper, ButtonWrapper } from './styles';
import { ActionType, SlideProps } from './types';

export const Item: React.FC<SlideProps> = (props) => {
  const mainPageStore = useMainPageStore();
  const openLink = useLink();
  const [showForm, setShowForm] = useState(false);

  const showButton =
    (props.buttonAction === ActionType.Link && props.link.link) ||
    (props.buttonAction === ActionType.ApplicationForm && props.dynamicForm);

  const handleLinkClick = useCallback(async () => {
    if (props.showModal) {
      await mainPageStore.emailModalStore.fetchContent();
      mainPageStore.emailModalStore.setShow(true);
    } else {
      openLink(props.link.link);
    }
  }, [openLink, props.link.link, mainPageStore.emailModalStore.isShowing, props.showModal]);

  const handleFormClick = useCallback(() => {
    debounce(() => setShowForm(true), 300)();
  }, []);

  const onButtonClick = useCallback(
    (e: unknown) => {
      if (isObject(e) && 'stopPropagation' in e && isFunction(e.stopPropagation)) {
        e.stopPropagation();
      }

      if (props.buttonAction === ActionType.Link) {
        handleLinkClick();
      } else {
        handleFormClick();
      }
    },
    [props.buttonAction],
  );

  return (
    <ItemWrapper>
      <BaseModal isVisible={showForm} onClose={() => setShowForm(false)}>
        <Feedback formId={props.dynamicForm ?? 0} />
      </BaseModal>
      <Content onClick={props.goPrev}>
        <Title>{props.title}</Title>
        <Description>
          <HtmlRenderer html={props.description} />
        </Description>
        {showButton && (
          <ButtonWrapper>
            <Button onClick={onButtonClick}>{props.link.text}</Button>
          </ButtonWrapper>
        )}
      </Content>
      <ImageWrapper>
        <Image src={props.image} alt="banner image" onClick={props.goNext} />
      </ImageWrapper>
    </ItemWrapper>
  );
};
