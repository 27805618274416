import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { NewsItemProps, ParagraphValue } from 'web';

import { GuidePage } from 'services';

import { useGuidePagesStore, useProfileStore } from 'store';

import { Block, BlockTypes } from 'utils/blocks';
import { COLORS } from 'utils/constants';

import { smallStyles } from 'styles/text';

import HtmlContainer from 'components/base-ui/html-container';

import { HtmlRenderer } from 'utils/htmlParser';
import { IconViews } from 'components/base-ui/icon-views';
import { IconLikeWithTooltip } from '../../base-ui/icon-like-with-tooltip';
import Icon from '../../base-ui/icon';
import { ActionIcon } from '../../news/list/cards/item-with-image/styled';

const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.35));
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Like = styled.div`
  width: 24px;
  height: 24px;
`;

const Favorite = styled(Like)`
  cursor: pointer;
`;

const Count = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 20px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${COLORS.gray4};
  margin: 0 8px;
  cursor: default;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
`;

const Title = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 13px;
  color: inherit;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-decoration: none;
  transition: all 0.2s ease-out;
  cursor: pointer;
`;

const Div = styled.div`
  display: grid;
  row-gap: 24px;
  color: ${COLORS.gray3};
  grid-template-rows: 366px auto;
`;

const Info = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const Description = styled(HtmlContainer)`
  ${smallStyles};
  margin-bottom: 16px;
`;

interface ItemProps {
  data: GuidePage;
  handleShowModal?: (guideId: number) => void;
}

export const GuidePageCardItem: React.FC<ItemProps> = ({ data, handleShowModal }) => {
  const history = useHistory<{ scroll: boolean }>();
  const profileStore = useProfileStore();
  const getItemData = (news: GuidePage): NewsItemProps => {
    const res: NewsItemProps = { id: Number(news.id) };
    const content = news.content as unknown as Block[];
    const previewImage = content.find((c) => c.type === BlockTypes.PreviewImage);
    const preview = content.find((c) => c.type === BlockTypes.Preview);
    res.date = news.publishedAt || '';
    res.image = previewImage ? String(previewImage.value) : '';
    res.paragraph = preview ? (preview.value as ParagraphValue) : undefined;
    res.isLiked = !!news.isLikedByUser;
    res.likesCount = news.likesCount;
    res.viewsCount = news.viewsCount;
    res.isInUserFavorites = !!news.isInUserFavorites;
    return res;
  };
  const card = useMemo(() => getItemData(data), [data]);
  const guidePagesStore = useGuidePagesStore();

  const goToComments = useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      ev.stopPropagation();
      history.push({ pathname: `/guide/${data.id}`, state: { scroll: true } });
    },
    [history, data.id],
  );

  const cardClickHandler = useCallback(
    (ev) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
      if (!ev.target.closest('BUTTON')) history.push(`/guide/${data.id}`);
    },
    [data.id],
  );

  const handleLikeClick = useCallback(() => {
    if (data.id) {
      card.isLiked
        ? guidePagesStore.handleLikeDelete(data.id, profileStore.profile?.id!)
        : guidePagesStore.handleLikePost(data.id, profileStore.profile);
    }
  }, [data.id, card.isLiked, card, profileStore.profile?.id]);

  const onShowModal = useCallback(() => {
    if (handleShowModal && card.id) {
      handleShowModal(card.id);
    }
  }, [card.id, handleShowModal]);

  return (
    <Div>
      <ImageWrapper onClick={cardClickHandler}>
        <Image src={card.image || ''} />
      </ImageWrapper>

      <div>
        <Title onClick={cardClickHandler}>{card.paragraph?.header || ''}</Title>
        <Description onClick={cardClickHandler}>
          <HtmlRenderer html={card.paragraph?.text || ''} />
        </Description>
        <Info>
          <ButtonWrapper>
            <IconLikeWithTooltip
              isLiked={Boolean(card.isLiked)}
              onClick={handleLikeClick}
              likedUsers={data.likeAuthors || []}
              likesCount={data.likesCount || 0}
              newsId={data.id!}
              onShowModal={onShowModal}
            />
            <Like>
              <IconViews height="24px" width="24px" color={COLORS.gray4} />
            </Like>
            <Count>{card.viewsCount ?? 0}</Count>
            {data.commentsCount! > 0 && (
              <>
                <Like onClick={goToComments}>
                  <Icon name="iconChatBubbleOutline" />
                </Like>
                <Count>{data.commentsCount}</Count>
              </>
            )}
          </ButtonWrapper>
        </Info>
      </div>
    </Div>
  );
};
